//@flow
import React, {Fragment} from 'react';
import {Line, defaults} from "react-chartjs-2";
import {FaFileExport} from "react-icons/fa";
import LoadingIcon from "../../LoadingIcon";
import {convertStatsToCSV} from "../../../util/util";


defaults.global.defaultFontFamily = 'Avenir-Roman';

type FechaStats = {
    fecha_yyyy_mm: string,
    especies: number,
    diversidad: number,
    eventos: number,
};

type Props = {
    fechaStats: FechaStats[],
    title?: string,
}

export function FechaStatsGraph(props: Props) {

    if(!props.fechaStats) {
        return <LoadingIcon/>
    }

    const data = {
        labels: props.fechaStats.map(f => f.fecha_yyyy_mm),
        datasets: [{
            data: props.fechaStats.map(f => f.eventos),
            backgroundColor: 'rgba(228,26,28, 0.7)',
            borderColor: 'rgba(228,26,28, 0.8)',
            label: 'Eventos',
            yAxisID: 'A',
            fill: true,
        }, {
            data: props.fechaStats.map(f => f.especies),
            backgroundColor: 'rgba(55,126,184, 0.8)',
            borderColor: 'rgba(55,126,184, 0.8)',
            label: 'Abundancia',
            yAxisID: 'B',
            fill: false,
        }, {
            data: props.fechaStats.map(f => f.diversidad),
            backgroundColor: 'rgba(77,175,74, 0.8)',
            borderColor: 'rgba(77,175,74, 0.8)',
            label: 'Diversidad',
            yAxisID: 'A',
            fill: false,
        }],
    };

    const options = {
        responsive: true,
        legend: {
            position: 'bottom',
        },
        scales: {
            yAxes: [{
                id: 'A',
                position: 'left',
                scaleLabel: {
                    display: true,
                    labelString: 'Eventos y diversidad',
                }
            }, {
                id: 'B',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Abundancia',
                }
            }],
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
        <Fragment>
            <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                <div className="col">
                    <h3>{props.title || 'Estadísticas por fecha de evento'}</h3>
                </div>
                <div className="col text-right">
                    <a className="btn btn-primary"
                       href={URL.createObjectURL(new Blob([convertStatsToCSV(props.fechaStats)], {type: 'text/csv;charset=utf-8;'}))}
                       download={`estadisticas_por_fecha_evento.csv`}><FaFileExport/> Exportar</a>
                </div>
            </div>
            <Line data={data} options={options}/>
        </Fragment>
    );
}