import React, {Component} from "react";
import CalidadAgua from "./CalidadAgua";
import Olas from "./Olas";
import SedimentoPlaya from "./SedimentoPlaya";
import TransectosPlaya from "./TransectosPlaya";

class PlayaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId
        };
    }

    render() {
        return (
            <div>
                <TransectosPlaya eventoId={this.state.eventoId}/>
                <Olas eventoId={this.state.eventoId}/>
                <SedimentoPlaya eventoId={this.state.eventoId}/>
                <CalidadAgua eventoId={this.state.eventoId}/>
            </div>
        )
    }
}

export default PlayaList;