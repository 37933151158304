import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";
import {INDICADORES_PRIM, INDICADORES_SEG, INDICADORES_SUELO} from './Common';

class DatosAdicionales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            record: {},
        };
    }

    async componentDidMount() {
        const data = await this.props.rest.query({
            resultRecordCount: 1,
            entity: 'humedal',
            where: `evento_GlobalID='${this.state.eventoId}'`,
        });
        this.setState({
            record: data.records[0]
        });
    }

    render() {
        return (
            <div style={{paddingBottom: 2 + "em"}}>
                <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                    <div className="col-6">
                        <h3>Indicadores</h3>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="btn btn-success"
                              to={`/evento/${this.state.eventoId}/humedales/datos-adicionales/${this.state.record.OBJECTID}`}
                              role="button">
                            <FaPencilAlt/> Editar
                        </Link>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-6">
                        <span className="font-weight-bold">Indicadores de suelos: </span>{
                        Object.keys(INDICADORES_SUELO).map((k, idx) => (
                            this.state.record[k] ? (idx > 0 ? ', ' : '') + INDICADORES_SUELO[k] : null)
                        )}
                    </div>
                    <div className="col-6">
                        <span
                            className="font-weight-bold">Indicadores de hidrología primarios: </span>{
                        Object.keys(INDICADORES_PRIM).map((k, idx) => (
                            this.state.record[k] ? (idx > 0 ? ', ' : '') + INDICADORES_PRIM[k] : null)
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <span
                            className="font-weight-bold">Indicadores de hidrología secundarios: </span>{
                        Object.keys(INDICADORES_SEG).map((k, idx) => (
                            this.state.record[k] ? (idx > 0 ? ', ' : '') + INDICADORES_SEG[k] : null)
                        )}
                    </div>
                    <div className="col-6">
                        <span
                            className="font-weight-bold">Determinacion de vegetación hídrica: </span>{this.state.record.hum_vegetacion}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <span
                            className="font-weight-bold">Total de especies dominantes: </span>{this.state.record.hum_total_especies}
                    </div>
                    <div className="col-6">
                        <span
                            className="font-weight-bold">Porcientos de especies dominantes: </span>{this.state.record.hum_pct_especies}
                    </div>
                </div>
            </div>
        )
    }
}

export default withSession(DatosAdicionales);