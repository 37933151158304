//@flow
import React, {Component, Fragment} from 'react';
import HexStatsTable from "../mapa/HexStatsTable";
import {withSession} from '../../../context/SessionContext';
import HexStatsTableHeader from "../mapa/HexStatsTableHeader";
import {FechaStatsGraph} from "./FechaStatsGraph";
import {EditorEventoStatsGraph} from "./EditorEventoStatsGraph";
import {EditorEspeciesStatsGraph} from "./EditorEspeciesStatsGraph";
import {convertStatsToCSV} from "../../../util/util";
import LoadingIcon from "../../LoadingIcon";

class Estadisticas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalStats: null,
            editorEventoStats: null,
            editorEspeciesStats: null,
            fechaStats: null,
        }
    }

    async componentDidMount() {
        this.props.refDataRest.query({
            entity: 'global_stats_v',
        }).then(globalStats => {
            this.setState({
                globalStats: globalStats.records[0],
            })
        });

        this.props.refDataRest.query({
            entity: 'editor_evento_stats_v',
            orderByFields: 'evento desc',
        }).then(editorStats => {
            this.setState({
                editorEventoStats: editorStats.records,
            })
        });

        this.props.refDataRest.query({
            entity: 'editor_especies_stats_v',
            orderByFields: 'especies desc',
        }).then(editorStats => {
            this.setState({
                editorEspeciesStats: editorStats.records,
            })
        });

        this.props.refDataRest.query({
            entity: 'fecha_yyyy_mm_stats_v',
            orderByFields: 'fecha_yyyy_mm',
        }).then(fechaStats => {
            this.setState({
                fechaStats: fechaStats.records,
            })
        });

    }

    render() {
        return (
            <div className="container">

                {/*<div>{JSON.stringify(this.state.editorEventoStats)}</div>*/}

                {this.loadingWrapper(this.state.globalStats,
                    this.state.globalStats &&
                    <Fragment>
                        <HexStatsTableHeader hexStats={this.state.globalStats}/>
                        <HexStatsTable hexStats={this.state.globalStats}/>
                    </Fragment>
                )}

                <FechaStatsGraph
                    fechaStats={this.state.fechaStats}
                />

                <EditorEventoStatsGraph
                    editorEventoStats={this.state.editorEventoStats}
                />

                <EditorEspeciesStatsGraph
                    editorEspeciesStats={this.state.editorEspeciesStats}
                />
            </div>
        );
    }

    loadingWrapper(payload, component) {
        if (!payload) {
            return <LoadingIcon/>
        }

        return component;
    }

}

export default withSession(Estadisticas);