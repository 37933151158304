import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FaPencilAlt, FaPlus} from "react-icons/fa";
import noImageArbolImg from '../../../images/no-image-arbol.png';
import {withSession} from "../../../context/SessionContext";
import {formatDate} from "../../../util/format";
import BackButton from "../../BackButton";
import {AuditData} from "../../AuditData";

class ArbolesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordId: props.match.params.recordId,
            record: {
                arbol: {},
                mediciones: [],
                foto: null
            }
        };
    }

    componentDidMount() {
        this.fetchRecord(this.state.recordId);
    }

    async fetchRecord(globalId) {
        const arbol = await this.props.rest.getByGlobalID({
            entity: 'arbol_campeon',
            globalId: globalId,
        });

        const mediciones = await this.props.rest.query({
            entity: 'arbol_campeon_medicion',
            where: `arbol_campeon_GlobalID = '${globalId}'`,
            outFields: ['OBJECTID', 'fecha_medicion', 'diametro', 'altura_pies', 'mayor_extension', 'menor_extension', 'promedio_copa', 'cuarto_extension_copa', 'GlobalID']
        });

        const photos = await this.props.rest.getPhotos({
            entity: 'arbol_campeon',
            id: arbol.OBJECTID
        });

        this.setState({
            record: {
                arbol: arbol,
                mediciones: mediciones.records,
                foto: photos[0]
            },
        });
    }

    render() {
        let img = <img alt="" className="img-fluid" src={noImageArbolImg}/>;
        if (this.state.record.foto) {
            img = <img alt="" className="img-fluid" src={this.state.record.foto.attachment.url}/>;
        }

        return (
            <div className="container">

                <div className="row">
                    <div className="col-9"><h1>{this.state.record.arbol.nombre_comun}</h1></div>
                    <div className="col-3 text-right">
                        <Link className="btn btn-success" to={`/arboles/${this.state.recordId}/edit`}
                              role="button">
                            <FaPencilAlt/> Editar
                        </Link>
                        &nbsp;<BackButton btnClass="success"/>
                    </div>
                </div>

                <div className="row">

                    <div className="col-6">
                        <div style={{height: 'auto', width: 100 + '%', border: 'dotted ' + 1 + 'px #ccc'}}>
                            {img}
                        </div>
                    </div>

                    <div className="col-6">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td><b>Total de Puntos:</b></td>
                                <td>{this.state.record.arbol.total_puntos}</td>
                            </tr>
                            <tr>
                                <td><b>Nombre común:</b></td>
                                <td>{this.state.record.arbol.nombre_comun}</td>
                            </tr>
                            <tr>
                                <td><b>Nombre científico:</b></td>
                                <td><em>{this.state.record.arbol.nombre_cientifico}</em></td>
                            </tr>
                            <tr>
                                <td><b>Estado:</b></td>
                                <td>{this.state.record.arbol.condicion_arbol}</td>
                            </tr>
                            <tr>
                                <td><b>Tipo de Lugar:</b></td>
                                <td>{this.state.record.arbol.tipo_lugar}</td>
                            </tr>
                            <tr>
                                <td><b>Barrio y carretera:</b></td>
                                <td>{this.state.record.arbol.barrio_carretera}</td>
                            </tr>
                            <tr>
                                <td><b>Municipio:</b></td>
                                <td>{this.state.record.arbol.municipio_arbol}</td>
                            </tr>
                            <tr>
                                <td><b>Descripción del área:</b></td>
                                <td>{this.state.record.descripcion_area}</td>
                            </tr>
                            <tr>
                                <td><b>Información relevante:</b></td>
                                <td>{this.state.record.arbol.informacion_relevante}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-9">
                        <h3>Mediciones</h3>
                    </div>
                    <div className="col-3 text-right">
                        <Link className="btn btn-success"
                              to={`/arboles/${this.state.recordId}/medicion/new`} role="button">
                            <FaPlus/> Añadir nuevos datos
                        </Link>
                    </div>
                </div>

                <table className="table table-hover table-sm table-striped">
                    <thead className="thead-light">
                    <tr>
                        <th>Fecha de Medición</th>
                        <th>Diametro</th>
                        <th>Altura</th>
                        <th>Mayor extención</th>
                        <th>Menor extención</th>
                        <th>Promedio de copa</th>
                        <th>1/4 de copa</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.record.mediciones.map(m => (
                        <tr key={m.OBJECTID}>
                            <td>{formatDate(m.fecha_medicion)}</td>
                            <td>{m.diametro}</td>
                            <td>{m.altura_pies}</td>
                            <td>{m.mayor_extension}</td>
                            <td>{m.menor_extension}</td>
                            <td>{m.promedio_copa}</td>
                            <td>{m.cuarto_extension_copa}</td>
                            <td className="text-center">
                                <Link
                                    to={`/arboles/${this.state.recordId}/medicion/${m.GlobalID}`}><FaPencilAlt/></Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <AuditData entity={this.state.record.arbol}/>

            </div>
        );
    }
}

export default withSession(ArbolesView);





