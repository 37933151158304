import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FaPlus, FaSearch} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";
import {formatDate} from "../../util/format";
import withPermalink from "../withPermalink";
import PageNav from "../pagenav/PageNav";


class SapoList extends Component {
    constructor(props) {
        super(props);
        const plinkData = this.props.permalinkData;
        this.state = {
            page: {
                limit: 15,
                offset: plinkData && plinkData.page ? Number(plinkData.page) : 1,
                totalRecords: 0
            },
            sapos: [],
        };

        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
    }

    componentDidMount() {
        this.fetchSapos(this.state.page.offset);
    }

    async fetchSapos(pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            entity: 'evento',
            where: "tipo_evento = 'SAPO_CONCHO'",
            outFields: ['OBJECTID', 'fecha', 'hex_id', 'latitud', 'longitud', 'GlobalID'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            page: {limit: data.limit, offset: pageNumber, totalRecords: data.totalRecords},
            sapos: data.records
        })
    }

    toggleDropDown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handlePaginationChange(pageNumber) {
        this.fetchSapos(pageNumber);
        if(this.props.updatePermalink) {
            this.props.updatePermalink({
                page: '' + pageNumber
            })
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h3>Eventos de sapo concho</h3>
                    </div>
                    <div className="col-6 text-right">
                        <div className="form-group text-right align-bottom">
                            <Link className="btn btn-success" to="/evento/new/SAPO_CONCHO" role="button">
                                <FaPlus/> Evento nuevo
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="mb-3 table-responsive">
                    <table className="table table-hover table-sm table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Fecha</th>
                            <th>ID Hexágono</th>
                            <th>Latitud</th>
                            <th>Longitud</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.sapos.map(sapo => (
                                <tr key={sapo.OBJECTID}>
                                    <td>{formatDate(sapo.fecha)}</td>
                                    <td>{sapo.hex_id}</td>
                                    <td>{sapo.latitud}</td>
                                    <td>{sapo.longitud}</td>
                                    <td className="text-center">
                                        <Link to={`/sapo-concho/${sapo.GlobalID}`}><FaSearch/></Link>
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />

            </div>
        );
    }

}

export default withSession(withPermalink(SapoList));