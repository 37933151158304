import React, {Component} from 'react';
import BackButton from "../BackButton";
import {withSession} from "../../context/SessionContext";
import Form from "../Form";
import FormControl from "../FormControl";
import {ORIGIN_OPTS, USFWS_OPTS, DRNA_OPTS, UICN_OPTS, SPECIES_TYPES} from "./common";
import type {SessionProps} from "../../context/SessionContext";
import EspecieSelect from "../EspecieSelect";
import {AuditData} from "../AuditData";


class EspeciesEdit extends Component<SessionProps> {
    constructor(props) {
        super(props);
        this.recordId = props.match.params.recordId;
        this.state = {
            record: {
                active: 1,
            },
            especies: [],
        };
    }

    componentDidMount() {
        if (this.recordId) {
            this.fetchRecord(this.recordId);
        }
    }

    fetchRecord(id) {
        this.props.adminRest.query({
            entity: 'species',
            where: `species_id = ${id}`,
        },).then(data => {
            const record = data.records[0];
            this.props.especieService.fetchEspecies(record.species_type)
                .then(especies => this.setState({especies: especies.records}));

            this.setState({
                record: record
            })
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        let value = target.value;
        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        const name = target.name;
        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    };

    handleSpeciesTypeChange = async (event) => {
        const {name, value} = event.target;
        let especies = this.state.especies;
        if (value) {
            const especiesData = await this.props.especieService.fetchEspecies(value);
            especies = especiesData.records;
        } else {
            especies = [];
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            },
            especies: especies,
        }));
    };

    handleSubmit = async (event) => {

        let speciesId = this.state.record.species_id;
        if (!this.recordId) {
            const statQueryResult = await this.props.adminRest.query({
                entity: 'species',
                outStatistics: [{
                    statisticType: 'max',
                    onStatisticField: 'species_id',
                    outStatisticFieldName: 'max_species_id',
                }],
            });
            speciesId = statQueryResult.records[0].max_species_id + 1;
        }

        let commonName = this.state.record.common_name;
        if (!commonName) {
            commonName = this.state.record.genus_species;
        }

        this.setState(prevState => ({
            record: {
                ...prevState.record,
                species_id: speciesId,
                common_name: commonName,
            }
        }), () => {
            let options = {
                action: this.recordId ? 'UPDATE' : 'INSERT',
                entity: 'species',
                attributes: this.state.record,
            };

            this.props.adminRest.save(options).then(data => {
                if (data.success) {
                    this.props.history.goBack();
                }
            });
        });

        event.preventDefault();
    };

    render() {
        const record = this.state.record;
        return (
            <div className="container">
                <Form submit={this.handleSubmit} encType="multipart/form-data">
                    {/*<div>{JSON.stringify(this.state.record)}</div>*/}
                    <h3>{this.recordId ? 'Editar especie #' + this.recordId : 'Nueva especie'}</h3>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="species_type">Tipo de especie <i className="text-danger">*</i></label>
                                <select className="form-control"
                                        id="species_type"
                                        name="species_type"
                                        value={this.state.record.species_type}
                                        required
                                        onChange={this.handleSpeciesTypeChange}>
                                    <option value="">- Escoger -</option>
                                    {SPECIES_TYPES.map(spType => (
                                        <option key={'species-types-' + spType.value}
                                                value={spType.value}>{spType.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Nombre científico"
                            name="genus_species"
                            required
                            value={record.genus_species}
                            autoFocus={true}
                            onChange={this.handleInputChange}
                        />
                        <FormControl
                            label="Nombre común"
                            name="common_name"
                            value={record.common_name}
                            onChange={this.handleInputChange}
                        />
                    </div>

                    <div className="row">
                        <FormControl
                            label="Nombre común inglés"
                            name="common_name_en"
                            value={record.common_name_en}
                            onChange={this.handleInputChange}
                            type="text"/>
                        <FormControl
                            label="Familia"
                            name="family"
                            value={record.family}
                            onChange={this.handleInputChange}
                        />
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <label>Origen</label>
                            <select className="form-control"
                                    name="origin"
                                    value={record.origin}
                                    onChange={this.handleInputChange}>
                                <option value="">- No aplica -</option>
                                {ORIGIN_OPTS.map(opt => (
                                    <option key={'ORIGIN_OPT-' + opt.value} value={opt.value}>{opt.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                    <input type="checkbox"
                                           name="active"
                                           onChange={this.handleInputChange}
                                           value={1}
                                           checked={record.active !== 0}
                                           className="form-check-input form-checkbox"/>
                                    Activo
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <EspecieSelect
                            onChange={this.handleInputChange}
                            labelText="Duplicado de especie"
                            hideNoAparece={true}
                            value={record.duplicates_id}
                            disabled={record.active !== 0}
                            name="duplicates_id"
                            options={this.state.especies}
                        />
                    </div>

                    <h3>Estatus</h3>

                    <div className="row">

                        <div className="col form-group">
                            <label>Estatus DRNA</label>
                            <select className="form-control"
                                    name="status_drna"
                                    value={record.status_drna}
                                    onChange={this.handleInputChange}>
                                <option value="">- No aplica -</option>
                                {DRNA_OPTS.map(opt => (
                                    <option key={'ORIGIN_OPT-' + opt.value} value={opt.value}>{opt.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col form-group">
                            <label>Estatus USFWS</label>
                            <select className="form-control"
                                    name="status_usfws"
                                    value={record.status_usfws}
                                    onChange={this.handleInputChange}>
                                <option value="">- No aplica -</option>
                                {USFWS_OPTS.map(opt => (
                                    <option key={'USFWS_OPTS-' + opt.value} value={opt.value}>{opt.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Status UICN</label>
                            <select className="form-control"
                                    name="status_uicn"
                                    value={record.status_uicn}
                                    onChange={this.handleInputChange}>
                                <option value="">- No aplica -</option>
                                {UICN_OPTS.map(opt => (
                                    <option key={'UICN_OPTS-' + opt.value} value={opt.value}>{opt.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>


                    <AuditData entity={record}/>


                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withSession(EspeciesEdit);



