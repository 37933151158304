//@flow
import React, {PureComponent} from 'react';
import {GeoJSONLayer} from "react-mapbox-gl";

type Props = {
    data: any,
}

export default class HexBoundariesLayer extends PureComponent<Props> {

    render() {
        return (this.props.data &&
            <GeoJSONLayer
                id="mdv-hex-outline"
                data={this.props.data}
                linePaint={{
                    "line-width": 0.5,
                    "line-color": "#003A0F",
                    "line-opacity": 0.6
                }}
                before="poi-scalerank4-l1"
            />)
    }

}