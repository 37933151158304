import React, {Component} from "react";
import Indicador1 from "./Indicador1";
import Indicador2 from "./Indicador2";
import Suelo from "./Suelo";
import DatosAdicionales from "./DatosAdicionales";
import {withSession} from "../../context/SessionContext";

class HumedalesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            especies: [],
        };
    }

    componentDidMount() {
        this.fetchEspecies();
    }

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('vegetacion').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    render() {
        return (
            <div className="row">
                <div className="col">
                    <DatosAdicionales eventoId={this.props.eventoId} especies={this.state.especies}/>
                    <Indicador1 eventoId={this.props.eventoId} especies={this.state.especies}/>
                    <Indicador2 eventoId={this.props.eventoId} especies={this.state.especies}/>
                    <Suelo eventoId={this.props.eventoId} especies={this.state.especies}/>
                </div>
            </div>
        )
    }
}

export default withSession(HumedalesList);