import React, {Component} from "react";
import { withRouter } from 'react-router-dom'

class BackButton extends Component {
    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this);
    }

    handleBack(event) {
        this.props.history.goBack();
        event.preventDefault();
    }

    render() {
        const btnClass = this.props.btnClass || 'primary'
        return (
            <button className={`btn btn-outline-${btnClass}`} onClick={this.handleBack}>Regresar</button>
        )
    }
}

export default withRouter(BackButton);