import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class OlasForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            windDirection: [
                "Este",
                "Este Nordeste",
                "Este Sudeste",
                "Noreste",
                "Noroeste",
                "Norte",
                "Norte Nordeste",
                "Oeste",
                "Oeste Noroeste",
                "Oeste Sudeste",
                "Sudeste",
                "Sudoeste",
                "Sur",
                "Sur Sudoeste"
            ]
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Perfíl de playa</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Número de transecto playa"
                            type="number"
                            name="transecto"
                            autoFocus={true}
                            col={6}
                            value={this.state.record.transecto}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Lectura"
                            type="number"
                            name="lectura"
                            col={6}
                            value={this.state.record.lectura}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Altura (p)"
                            type="number"
                            step="any"
                            name="altura"
                            col={6}
                            value={this.state.record.altura}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Período"
                            type="number"
                            step="any"
                            name="periodo"
                            col={6}
                            value={this.state.record.periodo}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Dirección de la ola"
                            type="text"
                            name="dir_ola"
                            col={6}
                            value={this.state.record.dir_ola}
                            onChange={this.handleInputChange}/>

                        <div className="col-6 form-group">
                            <label>Dirección del viento</label>
                            <select className="form-select form-control"
                                    name="dir_viento"
                                    value={this.state.record.dir_viento}
                                    onChange={this.handleInputChange}>
                                {this.state.windDirection.map(wind => (
                                    <option>{wind}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Velocidad del viento (m/s)"
                            type="number"
                            name="velocidad_viento"
                            col={6}
                            value={this.state.record.velocidad_viento}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default OlasForm;