//@flow
import React, {Fragment} from 'react';

import FormControl from "../FormControl";

const CUERPO_AGUA_OPTS = [
    "Ausente",
    "Charca artificial",
    "Lago/Laguna",
    "Ojo de agua/ Manantial",
    "Quebrada",
    "Quebrada intermitente",
    "Río",
    "Playa"
];

type Props = {
    evento: Object,
    onInputChange: (Object) => void,
}

export function SitioEdit(props: Props) {

    const evento = props.evento;
    if (evento && evento.tipo_evento !== 'MDV') {
        return <Fragment/>;
    }

    return (
        <Fragment>

            <h3 className="mt-3">Sitio</h3>
            <div className="row">
                <FormControl
                    name="ubicacion"
                    label="Ubicación"
                    value={evento.ubicacion}
                    required
                    onChange={props.onInputChange}
                />

                <FormControl
                    name="propietario"
                    label="Propietario"
                    value={evento.propietario}
                    required
                    onChange={props.onInputChange}
                />
            </div>

            <h3 className="mt-3">Uso del terreno</h3>

            <h5 className="mt-3">Conservación</h5>

            <div className="row">

                <FormControl
                    type="number"
                    col={2}
                    max={100} min={0}
                    name="conservacion_agricultura"
                    label="% Agricultura"
                    value={evento.conservacion_agricultura}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    max={100} min={0}
                    name="conservacion_conservacion"
                    label="% Conservación"
                    value={evento.conservacion_conservacion}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    max={100} min={0}
                    name="conservacion_ganaderia"
                    label="% Ganadería"
                    value={evento.conservacion_ganaderia}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    max={100} min={0}
                    name="conservacion_plantacion"
                    label="% Plantación"
                    value={evento.conservacion_plantacion}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    max={100} min={0}
                    name="conservacion_otro"
                    label="% Otro"
                    value={evento.conservacion_otro}
                    onChange={props.onInputChange}
                />

            </div>

            <h5 className="mt-3">Construído</h5>
            <div className="row">
                <FormControl
                    type="number"
                    col={2}
                    label="% Carretera"
                    max={100} min={0}
                    name="construido_carretera"
                    value={evento.construido_carretera}
                    onChange={props.onInputChange}
                    className="form-control"
                />
                <FormControl
                    type="number"
                    col={2}
                    label="% Industrial"
                    max={100} min={0}
                    name="construido_industrial"
                    value={evento.construido_industrial}
                    onChange={props.onInputChange}
                    className="form-control"
                />
                <FormControl
                    type="number"
                    col={2}
                    label="% Residencial"
                    max={100} min={0}
                    name="construido_residencial"
                    value={evento.construido_residencial}
                    onChange={props.onInputChange}
                    className="form-control"
                />
                <FormControl
                    type="number"
                    col={2}
                    label="% Recreativo"
                    max={100} min={0}
                    name="construido_recreativo"
                    value={evento.construido_recreativo}
                    onChange={props.onInputChange}
                />
            </div>


            <h3 className="mt-3">Cubierta del terreno</h3>
            <div className="row">
                <FormControl
                    type="number"
                    col={2}
                    name="cobertura_bosque"
                    label="% Bosque"
                    max={100} min={0}
                    value={evento.cobertura_bosque}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    name="cobertura_humedal"
                    label="% Humedal"
                    max={100} min={0}
                    value={evento.cobertura_humedal}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    name="cobertura_pastizal"
                    label="% Pastizal"
                    max={100} min={0}
                    value={evento.cobertura_pastizal}
                    onChange={props.onInputChange}
                />

                <FormControl
                    type="number"
                    col={2}
                    name="cobertura_otro"
                    label="% Otro"
                    max={100} min={0}
                    value={evento.cobertura_otro}
                    onChange={props.onInputChange}
                />

            </div>

            <h3 className="mt-3">Vegetación dominante</h3>
            <div className="row">
                <div className="col-2 form-group">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_arbustos"
                                   id="vegetacion_arbustos"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_arbustos !== 0}
                                   className="form-check-input form-checkbox"/>
                            Arbustos
                        </label>
                    </div>
                </div>
                <div className="col-2 form-group">
                    <div className="form-check form-check-inline">

                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_cactus"
                                   id="vegetacion_cactus"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_cactus !== 0}
                                   className="form-check-input form-checkbox"/>
                            Cactus
                        </label>
                    </div>
                </div>
                <div className="col-2 form-group">

                    <div className="form-check form-check-inline">

                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_gramineas"
                                   id="vegetacion_gramineas"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_gramineas !== 0}
                                   className="form-check-input form-checkbox"/>
                            Gramíneas
                        </label>
                    </div>
                </div>
                <div className="col-2 form-group">

                    <div className="form-check form-check-inline">

                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_helechos"
                                   id="vegetacion_helechos"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_helechos !== 0}
                                   className="form-check-input form-checkbox"/>
                            Helechos
                        </label>

                    </div>
                </div>
                <div className="col-2 form-group">
                    <div className="form-check form-check-inline">

                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_lenosa"
                                   id="vegetacion_lenosa"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_lenosa !== 0}
                                   className="form-check-input form-checkbox"/>
                            Leñosa
                        </label>

                    </div>
                </div>
                <div className="col-2 form-group">

                    <div className="form-check form-check-inline">

                        <label className="form-check-label">
                            <input type="checkbox"
                                   name="vegetacion_otro"
                                   id="vegetacion_otro"
                                   onChange={props.onInputChange}
                                   value={1}
                                   checked={evento.vegetacion_otro !== 0}
                                   className="form-check-input form-checkbox"/>
                            Otra
                        </label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-4 form-group">
                    <label>Cuerpo de agua</label>
                    <select className="form-control"
                            name="cuerpo_agua"
                            value={evento.cuerpo_agua}
                            onChange={props.onInputChange}>
                        <option value=""/>
                        {CUERPO_AGUA_OPTS.map(p => (
                            <option key={p} value={p}>{p}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md form-group">
                    <label>Descripción del paisaje</label>
                    <input type="text"
                           name="descripcion_paisaje"
                           value={evento.descripcion_paisaje || ''}
                           onChange={props.onInputChange}
                           className="form-control"/>
                </div>
            </div>

            <div className="row">
                <div className="col-4 form-group">
                    <label>Fotos del paisaje</label>
                    <label className="btn btn-primary form-control">Fotos del paisaje
                        <input name="foto_paisaje" type="file" hidden/>
                    </label>
                </div>
                <div className="col-md">
                    <div>
                        <label>Descripción de foto</label>
                        <div className="form-group">
                            <input type="text"
                                   name="descripcion_foto"
                                   value={evento.descripcion_foto || ''}
                                   onChange={props.onInputChange}
                                   className="form-control"/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}