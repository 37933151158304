//@flow
import React, {Component} from 'react';

import RestArcGIS from "../../util/rest-arcgis";
import type {GeoJSONFeature} from "@mapbox/geojson-types";
import LoadingIcon from "../LoadingIcon";


export async function getEventoByGlobalID() {
    const data = await this.props.rest.getByGlobalID({
        entity: 'evento',
        globalId: this.state.eventoId,
    });
}

export async function getHexagono(refDataRest: RestArcGIS, hexId: number): Feature {
    const hexResult = await refDataRest.queryGeoJSON({
        entity: 'hexagonos',
        where: `hex_id=${hexId}`
    });
    if (hexResult.features.length > 0) {
        return hexResult.features[0]
    }
    return null;
}

export type EventoProps = {
    evento: Object,
    hexagono: GeoJSONFeature,
}

export function withEvento(WrappedComponent,) {

    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                evento: {},
                hexagono: null,
                isLoaded: false,
            };
        }

        componentDidMount = async () => {
            if (!this.props.match.params.eventoId) {
                this.setState({isLoaded: true});
                return;
            }

            const evento = await this.props.rest.getByGlobalID({
                entity: 'evento',
                globalId: this.props.match.params.eventoId,
            });

            let hex = null;
            if (evento && evento.hex_id) {
                hex = await getHexagono(this.props.refDataRest, evento.hex_id);
            }

            this.setState({
                evento: evento,
                hexagono: hex,
                isLoaded: true,
            });
        };

        render = () => {
            if(this.state.isLoaded) {
                return <WrappedComponent evento={this.state.evento}
                                         hexagono={this.state.hexagono}
                                         {...this.props} />
            }

            return (
                <div className="container">
                    <LoadingIcon/>
                </div>
            );
        };
    };
}