//@flow
import React, {Component} from 'react';
import {Map as ReactMapboxGL, ScaleControl, ZoomControl} from 'react-mapbox-gl';
import '../mapa/MdvMapa.css'
import {DEFAULT_VIEWPORT, EMPTY_FEATURE_COLLECTION} from "../../../util/map";
import type {SessionProps} from "../../../context/SessionContext";
import {withSession} from "../../../context/SessionContext";
import HexBoundariesLayer from "../mapa/HexBoundariesLayer";
import HexLabelLayer from "../mapa/HexLabelLayer";
import EventoClusterLayer from "./EventoClusterLayer";

const ReactMap = ReactMapboxGL({
    accessToken: process.env.REACT_APP_MAPBOX_API_KEY
});

const MAP_STYLE = "mapbox://styles/mapbox/streets-v9";

type Props = {
    ...SessionProps,
    height?: number,
    hexId: number
}

class HexMap extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                ...DEFAULT_VIEWPORT,
                zoom: 12,
            },
            map: null,
            mapContainerHeight: this.props.height || window.innerHeight,
            boundaries: EMPTY_FEATURE_COLLECTION,
            centroids: EMPTY_FEATURE_COLLECTION,
            centroid: EMPTY_FEATURE_COLLECTION,
            eventos: EMPTY_FEATURE_COLLECTION,
        };

    }

    async componentDidMount() {

        const centroid = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_centroids_v',
            where: "hex_id=" + this.props.hexId,
        });
        const centroidCoords = centroid.features[0].geometry.coordinates;
        this.setState({
            viewport: {
                ...this.state.viewport,
                longitude: centroidCoords[0],
                latitude: centroidCoords[1],
            },
        });

        const boundaries = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_boundaries'
        });
        this.setState({
            boundaries: boundaries,
        });

        const centroids = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_centroids_v'
        });
        this.setState({
            centroids: centroids,
        });
    }

    render() {

        const vport = this.state.viewport;

        const mapStyle = {
            width: '100%',
            height: this.state.mapContainerHeight
        };

        return (
            <ReactMap
                style={MAP_STYLE}
                center={[vport.longitude, vport.latitude]}
                zoom={[vport.zoom]}
                bearing={vport.bearing}
                containerStyle={mapStyle}
                onStyleLoad={this.initMap}>

                <ZoomControl position="topLeft" style={{left: '10px', top: '10px'}}/>
                <ScaleControl position="bottomRight" style={{bottom: '30px', right: '10px'}}/>


                <HexBoundariesLayer data={this.state.boundaries}/>
                <HexLabelLayer data={this.state.centroids}
                               minZoom={8} textSize={14}/>


                <EventoClusterLayer/>

            </ReactMap>
        );
    }

    initMap = (map, evt) => {
        this.setState({
            map: map,
        });
    };

}


export default withSession(HexMap);
