//@flow
import React from 'react';
import type {IUser} from '@esri/arcgis-rest-types';
import RestArcGIS from "../util/rest-arcgis";
import {UserSession} from "@esri/arcgis-rest-auth";
import EspecieService from "../util/service";

export type SessionProps = {
    session: UserSession,
    user: IUser,
    rest: RestArcGIS,
    refDataRest: RestArcGIS,
    especieService: EspecieService,
    adminRest: RestArcGIS,
}

export const SessionContext = React.createContext({
    session: null,
    user: null,
    rest: null,
    refDataRest: null,
    especieService: null,
    adminRest: null,
});

// This function takes a component...
export function withSession(Component) {
    // ...and returns another component...
    return function SessionComponent(props) {
        // ... and renders the wrapped component with the context User!
        // Notice that we pass through any additional props as well
        return (
            <SessionContext.Consumer>
                {({session, user, rest, refDataRest, especieService, adminRest}) => {
                    return (
                        <Component
                            {...props}
                            session={session}
                            user={user}
                            rest={rest}
                            refDataRest={refDataRest}
                            especieService={especieService}
                            adminRest={adminRest}
                        />
                    );
                }}
            </SessionContext.Consumer>
        );
    };
}

