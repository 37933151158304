import React, {Component} from "react";
import TableContainer from "../TableContainer";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class CaracteristicasQuimicas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            resultRecordCount: limit,
            entity: 'cuenca_caracteristicas_quimicas',
            where: `evento_GlobalID='${this.state.eventoId}'`,
            outFields: ['OBJECTID', 'sitio_xquimica', 'temperatura', 'dissolved_oxygen', 'ph', 'tds', 'salinity', 'n', 'p', 'comm_quimica'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        }
    }

    render() {
        return (
            <TableContainer title="Características químicas"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.state.eventoId}/cuencas/quimicas/new`}>
                <table className="table table-hover table-bordered table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>X Site point</th>
                        <th>Temperatura</th>
                        <th>Do (mg/l)</th>
                        <th>pH</th>
                        <th>TDS</th>
                        <th>S (ppm)</th>
                        <th>N</th>
                        <th>P</th>
                        <th>Comentarios</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.sitio_xquimica}</td>
                                <td>{record.temperatura}</td>
                                <td>{record.dissolved_oxygen}</td>
                                <td>{record.ph}</td>
                                <td>{record.tds}</td>
                                <td>{record.salinity}</td>
                                <td>{record.n}</td>
                                <td>{record.p}</td>
                                <td>{record.comm_quimica}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.state.eventoId}/cuencas/quimicas/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(CaracteristicasQuimicas);