import React, {Component} from 'react';
import {parseHashQueryString} from '../util/util';

export default function withPermalink(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                permalinkData: parseHashQueryString(window.location.hash)
            };

            this.onWindowPopState = this.onWindowPopState.bind(this);
            this.updatePermalink = this.updatePermalink.bind(this);
        }

        componentDidMount() {
            window.addEventListener('popstate', this.onWindowPopState);
        }

        componentWillUnmount() {
            window.removeEventListener('popstate', this.onWindowPopState);
        }

        onWindowPopState(event) {
            if (event.state === null) {
                return;
            }

            this.setState({
                permalinkData: event.state
            });
        }

        updatePermalink(permalinkData) {
            this.setState({ permalinkData: permalinkData });
            window.history.pushState(permalinkData, '', this.writePermalink(permalinkData));
        }

        writePermalink(permalinkData) {
            const prevUrlHash = window.location.hash;
            let hashStr = (prevUrlHash ? prevUrlHash.split("?")[0] : '');
            let first = true;
            for(let [key,val]  of Object.entries(permalinkData)) {
                if (first) {
                    hashStr += '?';
                    first = false;
                } else {
                    hashStr += '&';
                }
                hashStr += `${key}=${val}`;
            }
            return hashStr;
        }

        render() {
            return <WrappedComponent permalinkData={this.state.permalinkData} updatePermalink={this.updatePermalink} {...this.props} />;
        }
    };
}

