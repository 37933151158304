//@flow
import React, {Fragment} from 'react';
import {defaults, HorizontalBar} from "react-chartjs-2";
import {FaFileExport} from "react-icons/fa";
import LoadingIcon from "../../LoadingIcon";
import {convertStatsToCSV} from "../../../util/util";


defaults.global.defaultFontFamily = 'Avenir-Roman';

type EditorEventoStats = {
    editor: string,
    evento: number,
}

type Props = {
    editorEventoStats: EditorEventoStats[],
}

export function EditorEventoStatsGraph(props: Props) {

    if(!props.editorEventoStats) {
        return <LoadingIcon/>
    }

    const data = {
        labels: props.editorEventoStats.map(f => f.editor ? f.editor : '* Desconocido *'),
        datasets: [{
            data: props.editorEventoStats.map(f => f.evento),
            backgroundColor: 'rgba(228,26,28, 0.7)',
            borderColor: 'rgba(228,26,28, 0.8)',
            label: 'Eventos',
        }]
    };
    const options = {
        responsive: true,
    };

    return (
        <Fragment>
            <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                <div className="col">
                    <h3>Eventos por editor</h3>
                </div>
                <div className="col text-right">
                    <a className="btn btn-primary"
                       href={URL.createObjectURL(new Blob([convertStatsToCSV(props.editorEventoStats)], {type: 'text/csv;charset=utf-8;'}))}
                       download={`eventos_por_editor.csv`}><FaFileExport/> Exportar</a>
                </div>
            </div>
            <HorizontalBar data={data} options={options}/>
        </Fragment>
    );
}

EditorEventoStatsGraph.defaultProps = {
    editorEventoStats: [],
};