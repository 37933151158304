//@flow
import React, {Component} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../TableContainer";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";

type Props = {
    ...SessionProps,
    eventoId: string,
}

class TransectosLineales extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            records: []
        };
    }

    fetchRecords = async (limit, pageNumber) => {
        const data = await this.props.rest.query({
            entity: 'reptil_muestreo_transecto',
            resultRecordCount: limit,
            _pageNumber: pageNumber,
            where: `evento_GlobalID='${this.props.eventoId}'`,
            outFields: ['OBJECTID', 'num_transecto_lineal', 'segmento_transecto', 'especie_id', 'especie_txt',
                'cnt_individuos_cuadrante', 'componente_habitat', 'comportamiento_reptil'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: limit,
            pageNumber: pageNumber,
            totalRecords: data.totalRecords,
        }
    };

    render() {
        return (
            <TableContainer title="Transectos lineales"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.props.eventoId}/reptiles/transectos/new`}>
                <table className="table table-hover table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th style={{width: 10 + '%'}}>Num. de transecto</th>
                        <th style={{width: 10 + '%'}}>Segmento de transecto</th>
                        <th>Especie</th>
                        <th style={{width: 10 + '%'}}>Num. de individuos</th>
                        <th>Hábitat</th>
                        <th>Comportamiento</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={'transecto-lineal-' + record.OBJECTID}>
                                <td>{record.num_transecto_lineal}</td>
                                <td>{record.segmento_transecto}</td>
                                <td>{this.props.especieService.getSelectedSpecieName(this.props.especies, record.especie_id) || record.especie_txt}</td>
                                <td>{record.cnt_individuos_cuadrante}</td>
                                <td>{record.componente_habitat}</td>
                                <td>{record.comportamiento_reptil}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.props.eventoId}/reptiles/transectos/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(TransectosLineales);