import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import sapoConchoImg from '../../images/1-sapo-concho1.jpg'
import arbolesCampeonesImg from '../../images/2-ChampionTrees.jpg'
import mapaDeVidaImg from '../../images/3-mapa-de-vida-puerto-rico1.jpg'
import MdvMapa from "../mdv/mapa/MdvMapa";


class Inicio extends Component {

    render() {
        return (
            <div className="container">

                <h2>Sistema Mapa de Vida v{process.env.REACT_APP_VERSION}</h2>
                <div className="row mb-3">
                    <div className="col">
                        <MdvMapa height={500}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-5">
                        <p>¡Bienvenidos al sistema Mapa de Vida v{process.env.REACT_APP_VERSION}! Para ingresar datos, favor de iniciar la
                            sesión en el menú de la izquierda.</p>
                    </div>
                    <div className="col-7">

                        <div className="row">
                            <div className="col-4 col-xs-6">
                                <Link to="/mdv/mapa">
                                    <img className="img-fluid rounded"
                                         src={mapaDeVidaImg}
                                         alt="Mapa de Vida"/></Link><p/>
                                <h5 className="text-center">
                                    <Link to="/mdv/mapa">Mapa de Vida</Link></h5>
                            </div>
                            <div className="col-4 col-xs-6">
                                <Link to="/arboles/mapa">
                                <img className="img-fluid rounded"
                                     src={arbolesCampeonesImg}
                                     alt="Arboles Campeones"/></Link><p/>
                                <h5 className="text-center">
                                    <Link to="/arboles/mapa">Árboles Campeones</Link></h5>
                            </div>
                            <div className="col-4 col-xs-6">
                                <Link to="/sapo/mapa">
                                <img className="img-fluid rounded"
                                     src={sapoConchoImg}
                                     alt="Sapo Concho"/></Link><p/>
                                <h5 className="text-center">
                                    <Link to="/sapo/mapa">Sapo Concho</Link></h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Inicio;



