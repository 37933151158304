import React, {Component} from "react";
import TableContainer from "../TableContainer";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class Remocion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            entity: 'sapo_concho_remocion',
            where: `evento_GlobalID = '${this.state.eventoId}'`,
            outFields: ['OBJECTID', 'especie_txt', 'hileras', 'renacuajos', 'latitud', 'longitud', 'GlobalID'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });

        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        };
    }

    render() {
        return (
            <TableContainer title="Remoción de hileras y renacuajos"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/sapo-concho/${this.state.eventoId}/remocion/new`}>

                <table className="table table-bordered table-striped table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>Especie</th>
                        <th>Hileras</th>
                        <th>Renacuajos</th>
                        <th>Latitud</th>
                        <th>Longitud</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.especie_txt}</td>
                                <td>{record.hileras}</td>
                                <td>{record.renacuajos}</td>
                                <td>{record.latitud}</td>
                                <td>{record.longitud}</td>
                                <td className="text-center">
                                    <Link to={`/sapo-concho/${this.state.eventoId}/remocion/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}


                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(Remocion);