//@flow
import React, {PureComponent, Fragment} from 'react';
import {FaFileExport} from 'react-icons/fa';

const PROTOCOLS = new Map([
    ['total', {title: 'Total', hasEspecie: true}],
    ['aves', {title: 'Aves', hasEspecie: true}],
    ['vegetacion', {title: 'Vegetación', hasEspecie: true}],
    ['reptil', {title: 'Reptiles', hasEspecie: true}],
    ['humedal', {title: 'Humedales', hasEspecie: true}],
    ['playa', {title: 'Playas', hasEspecie: false}],
    ['cuenca', {title: 'Cuencas', hasEspecie: false}],
]);

type Props = {
    hexStats: Object,
    hexId?: number | string,
}

const convertHexStatsToCSV = (fProps): string => {
    const headers = [
        'Protocolo',
        'Eventos',
        'Abundancia',
        'Diversidad',
    ];
    let csvStr = headers.join(',') + '\r\n';
    for (const protocolKey of PROTOCOLS.keys()) {
        const values = [PROTOCOLS.get(protocolKey).title];
        let columnPrefixes = ['evt_', 'esp_', 'div_'];
        for (const colPrefix of columnPrefixes) {
            values.push(fProps[colPrefix + protocolKey])
        }
        csvStr += values.join(',') + '\r\n';
    }
    return csvStr;
};

export default class HexStatsTableHeader extends PureComponent<Props> {
    render() {
        return (
            <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                <div className="col-6">
                    <h3>Estadísticas {this.props.hexId ? ' de hexágono #' + this.props.hexId : 'globales'}</h3>
                </div>
                <div className="col-6 text-right">
                    <a className="btn btn-primary"
                       href={URL.createObjectURL(new Blob([convertHexStatsToCSV(this.props.hexStats)], {type: 'text/csv;charset=utf-8;'}))}
                       download={`hexagono${this.props.hexId ? '_' + this.props.hexId : ''}_estadisticas.csv`}><FaFileExport/> Exportar</a>
                </div>
            </div>
        );
    }
}
