import React, {Component} from "react";
import PropTypes from 'prop-types';


class FormControl extends Component {

    render() {

        const name = this.props.name || this.props.id;
        const id = this.props.id || this.props.name;

        return (
            <div className={`form-group mb-3 ${this.props.col ? `col-${this.props.col}` : 'col'}`}>
                <label htmlFor={id}>{this.props.label}{this.props.required ? <i className="text-danger">*</i>: null}</label>
                <input {...this.props}
                       id={id}
                       name={name}
                       className={`form-control ${this.props.className || ''}`}
                       type={this.props.type || 'text'}/>
                <div className='invalid-feedback'>&nbsp;</div>
            </div>
        )
    }
}

FormControl.propTypes = {
    id: PropTypes.string,
    col: PropTypes.number,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
    ]),
    type: PropTypes.string,
    className: PropTypes.string,
    invalidFeedback: PropTypes.string,
};

export default FormControl;