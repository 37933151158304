//@flow
import React, {Component} from 'react';
import {withSession} from "../../context/SessionContext";
import {Link} from "react-router-dom";
import PageNav from "../pagenav/PageNav";
import {FaPlus, FaSearch, FaCheckSquare, FaSquare} from 'react-icons/fa';
import withPermalink from "../withPermalink";
import {ORIGIN_OPTS, USFWS_OPTS, DRNA_OPTS, UICN_OPTS, SPECIES_TYPES} from "./common";



class EspeciesList extends Component {
    constructor(props) {
        super(props);
        const plinkData = this.props.permalinkData;
        this.state = {
            page: {
                limit: 15,
                offset: plinkData && plinkData.page ? Number(plinkData.page) : 1,
                totalRecords: 0
            },
            especieType: plinkData && plinkData.especieType ? plinkData.especieType : '',
            records: [],
        };

    }

    componentDidMount() {
        this.fetchEspecies(this.state.especieType, this.state.page.offset);
    }

    fetchEspecies(especieType: string, pageNumber: number) {
        this.props.adminRest.query({
            _pageNumber: pageNumber,
            entity: 'species',
            where: especieType ? `species_type = '${especieType}'` : '1=1',
            orderByFields: 'genus_species'
        },).then(data => {
            this.setState({
                especieType: especieType,
                page: {limit: data.limit, offset: pageNumber, totalRecords: data.totalRecords},
                records: data.records
            })
        });
    }

    getLabel(opts: { value: string, label: string }[], value: string): string {
        if (!value) {
            return '';
        }

        const found = opts.find(opt => opt.value === value);
        return found ? found.label : value;
    }

    handleTypeChange = (event) => {
        const especieType = event.target.value;
        this.fetchEspecies(especieType, 1);
        if (this.props.updatePermalink) {
            this.props.updatePermalink({
                especieType: especieType,
                page: '' + 1,
            });
        }
    };

    handlePaginationChange = (pageNumber) => {
        this.fetchEspecies(this.state.especieType, pageNumber);
        if (this.props.updatePermalink) {
            this.props.updatePermalink({
                especieType: this.state.especieType || '',
                page: '' + pageNumber
            });
        }
    };

    render() {
        return (
            <div className="container">
                <h3>Especies</h3>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="tipoEvento">Tipo de especie</label>
                            <select className="form-control"
                                    id="tipoEvento"
                                    onClick={this.handleTypeChange}>
                                <option value="">Todas</option>
                                {SPECIES_TYPES.map(spType => (
                                    <option key={'species-types-' + spType.value}
                                            value={spType.value}>{spType.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group text-right align-bottom">
                            <Link className="btn btn-success" to="/admin/especies/new" role="button">
                                <FaPlus/> Añadir especie
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="table-responsive mb-3">
                    <table className="table table-hover table-sm table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th style={{textAlign: 'right'}}>#</th>
                            <th>Tipo</th>
                            <th>Nombre científico</th>
                            <th>Nombre común</th>
                            <th>Origen</th>
                            <th>DRNA</th>
                            <th>USFWS</th>
                            <th>UICN</th>
                            <th>Activo</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.records.map(r => {
                            const speciesTypeObj = SPECIES_TYPES.find(spType => spType.value === r.species_type);
                            return (
                                    <tr key={r.species_id}>
                                        <td style={{textAlign: 'right'}}>{r.species_id}</td>
                                        <td>{speciesTypeObj ? speciesTypeObj.label : r.species_type}</td>
                                        <td>{r.genus_species}</td>
                                        <td>{r.common_name}</td>
                                        <td>{this.getLabel(ORIGIN_OPTS, r.origin)}</td>
                                        <td>{this.getLabel(DRNA_OPTS, r.status_drna)}</td>
                                        <td>{this.getLabel(USFWS_OPTS, r.status_usfws)}</td>
                                        <td>{this.getLabel(UICN_OPTS, r.status_uicn)}</td>
                                        <td style={{textAlign: 'center'}}>{r.active ? <FaCheckSquare/> : <FaSquare/>}</td>
                                        <td className="text-center">
                                            <Link to={`/admin/especies/${r.species_id}`}><FaSearch/></Link>
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                        </tbody>
                    </table>
                </div>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />
            </div>
        );
    }
}

export default withSession(withPermalink(EspeciesList));