//@flow
import React from 'react';
import {Doughnut, defaults} from "react-chartjs-2";

defaults.global.defaultFontFamily = 'Avenir-Roman';
// defaults.global.defaultFontSize = 12;

export function LandCoverChart(props) {

    const data = {
        datasets: [{
            data: [
                props.evento.cobertura_bosque,
                props.evento.cobertura_humedal,
                props.evento.cobertura_pastizal,
                props.evento.cobertura_otro,
            ],
            backgroundColor: [
                'rgba(228,26,28, 0.8)',
                'rgba(55,126,184, 0.8)',
                'rgba(77,175,74, 0.8)',
                'rgba(152,78,163, 0.8)',
            ],
            label: 'Dataset 1'
        }],
        labels: [
            'Bosque',
            'Humedal',
            'Pastizal',
            'Otro',
        ]
    };
    const options = {
        responsive: true,
        legend: {
            position: 'right',
        },
        title: {
            display: true,
            text: 'Cubierta del terreno'
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
        <Doughnut data={data} options={options}/>
    );
}