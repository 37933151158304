import React, {Component} from "react";
import CaracteristicasQuimicas from "./CaracteristicasQuimicas";
import CaracteristicasFisicas from "./CaracteristicasFisicas";
import Macroinvertebrados from "./Macroinvertebrados";
import Rio from "./Rio";

class CuencaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId
        };
    }

    render() {
        return (
            <div className="row">
                <div className="col">
                    <Rio eventoId={this.state.eventoId}/>
                    <CaracteristicasQuimicas eventoId={this.state.eventoId}/>
                    <CaracteristicasFisicas eventoId={this.state.eventoId}/>
                    <Macroinvertebrados eventoId={this.state.eventoId}/>
                </div>
            </div>
        )
    }
}

export default CuencaList;