//@flow
import React, {Component} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../TableContainer";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";
import PageNav from "../pagenav/PageNav";
import type {SessionProps} from "../../context/SessionContext";

type Props = {
    ...SessionProps,
    eventoId: string,
}

class VegetacionList extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            page: {
                limit: 15,
                offset: 1,
                totalRecords: 0
            },
            records: [],
            especies: [],
        };
    }

    componentDidMount() {
        this.fetchEspecies();
    }

    fetchRecords = async (limit, pageNumber) => {
        const data = await this.props.rest.query({
            entity: 'vegetacion',
            resultRecordCount: limit,
            _pageNumber: pageNumber,
            where: `evento_GlobalID='${this.props.eventoId}'`,
            outFields: ['OBJECTID', 'idtransecto', 'especie_id', 'especie_txt', 'altura_vegetacion', 'dbh', 'distancia_vegetacion'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: limit,
            pageNumber: pageNumber,
            totalRecords: data.totalRecords,
        }
    };

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('vegetacion').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    render() {
        return (
            <TableContainer title="Datos compilados de vegetación"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.props.eventoId}/vegetacion/new`}>
                <table className="table table-hover table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>ID Transecto</th>
                        <th>Especie ID</th>
                        <th>Especie</th>
                        <th>Altura (p)</th>
                        <th>DBH (cm)</th>
                        <th>Distancia</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.idtransecto}</td>
                                <td>{record.especie_id}</td>
                                <td>{this.props.especieService.getSelectedSpecieName(this.state.especies, record.especie_id) || record.especie_txt}</td>
                                <td>{record.altura_vegetacion}</td>
                                <td>{record.dbh}</td>
                                <td>{record.distancia_vegetacion}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.props.eventoId}/vegetacion/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />
            </TableContainer>
        )
    }
}

export default withSession(VegetacionList);