//@flow
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FaPencilAlt, FaPlus, FaSearch, FaTrash, FaInfoCircle} from "react-icons/fa";
import {Button} from "reactstrap";
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";


type Props = {
    ...SessionProps,
    eventoId: string,
}

class Rio extends Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            record: {},
            fotos: []
        };

        this.addImage = this.addImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    async componentDidMount() {
        const cuencaData = await this.props.rest.query({
            resultRecordCount: 1,
            entity: 'cuenca',
            where: `evento_GlobalID = '${this.state.eventoId}'`,
        });

        const cuencaPhotoData = await this.props.rest.query({
            entity: 'cuenca_foto_diagrama',
            where: `evento_GlobalID='${this.state.eventoId}'`,
            outFields: 'OBJECTID'
        });

        let cuencaPhotos = [];
        let rio = cuencaData.records[0];

        if(!rio) {
            rio = {
                evento_GlobalID: this.state.eventoId
            };
            const cuencaResponse = await this.props.rest.save({
                action: 'INSERT',
                entity: 'cuenca',
                attributes: rio
            });
            Object.assign(rio, cuencaResponse)
        }

        let cuencaPhotoIds = cuencaPhotoData.records
            .flatMap(cuencaPhoto => {
                return cuencaPhoto.OBJECTID;
            });

        //array.forEach does not support calling 'await' methods inside its callback function
        for (let i = 0; i < cuencaPhotoIds.length; i++) {
            let photos = await this.props.rest.getPhotos({
                entity: 'cuenca_foto_diagrama',
                id: cuencaPhotoIds[i]
            });
            cuencaPhotos = cuencaPhotos.concat(photos);
        }

        this.setState({
            record: rio || {},
            fotos: cuencaPhotos
        })
    };

    addImage = async (event) => {
        const target = event.target;
        const value = target.value;

        if (value) {
            let files = document.querySelector("input[name='foto_cuenca'").files;

            let cuenca_diagram_foto_record = {
                'evento_objectid': this.state.record.OBJECTID,
                'evento_GlobalID': this.state.eventoId
            };

            let options = {
                action: 'INSERT',
                entity: 'cuenca_foto_diagrama',
                attributes: cuenca_diagram_foto_record,
                attachment: files.length > 0 ? files[0] : null
            };

            this.props.rest.save(options).then(data => {
                if (data.success) {
                    this.fetchRecord();
                }
            });
        }
    };

    removeImage = async (photo) => {
        let options = {
            entity: photo.entity.name,
            id: photo.entity.id,
        };

        this.props.rest.delete(options).then(data => {
            if (data.success) {
                this.fetchRecord();
            }
        });
    };

    render() {

        return (
            <div style={{paddingBottom: 2 + "em"}}>
                <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                    <div className="col-6">
                        <h3>Río</h3>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="btn btn-success"
                              to={`/evento/${this.state.eventoId}/cuencas/rio/${this.state.record.OBJECTID}`}
                              role="button">
                            <FaPencilAlt/> Editar
                        </Link>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-4">
                        <span className="font-weight-bold">Nombre: </span>{this.state.record.nombre_rio}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Ancho del Canal Activo (m): </span>{this.state.record.ancho_canal}
                    </div>
                    <div className="col-4">
                        <span className="font-weight-bold">Ancho del Caudal (m): </span>{this.state.record.ancho_base}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="font-weight-bold">Sustrato: </span>{this.state.record.substrato_dominante}
                    </div>
                    <div className="col-4">
                        <span className="font-weight-bold">Condición: </span>{this.state.record.condicion_canal}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Alteración Hidrológica: </span>{this.state.record.alteracion_hydro}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="font-weight-bold">Zona Ribereña: </span>{this.state.record.zona_riberena}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Estabilidad del Banco: </span>{this.state.record.estabilidad_banco}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Apariencia del Agua: </span>{this.state.record.apariencia_agua}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Abundancia de Nutrientes: </span>{this.state.record.abundancia_nutrientes}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Barreras al movimiento de los peces: </span>{this.state.record.barreras}
                    </div>
                    <div className="col-4">
                        <span className="font-weight-bold">Refugios de peces: </span>{this.state.record.cant_peces}
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span className="font-weight-bold">Pozas: </span>{this.state.record.pozas}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Hábitat de invertebrados: </span>{this.state.record.habitat_invertebrados}
                    </div>
                    <div className="col-4">
                        <span className="font-weight-bold">Puntuación promedio: </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Elevación río arriba: </span>{this.state.record.elevacion_rio_arriba}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Elevación río abajo: </span>{this.state.record.elevacion_rio_abajo}
                    </div>
                    <div className="col-4">
                        <span
                            className="font-weight-bold">Elevación río sitio X: </span>{this.state.record.elevacion_sitio_x}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card-group">
                            {this.state.fotos.map((foto, index) => (
                                <div className="card" key={index}>
                                    <img alt="" className="card-img-top" src={foto.attachment.url}/>
                                    <div className="card-footer text-right">
                                        <Button color="primary" size="sm"><FaSearch/></Button>{' '}
                                        <Button color="danger" size="sm" onClick={() => {
                                            this.removeImage(foto)
                                        }}><FaTrash/></Button>{' '}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: 1 + 'em'}}>
                    <div className="col-2 offset-10">
                        <label className="btn btn-success form-control"><FaPlus/> Añadir Foto
                            <input name="foto_cuenca" type="file" hidden onChange={this.addImage}/>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}

export default withSession(Rio)