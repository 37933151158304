//@flow
import React, {PureComponent, Fragment} from 'react';
import {Popup} from "react-mapbox-gl";
import type {SessionProps} from "../../../context/SessionContext";
import {withSession} from "../../../context/SessionContext";
import {Link} from "react-router-dom";
import HexStatsTable from "./HexStatsTable";

type Props = {
    ...SessionProps,
}

class HexPopup extends PureComponent<Props, {}> {

    render() {
        const fProps = this.props.feature.properties;
        return (
            <Popup coordinates={this.props.coordinates} anchor="bottom" style={{textAlign: 'center' }}>
                <button className="mapboxgl-popup-close-button" type="button" aria-label="Close popup"
                        onClick={() => this.props.onClickClose && this.props.onClickClose() }>×</button>
                <div style={{fontWeight: 'bold'}}>
                    Hexágono #{fProps.hex_id}&nbsp;&nbsp;&nbsp;<Link
                    style={{textDecoration: 'underline'}}
                    to={`/hex/${fProps.hex_id}`}>Más info</Link>
                </div>

                {fProps.evt_total > 0 &&
                <HexStatsTable hexStats={fProps}
                />}
            </Popup>
        )
    }
}

export default withSession(HexPopup)