//@flow

import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {FaInfoCircle, FaPlus} from 'react-icons/fa';
import PageNav from "./pagenav/PageNav";

type Props = {
    limit?: number,
    fetchRecords: () => void,
    title: string,
    newRecordUrl: string,
}

class TableContainer extends Component<Props> {

    static defaultProps = {
        limit: 15,
    };

    constructor(props) {
        super(props);
        this.state = {
            page: {
                limit: this.props.limit,
                pageNumber: 1,
                totalRecords: 0
            }
        };
    }

    async componentDidMount() {
        const page = await this.props.fetchRecords(this.state.page.limit, 1)
        this.setState({
            page: page
        });
    }

    handlePaginationChange = pageNumber => {
        this.props.fetchRecords(this.state.page.limit, pageNumber)
            .then((page) => {
                this.setState({
                    page: page
                });
            });
    };

    render() {
        let innerHtml = null;
        if (this.state.page.totalRecords > 0) {

            let pageNav = null;
            if (this.state.page.totalRecords > this.state.page.limit) {
                pageNav = (
                    <div className="row">
                        <div className="col text-center mt-3">
                            <PageNav
                                activePage={this.state.page.pageNumber}
                                itemsCountPerPage={this.state.page.limit}
                                totalItemsCount={this.state.page.totalRecords}
                                onChange={this.handlePaginationChange}
                            />
                        </div>
                    </div>
                );
            }

            innerHtml = (
                <Fragment>
                    {this.props.children}
                    {pageNav}
                </Fragment>
            );
        } else {
            innerHtml = (
                <div className="alert border text-center">
                    <FaInfoCircle/> No existen datos
                </div>
            )
        }

        return (
            <Fragment>
                <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                    <div className="col-6">
                        <h3>{this.props.title}</h3>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="btn btn-success" to={this.props.newRecordUrl} role="button">
                            <FaPlus/> Añadir nuevos datos
                        </Link>
                    </div>
                </div>
                {innerHtml}
            </Fragment>
        )
    }
}

export default TableContainer;