//@flow
import React, {Component} from "react";
import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {Link} from "react-router-dom";
import {FaSearch} from 'react-icons/fa';
import {withSession} from '../../context/SessionContext'
import {formatDate} from '../../util/format';
import type {QueryResults} from "../../util/rest-arcgis";
import RestArcGIS from "../../util/rest-arcgis";
import withPermalink from "../withPermalink";
import PageNav from "../pagenav/PageNav";


class EventoList extends Component {

    rest: RestArcGIS;

    constructor(props) {
        super(props);
        const plinkData = this.props.permalinkData;
        this.state = {
            page: {
                limit: 15,
                offset: plinkData && plinkData.page ? Number(plinkData.page) : 1,
                totalRecords: 0
            },
            eventos: [],
            eventoTypes: [
                { code: 'mdv-aves', desc: 'Aves' },
                { code: 'mdv-vegetaciones', desc: 'Vegetación' },
                { code: 'mdv-reptiles', desc: 'Reptiles' },
                { code: 'mdv-playa', desc: 'Playa' },
                { code: 'mdv-cuenca', desc: 'Cuenca' },
                { code: 'mdv-humedal', desc: 'Humedal' },
                // { code: 'mdv-tortugas', desc: 'Tortugas' },
                // { code: 'mdv-mariposas', desc: 'Mariposas' },
                // { code: 'mdv-anfibios', desc: 'Anfíbios' },
            ],
            selectedEventoType: plinkData && plinkData.selectedEventoType ? plinkData.selectedEventoType : '',
        };
        this.rest = props.rest;
    }

    componentDidMount() {
        this.fetchEventos(this.state.selectedEventoType, this.state.page.offset);
    }

    fetchEventos = async (type, pageNumber) => {
        const data: QueryResults = await this.rest.query({
            _pageNumber: pageNumber,
            entity: 'evento',
            where: "tipo_evento = 'MDV'" + (type ? `AND tipo_mdv = '${type}'` : ''),
            outFields: ['OBJECTID', 'tipo_mdv', 'fecha', 'hex_id', 'GlobalID', 'estatus', 'nombre', 'ubicacion'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            selectedEventoType: type,
            page: {limit: data.limit, offset: pageNumber, totalRecords: data.totalRecords},
            eventos: data.records
        })
    };

    toggleDropDown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    handleTypeChange = (event) => {
        const selectedEventoType = event.target.value;
        this.fetchEventos(selectedEventoType, 1);
        if(this.props.updatePermalink) {
            this.props.updatePermalink({
                selectedEventoType: selectedEventoType,
                page: '' + 1,
            })
        }
    };

    handlePaginationChange = (pageNumber) => {
        this.fetchEventos(this.state.selectedEventoType, pageNumber);
        if(this.props.updatePermalink) {
            this.props.updatePermalink({
                selectedEventoType: this.state.selectedEventoType || '',
                page: '' + pageNumber
            })
        }
    };

    render() {
        return (
            <div className="container">
                <h3>Eventos Mapa de Vida</h3>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="tipoEvento">Tipo de evento</label>
                            <select className="form-control"
                                    id="tipoEvento"
                                    onClick={this.handleTypeChange}>
                                <option>Todos</option>
                                {this.state.eventoTypes.map(eventoType => (
                                    <option key={eventoType.code} value={eventoType.code}>{eventoType.desc}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group text-right align-bottom">
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                <DropdownToggle caret color="success">
                                    Nuevo Evento
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.state.eventoTypes.map(evtType => (
                                        <Link key={evtType.code} to={`/evento/new/${evtType.code}`}><DropdownItem>{evtType.desc}</DropdownItem></Link>
                                    ))}
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                </div>

                <div className="table-responsive mb-3">
                    <table className="table table-hover table-sm table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Nombre</th>
                                <th>Estatus</th>
                                <th style={{textAlign: 'right'}}>ID Hexágono</th>
                                <th className="text-center">Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.eventos && this.state.eventos.map(evento => (
                                    <tr key={evento.OBJECTID}>
                                        <td>{this.getEventoDescription(evento.tipo_mdv)}</td>
                                        <td>{formatDate(evento.fecha)}</td>
                                        <td>{evento.nombre}</td>
                                        <td>{evento.estatus}</td>
                                        <td style={{textAlign: 'right'}}>{evento.hex_id}</td>
                                        {/*<td style={{textAlign: 'center'}}>{evento.valid_coords ? <FaCheckSquare/> : <FaSquare/>}</td>*/}
                                        <td className="text-center">
                                            <Link to={`/evento/${evento.GlobalID}`} title="Ver Detalle"><FaSearch/></Link>
                                        </td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </table>
                </div>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />
            </div>
        );
    }

    getEventoDescription(code) {
        const eventoTypes = this.state.eventoTypes;
        if(eventoTypes && eventoTypes.length > 0) {
            const match = eventoTypes.find((elem) => code === elem.code);
            return match? match.desc : code;
        }
        return code;
    }
}

export default withSession(withPermalink(EventoList));