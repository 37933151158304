//@flow

export function parseHashQueryString(urlHashStr: string): Object {
    if(!urlHashStr || urlHashStr.trim().length < 1) {
        return {};
    }

    const splittedHash = urlHashStr.split("?");
    if (splittedHash.length < 2) {
        return {};
    }

    const urlParams = new URLSearchParams(splittedHash[1]);
    const returnVal = {};
    for (let [key, val] of urlParams) {
        returnVal[key] = val
    }

    return returnVal
}

export function getLocationWithoutQueryString(location: Object): string {
    const splittedHash = location.hash.split("?");
    const hashWithoutQuery = splittedHash.length > 0 ? splittedHash[0] : '';
    return location.origin + location.pathname + hashWithoutQuery;
}

export function countDecimals(value) {
    if (Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;
    return 0;
}

export function convertStatsToCSV(statsTable) {
    if(!statsTable || statsTable.length === 0) {
        return '';
    }

    const headers = Object.keys(statsTable[0]);
    let csvStr = headers.join(',') + '\r\n';
    for (const record of statsTable) {
        const values = [];
        for (const header of headers) {
            let val: *;
            if (record[header] === null) {
                val = '';
            } else {
                val = String(record[header]);
                if(val.indexOf('"') !== -1) {
                    val = String(record[header]).replace('"', '""');
                    val = '"' + val + '"';
                } else if(val.indexOf(',') !== -1) {
                    val = '"' + val + '"';
                }
            }
            values.push(val);
        }
        csvStr += values.join(',') + '\r\n';
    }
    return csvStr;
}