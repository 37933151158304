// @flow
import React, {PureComponent} from 'react';

type EspecieShape = {
    OBJECTID: number,
    genus_species: string,
    common_name: string,
}

type Props = {
    options: EspecieShape[],
    value: string,
    onChange: () => void,
    autoFocus?: boolean,
    name: string,
    col: number,
    required?: boolean,
    hideNoAparece?: boolean,
    disabled?: boolean,
    labelText?: string,
}

export default class EspecieSelect extends PureComponent<Props> {

    static defaultProps = {
        col: 6,
        required: false,
        hideNoAparece: false,
        disabled: false,
        labelText: 'Especie'
    };

    render() {

        return (
            <div className={`col-${this.props.col} form-group`}>
                <label>{this.props.labelText}{this.props.required ? <i className="text-danger">*</i>: null}</label>
                <select className="form-select form-control"
                        name={this.props.name}
                        value={this.props.value}
                        required={this.props.required}
                        autoFocus={this.props.autoFocus}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                >
                    <option>- Escoger -</option>
                    {this.props.hideNoAparece ? null : <option value={-1}>- No aparece -</option>}
                    {this.props.options.map(especie => {
                        let optLabel = especie.common_name;
                        if (especie.common_name !== especie.genus_species) {
                            optLabel += ` (${especie.genus_species})`
                        }
                        return (
                            <option key={especie.species_id}
                                    value={especie.species_id}>{optLabel}</option>
                        );
                    })}
                </select>
            </div>
        )
    }
}