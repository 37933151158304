import React, {Component} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../TableContainer";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";

class AvesList extends Component<SessionProps> {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            records: [],
            especies: [],
        };
    }

    componentDidMount(): void {
        this.fetchEspecies();
    }

    fetchRecords = async (limit, pageNumber) => {

        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            entity: 'aves',
            resultRecordCount: limit,
            where: `evento_GlobalID='${this.state.eventoId}'`,
            outFields: ['OBJECTID', 'especie_id', 'especie_txt', 'tiempo_menor07', 'tiempo_mayor07', 'tiempo_menor8_10', 'tiempo_mayor8_10',
                'tiempo_menor5', 'tiempo_mayor5', 'ocurrencia_escuchada', 'ocurrencia_observada', 'punto_conteo_txt', 'comportamiento' ],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: limit,
            pageNumber: pageNumber,
            totalRecords: data.totalRecords,
        }
    };

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('aves').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    render() {
        return (
            <TableContainer title="Datos compilados de aves"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.state.eventoId}/aves/new`}>
                <table className="table table-hover table-bordered table-sm table-striped">
                    <thead className="thead-light">
                    <tr>
                        <th>Especie</th>
                        <th>0-7<br/>(≤25)</th>
                        <th>0-7<br/>(≥25)</th>
                        <th>8-10<br/>(≤25)</th>
                        <th>8-10<br/>(≥25)</th>
                        <th>5<br/>(≤25)</th>
                        <th>5<br/>(≥25)</th>
                        <th>Escuchada</th>
                        <th>Observada</th>
                        <th>Punto de conteo</th>
                        <th>Comportamiento</th>
                        <th className="align-top text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{this.props.especieService.getSelectedSpecieName(this.state.especies, record.especie_id) || record.especie_txt}</td>
                                <td>{record.tiempo_menor07}</td>
                                <td>{record.tiempo_mayor07}</td>
                                <td>{record.tiempo_menor8_10}</td>
                                <td>{record.tiempo_mayor8_10}</td>
                                <td>{record.tiempo_menor5}</td>
                                <td>{record.tiempo_mayor5}</td>
                                <td>{record.ocurrencia_escuchada}</td>
                                <td>{record.ocurrencia_observada}</td>
                                <td>{record.punto_conteo_txt}</td>
                                <td>{record.comportamiento}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.state.eventoId}/aves/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(AvesList);