import React, {Component} from "react";

class ErrorToast extends Component {

    render() {

        return (
            <div>
                <div className="font-weight-bold">Error</div>
                <div className="row">
                    <div className="col-md" style={{fontSize: 0.8 + 'em'}}>
                        {this.props.errorDetail.description}
                    </div>
                </div>
            </div>
        )
    }
}


export default ErrorToast;