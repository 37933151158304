//@flow
import React, {Component} from 'react';

type Props = {
    className?: String,

    //Function must return promise
    submit: (event: any) => Promise,
};

class Form extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            isValidated: false
        };
        this.formEl = React.createRef();
        this.validate = this.validate.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
    }


    validate() {
        const formLength = this.formEl.current.length;

        if (this.formEl.current.checkValidity() === false) {
            for (let i = 0; i < formLength; i++) {
                const elem = this.formEl.current[i];
                if ('button' === elem.nodeName.toLowerCase()) {
                    continue;
                }

                const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
                if(!errorLabel) {
                    continue;
                }

                if (!elem.validity.valid) {
                    errorLabel.textContent = elem.validationMessage;
                } else {
                    errorLabel.textContent = '';
                }

            }

            return false;
        } else {
            for (let i = 0; i < formLength; i++) {
                const elem = this.formEl.current[i];
                if ('button' === elem.nodeName.toLowerCase()) {
                    continue;
                }

                const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
                if(!errorLabel) {
                    continue;
                }

                errorLabel.textContent = '';
            }

            return true;
        }
    }

    submitHandler(event) {

        event.preventDefault();

        if(this.validate()) {
            const submitButtons = this.formEl.current.querySelectorAll("[type='submit']");
            submitButtons.forEach(elem => elem.disabled = true);

            const submitPromise = this.props.submit(event);
            if(submitPromise) {
                submitPromise.then(() => {
                    submitButtons.forEach(elem => elem.disabled = false);
                }, () => {
                    submitButtons.forEach(elem => elem.disabled = false);
                })
            } else {
                submitButtons.forEach(elem => elem.disabled = false);
            }
        }

        this.setState({isValidated: true});
    }

    render() {
        const props = Object.assign({}, this.props);
        delete props.submit;

        let classNames = [];
        if (props.className) {
            classNames = [...props.className];
            delete props.className;
        }

        if (this.state.isValidated) {
            classNames.push('was-validated');
        }

        return (
            <form ref={this.formEl} onSubmit={this.submitHandler} {...props} className={classNames} noValidate={true}>
                {this.props.children}
            </form>
        );
    }
}

export default Form;