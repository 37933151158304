import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class RioForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sustrato: ['Arcilla', 'Peñasco', 'Pedrusco', 'Grava', 'Limo', 'Lecho rosco', 'Arena', 'Artificial'],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Datos de Río</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Nombre del río"
                            type="text"
                            name="nombre_rio"
                            col={6}
                            autoFocus={true}
                            required={true}
                            value={this.state.record.nombre_rio}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Ancho del canal activo (m)"
                            type="number"
                            step="any"
                            name="ancho_canal"
                            col={6}
                            value={this.state.record.ancho_canal}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Ancho de Base (m)"
                            type="number"
                            step="any"
                            name="ancho_base"
                            col={6}
                            value={this.state.record.ancho_base}
                            onChange={this.handleInputChange}/>

                        <div className="col-6 form-group">
                            <label>Sustrato dominante</label>
                            <select className="form-select form-control"
                                    name="substrato_dominante"
                                    value={this.state.record.substrato_dominante}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.sustrato.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Observaciones"
                            type="text"
                            name="comentario_rio"
                            col={12}
                            value={this.state.record.comentario_rio}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Condición del canal"
                            type="text"
                            name="condicion_canal"
                            col={6}
                            value={this.state.record.condicion_canal}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Alteración Hydrógica"
                            type="text"
                            name="alteracion_hydro"
                            col={6}
                            value={this.state.record.alteracion_hydro}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Zona Ribereña"
                            type="text"
                            name="zona_riberena"
                            col={6}
                            value={this.state.record.zona_riberena}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Estabilidad del Banco"
                            type="text"
                            name="estabilidad_banco"
                            col={6}
                            value={this.state.record.estabilidad_banco}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Apariencia del Agua"
                            type="text"
                            name="apariencia_agua"
                            col={6}
                            value={this.state.record.apariencia_agua}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Abundancia de Nutrientes"
                            type="text"
                            name="abundancia_nutrientes"
                            col={6}
                            value={this.state.record.abundancia_nutrientes}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Apariencia del Agua"
                            type="text"
                            name="apariencia_agua"
                            col={6}
                            value={this.state.record.apariencia_agua}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Barreras"
                            type="text"
                            name="barreras"
                            col={6}
                            value={this.state.record.barreras}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Cantidad de peces"
                            type="number"
                            name="cant_peces"
                            col={6}
                            value={this.state.record.cant_peces}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Pozas"
                            type="number"
                            name="pozas"
                            col={6}
                            value={this.state.record.pozas}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Hábitat de invertebrados"
                            type="text"
                            name="habitat_invertebrados"
                            col={6}
                            value={this.state.record.habitat_invertebrados}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Elevación río arriba"
                            type="number"
                            step="any"
                            name="elevacion_rio_arriba"
                            col={6}
                            value={this.state.record.elevacion_rio_arriba}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Elevación río abajo"
                            type="number"
                            step="any"
                            name="elevacion_rio_abajo"
                            col={6}
                            value={this.state.record.elevacion_rio_abajo}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Elevación sitio X"
                            type="number"
                            step="any"
                            name="elevacion_sitio_x"
                            col={6}
                            value={this.state.record.elevacion_sitio_x}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default RioForm;