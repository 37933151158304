//@flow
import React, {Component} from 'react';
import {Map as ReactMapboxGL, ScaleControl, ZoomControl, GeoJSONLayer} from 'react-mapbox-gl';
import './MdvMapa.css'
import {MapUtils} from "../../../util/map";
import HexStatsLayer from "./HexStatsLayer";
import {withSession} from "../../../context/SessionContext";
import type {SessionProps} from "../../../context/SessionContext";
import HexBoundariesLayer from "./HexBoundariesLayer";
import HexLabelLayer from "./HexLabelLayer";

const ReactMap = ReactMapboxGL({
    accessToken: process.env.REACT_APP_MAPBOX_API_KEY
});

type Props = {
    ...SessionProps,
    height?: number,
}

class MdvMapa extends Component<Props> {

    constructor(props) {
        super(props);

        this.state = {
            viewport: MapUtils.hashStringToViewport(window.location.hash),
            shouldUpdatePermalink: true,
            map: null,
            mapContainerHeight: this.props.height || window.innerHeight,
            boundaries: null,
            centroids: null,
        };
    }

    async componentDidMount() {
        window.addEventListener('popstate', this.onWindowPopState);

        const boundaries = await this.props.refDataRest.queryGeoJSON({entity: 'hexagonos_boundaries'});
        this.setState({
            boundaries: boundaries,
        });
        const centroids = await this.props.refDataRest.queryGeoJSON({entity: 'hexagonos_centroids_v'});
        this.setState({
            centroids: centroids,
        });
    }

    render() {

        const vport = this.state.viewport;

        const mapStyle = {
            width: '100%',
            height: this.state.mapContainerHeight
        };

        return (
            <ReactMap
                style={"mapbox://styles/mapbox/streets-v9"}
                center={[vport.longitude, vport.latitude]}
                zoom={[vport.zoom]}
                bearing={vport.bearing}
                containerStyle={mapStyle}
                onStyleLoad={this.initMap}
                onZoomEnd={this.onMapMove}
                onDragEnd={this.onMapMove}
                onRotateEnd={this.onMapMove}>

                <ZoomControl position="topLeft" style={{left: '10px', top: '10px'}} />
                <ScaleControl position="bottomRight" style={{bottom: '30px', right: '10px'}}/>

                <HexBoundariesLayer data={this.state.boundaries}/>
                <HexLabelLayer data={this.state.centroids}/>

                {this.state.map &&
                <HexStatsLayer map={this.state.map}
                    before="poi-scalerank4-l15"
                />}

            </ReactMap>
        );
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onWindowPopState);
    }

    initMap = (map, evt) => {
        this.setState({
            map: map,
        });
    };

    onMapMove = (evt) => {
        this.updatePermalink();
        this.setState(prevState => ({
            viewport: {
                zoom: prevState.map.getZoom(),
                longitude: prevState.map.getCenter().lng,
                latitude: prevState.map.getCenter().lat,
                bearing: prevState.map.getBearing(),
            }
        }));
    };

    updatePermalink() {
        if (!this.state.map) {
            return;
        }

        if (!this.state.shouldUpdatePermalink) {
            this.setState({
                shouldUpdatePermalink: true
            });
            return;
        }

        const viewport = MapUtils.getMapViewport(this.state.map);
        const hashStr = MapUtils.viewportToHashString(viewport, window.location.hash);
        window.history.pushState({ viewport: viewport }, 'Mapa de Vida', hashStr);
    }

    onWindowPopState(event) {
        if (event.state === null) {
            return;
        }

        this.setState({
            viewport: event.state.viewport
        });
    }

}


export default withSession(MdvMapa);
