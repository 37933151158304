import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import Datos from "./Datos";
import DatosFuera from "./DatosFuera";
import Remocion from "./Remocion";
import {withSession} from "../../context/SessionContext";
import {formatDate} from "../../util/format";
import BackButton from "../BackButton";
import {AuditData} from "../AuditData";


class SapoView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            evento: {}
        };
    }

    async componentDidMount() {
        const data = await this.props.rest.getByGlobalID({
            entity: 'evento',
            globalId: this.state.eventoId,
        });
        this.setState({
            evento: data,
        });
    }

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-6">
                        <h3>Evento de sapo concho</h3>
                    </div>
                    <div className="col-6 text-right">
                        <Link className="btn btn-success" to={`/sapo-concho/${this.state.eventoId}/edit`} role="button">
                            <FaPencilAlt/> Editar
                        </Link>
                        &nbsp;<BackButton btnClass="success"/>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-3 font-weight-bold">Nombre del evento:</div>
                    <div className="col-3">{this.state.evento.nombre}</div>
                    <div className="col-3 font-weight-bold">Fecha:</div>
                    <div className="col-3">{formatDate(this.state.evento.fecha)}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">ID Hexágono:</div>
                    <div className="col-3">{this.state.evento.hex_id}</div>
                    <div className="col-3 font-weight-bold">Longitud:</div>
                    <div className="col-3">{this.state.evento.longitud}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Estatus:</div>
                    <div className="col-3">{this.state.evento.estatus}</div>
                    <div className="col-3 font-weight-bold">Latitud:</div>
                    <div className="col-3">{this.state.evento.latitud}</div>
                </div>

                <br/>
                <h3>Clima</h3>
                <hr/>

                <div className="row">
                    <div className="col-3 font-weight-bold">Temperatura:</div>
                    <div className="col-3">{this.state.evento.temperatura}</div>
                    <div className="col-3 font-weight-bold">Velocidad del viento:</div>
                    <div className="col-3">{this.state.evento.velocidad_viento}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Humedad relativa:</div>
                    <div className="col-3">{this.state.evento.humedad}</div>
                    <div className="col-3 font-weight-bold">Dirección del viento:</div>
                    <div className="col-3">{this.state.evento.dir_viento}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Precipitación:</div>
                    <div className="col-3">{this.state.evento.precipitacion}</div>
                    <div className="col-3 font-weight-bold">Cobertura de nubes:</div>
                    <div className="col-3">{this.state.evento.cob_nubes}</div>
                </div>

                <div className="row">
                    <div className="col">
                        <Remocion eventoId={this.state.eventoId}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Datos eventoId={this.state.eventoId}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <DatosFuera eventoId={this.state.eventoId}/>
                    </div>
                </div>
                <AuditData entity={this.state.evento}/>
            </div>
        );
    }
}

export default withSession(SapoView);





