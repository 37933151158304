import React, {Component} from "react";
import CaracteristicasQuimicasForm from "./CaracteristicasQuimicasForm";
import CaracteristicasFisicasForm from "./CaracteristicasFisicasForm";
import MacroinvertebradosForm from "./MacroinvertebradosForm";
import RioForm from "./RioForm";
import {withSession} from "../../context/SessionContext";

class CuencasEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            recordType: props.match.params.recordType,
            record: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fetchRecord = this.fetchRecord.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId, this.getRecordType());
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }));
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    }

    async fetchRecord(id, type) {
        const data = await this.props.rest.get({
            entity: type,
            id: id,
        });
        this.setState({
            record: data
        })
    }

    getRecordType() {
        if (this.state.recordType === 'quimicas') return "cuenca_caracteristicas_quimicas";
        if (this.state.recordType === 'fisicas') return "cuenca_caracteristicas_fisicas";
        if (this.state.recordType === 'macroinverte') return "cuenca_microvertebrados";
        if (this.state.recordType === 'rio') return "cuenca";
    }

    handleSubmit(event) {
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: this.getRecordType(),
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if(data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });
        event.preventDefault();
    }

    render() {
        let form = null;
        if (this.state.recordType === 'quimicas')
            form = <CaracteristicasQuimicasForm record={this.state.record}
                                                handleSubmit={this.handleSubmit}
                                                handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'fisicas')
            form = <CaracteristicasFisicasForm record={this.state.record}
                                               handleSubmit={this.handleSubmit}
                                               handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'macroinverte')
            form = <MacroinvertebradosForm record={this.state.record}
                                           handleSubmit={this.handleSubmit}
                                           handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'rio')
            form = <RioForm record={this.state.record}
                            handleSubmit={this.handleSubmit}
                            handleInputChange={this.handleInputChange}/>;
        return (
            <div>{form}</div>
        );
    }
}

export default withSession(CuencasEdit);