import React, {Component} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../TableContainer";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class SedimentoPlaya extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            entity: 'playa_sedimento',
            where: `evento_GlobalID='${this.state.eventoId}'`,
            outFields: ['OBJECTID', 'transecto', 'zona', 'masa', 'sedimento', 'concentracion'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        }
    }

    render() {
        return (
            <TableContainer title="Composición del sedimento"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.state.eventoId}/playa/sedimento/new`}>
                <table className="table table-hover table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>Transecto</th>
                        <th>Zona</th>
                        <th>Masa</th>
                        <th>Sedimento</th>
                        <th>Concentración</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.transecto}</td>
                                <td>{record.zona}</td>
                                <td>{record.masa}</td>
                                <td>{record.sedimento}</td>
                                <td>{record.concentracion}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.state.eventoId}/playa/sedimento/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(SedimentoPlaya);