//@flow
import React, {Fragment} from 'react';

import FormControl from "../FormControl";

type Props = {
    evento: Object,
    onInputChange: (Object) => void,
}

const DIRECCION_VIENTO_OPTS = [
    "Este",
    "Este Nordeste",
    "Este Sudeste",
    "Noreste",
    "Noroeste",
    "Norte",
    "Norte Nordeste",
    "Oeste",
    "Oeste Noroeste",
    "Oeste Sudeste",
    "Sudeste",
    "Sudoeste",
    "Sur",
    "Sur Sudoeste"
];

const COBERTURA_NUBES_OPTS = [
    "Despejado",
    "Nublado",
    "Parcial"
];
const PRECIPITACION_OPTS = [
    "Fuerte",
    "Leve",
    "No"
];

export function ClimaEdit(props: Props) {
    const evento = props.evento;
    if (evento && evento.tipo_evento !== 'MDV') {
        return <Fragment/>;
    }

    return (
        <Fragment>
            <h3>Clima</h3>
            <div className="row">
                <FormControl
                    type="number"
                    label="Temperatura (°C)"
                    col={4}
                    name="temperatura"
                    value={evento.temperatura || ''}
                    onChange={props.onInputChange}
                    required={false}/>
                <FormControl
                    type="number"
                    label="Velocidad del viento (m/s)"
                    col={4}
                    step={0.1}
                    name="velocidad_viento"
                    value={evento.velocidad_viento}
                    onChange={props.onInputChange}/>
                <div className="col-4">
                    <div className="form-group">
                        <label>Dirección del viento</label>
                        <select className="form-select form-control"
                                name="dir_viento"
                                value={evento.dir_viento}
                                onChange={props.onInputChange}
                        >
                            <option value=""/>
                            {DIRECCION_VIENTO_OPTS.map(p => (
                                <option key={p} value={p}>{p}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row">
                <FormControl
                    label="Humedad relativa (%)"
                    type="number"
                    min={0} max={100}
                    col={4}
                    name="humedad"
                    value={evento.humedad}
                    onChange={props.onInputChange}
                />
                <div className="col-4">
                    <div>
                        <label>Cobertura de nubes</label>
                        <div className="form-group">
                            <select className="form-select form-control"
                                    data-wpt-type="select"
                                    name="cob_nubes"
                                    value={evento.cob_nubes}
                                    onChange={props.onInputChange}
                            >
                                <option value=""/>
                                {COBERTURA_NUBES_OPTS.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div>
                        <label>Precipitación</label>
                        <div className="form-group">
                            <select className="form-select form-control"
                                    name="precipitacion"
                                    value={evento.precipitacion}
                                    onChange={props.onInputChange}
                            >
                                <option value=""/>
                                {PRECIPITACION_OPTS.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <FormControl
                    label="Presión barométrica"
                    col={4}
                    type="number"
                    step="any"
                    name="presion_barometrica"
                    value={evento.presion_barometrica || ''}
                    onChange={props.onInputChange}/>
                <FormControl
                    label="Condición del hábitat"
                    name="condicion_habitat"
                    value={evento.condicion_habitat || ''}
                    onChange={props.onInputChange}
                    className="form-control"/>
            </div>
        </Fragment>
    )
}