import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class TransectosPlayaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Perfil de playa</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Número de transecto playa"
                            type="number"
                            col={6}
                            name="transecto"
                            autoFocus={true}
                            value={this.state.record.transecto}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Segmento del transecto playa"
                            type="number"
                            name="segmento"
                            col={6}
                            value={this.state.record.segmento}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Largo del segmento (p)"
                            type="number"
                            step="any"
                            name="largo_segmento"
                            col={6}
                            value={this.state.record.largo_segmento}
                            onChange={this.handleInputChange}/>

                        <FormControl
                            label="Altura del segmento (p)"
                            type="number"
                            step="any"
                            col={6}
                            name="altura_segmento"
                            value={this.state.record.altura_segmento}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Marea</label>
                            <select className="form-select form-control"
                                    name="marea"
                                    value={this.state.record.marea}
                                    onChange={this.handleInputChange}>
                                <option>Alta</option>
                                <option>Baja</option>
                            </select>
                        </div>

                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default TransectosPlayaForm;