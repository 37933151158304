//@flow
import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "./MdvApp.css";
import MdvMapa from "./components/mdv/mapa/MdvMapa";
import ArbolesMapa from "./components/arboles/mapa/ArbolesMapa";
import Menu from "./components/menu/Menu.js";
import Inicio from "./components/inicio/Inicio";
import MdvInicio from "./components/mdv/inicio/MdvInicio";
import {HashRouter, Route, Switch} from "react-router-dom";
import EventoList from "./components/evento/EventoList";
import EventoEdit from "./components/evento/EventoEdit";
import EventoView from "./components/evento/EventoView";
import AvesEdit from "./components/aves/AvesEdit";
import VegetacionEdit from "./components/vegetacion/VegetacionEdit";
import ReptilesEdit from "./components/reptiles/ReptilesEdit";
import PlayaEdit from "./components/playa/PlayaEdit";
import HumedalesEdit from "./components/humedal/HumedalesEdit";
import CuencasEdit from "./components/cuenca/CuencasEdit";
import ArcGISRest from './util/rest-arcgis';
import ArbolesInicio from "./components/arboles/inicio/ArbolesInicio";
import SapoInicio from "./components/sapo/inicio/SapoInicio";
import Perfil from "./components/perfil/Perfil";
import ArbolesList from "./components/arboles/forma/ArbolesList";
import ArbolesEdit from "./components/arboles/forma/ArbolesEdit";
import ArbolesView from "./components/arboles/forma/ArbolesView";
import ArbolesMedicionEdit from "./components/arboles/forma/ArbolesMedicionEdit";
import SapoList from "./components/sapo/SapoList";
import SapoView from "./components/sapo/SapoView";
import SapoConchoMapa from "./components/sapo/mapa/SapoConchoMapa";
import SapoEdit from "./components/sapo/SapoEdit";
import HexPerfil from "./components/mdv/hex/HexPerfil";
import Estadisticas from "./components/mdv/stats/Estadisticas";
import EspeciesList from "./components/admin/EspeciesList";
import EspeciesEdit from "./components/admin/EspeciesEdit";
import {UserSession} from "@esri/arcgis-rest-auth";
import {parseHashQueryString, getLocationWithoutQueryString} from './util/util';
import {SessionContext} from './context/SessionContext';
import {getUser} from '@esri/arcgis-rest-portal'
import {toast as unwrappedToaster} from 'react-toastify';
import ErrorToast from "./components/ErrorToast";
import EspecieService from "./util/service";

type RemoteSessionProps = {
    token: String,
    expires: number,
    username: String,
}

class MdvApp extends Component {

    constructor(props) {
        super(props);
        const nowTime = new Date().getTime();
        let sessionProps : RemoteSessionProps = null;

        //attempt to read session from url
        const queryStr = parseHashQueryString(window.location.hash);
        const SESSION_KEY = 'sessionData';
        if (queryStr.access_token && queryStr.expires_in && queryStr.username) {
            sessionProps = {
                token: queryStr.access_token,
                expires: nowTime + Number.parseInt(queryStr.expires_in),
                username: queryStr.username,
                ssl: queryStr.ssl,
            };
            sessionStorage.setItem(SESSION_KEY, JSON.stringify(sessionProps));
        }

        //attempt to read session from local storage
        if(!sessionProps) {
            const sessionPropsStr = sessionStorage.getItem(SESSION_KEY);
            if(sessionPropsStr) {
                sessionProps = JSON.parse(sessionPropsStr);
            }
        }

        let userSession = null;
        if (sessionProps && sessionProps.expires > nowTime) {
            userSession = new UserSession({
                clientId: process.env.REACT_APP_CLIENT_ID,
                token: sessionProps.token,
                tokenExpires: new Date(sessionProps.expires),
                username: sessionProps.username,
                portal: process.env.REACT_APP_SERVER_URL,
                ssl: sessionProps.ssl
            });
        }

        const refDataRest = new ArcGISRest(userSession, process.env.REACT_APP_SERVER_ENDPOINT_REFDATA);
        this.state = {
            session: userSession,
            rest: new ArcGISRest(userSession, process.env.REACT_APP_SERVER_ENDPOINT),
            refDataRest: refDataRest,
            adminRest: new ArcGISRest(userSession, process.env.REACT_APP_SERVER_ENDPOINT_ADMIN),
            especieService: new EspecieService(refDataRest),
            user: null,
            logout: () => {
                sessionStorage.removeItem(SESSION_KEY);
                window.history.replaceState({}, document.title, getLocationWithoutQueryString(window.location));
                const nullSessionRefDataRest = new ArcGISRest(null, process.env.REACT_APP_SERVER_ENDPOINT_REFDATA);
                this.setState({
                    session: null,
                    rest: new ArcGISRest(null, process.env.REACT_APP_SERVER_ENDPOINT),
                    refDataRest: nullSessionRefDataRest,
                    especieService: new EspecieService(nullSessionRefDataRest),
                    adminRest: new ArcGISRest(null, process.env.REACT_APP_SERVER_ENDPOINT_ADMIN),
                    user: null,
                })
            }
        };
    }

    async componentDidMount() {
        window.onunhandledrejection = (err) => {
            if(err.reason.name === 'ArcGISAuthError') {
                UserSession.beginOAuth2({
                    clientId: process.env.REACT_APP_CLIENT_ID,
                    portal: process.env.REACT_APP_SERVER_URL,
                    redirectUri: process.env.REACT_APP_REDIRECT_URL,
                    popup: false,
                });
                return;
            }
            console.error(err.reason);
            let errMsg = "";
            if(err.reason.name) {
                errMsg += err.reason.name;
            }
            if(err.reason.message) {
                errMsg += ' ' + err.reason.message;
            }

            if(errMsg.length === 0) {
                errMsg = JSON.stringify(err.reason);
            }
            unwrappedToaster(<ErrorToast errorDetail={{description: errMsg}}/>, {
                type: unwrappedToaster.TYPE.ERROR
            });
        };

        if(this.state.session) {
            const user = await getUser({ authentication: this.state.session });
            this.setState({
                user: user,
            })
        }

        window.setInterval(() => {
            this.state.refDataRest.pingServer();
        }, 60000);
    }

    render() {
        return (
            <SessionContext.Provider value={this.state}>
            <HashRouter>
                <Switch>
                    <Menu user={this.state.user} logout={this.state.logout}>
                        <Switch>
                            <Route exact path='/evento' component={EventoList}/>
                            <Route exact path='/evento/new/:eventoType' component={EventoEdit}/>
                            <Route exact path='/evento/:eventoId/edit' component={EventoEdit}/>
                            <Route exact path='/evento/:eventoId' component={EventoView}/>

                            <Route exact path='/evento/:eventoId/aves/new' component={AvesEdit}/>
                            <Route exact path='/evento/:eventoId/aves/:recordId' component={AvesEdit}/>

                            <Route exact path='/evento/:eventoId/vegetacion/new' component={VegetacionEdit}/>
                            <Route exact path='/evento/:eventoId/vegetacion/:recordId' component={VegetacionEdit}/>

                            <Route exact path='/evento/:eventoId/reptiles/:recordType/new' component={ReptilesEdit}/>
                            <Route exact path='/evento/:eventoId/reptiles/:recordType/:recordId' component={ReptilesEdit}/>

                            <Route exact path='/evento/:eventoId/playa/:recordType/new' component={PlayaEdit}/>
                            <Route exact path='/evento/:eventoId/playa/:recordType/:recordId' component={PlayaEdit}/>

                            <Route exact path='/evento/:eventoId/humedales/:recordType/new' component={HumedalesEdit}/>
                            <Route exact path='/evento/:eventoId/humedales/:recordType/:recordId' component={HumedalesEdit}/>

                            <Route exact path='/evento/:eventoId/cuencas/:recordType/new' component={CuencasEdit}/>
                            <Route exact path='/evento/:eventoId/cuencas/:recordType/:recordId' component={CuencasEdit}/>
                            <Route exact path='/hex/:hexId' component={HexPerfil}/>

                            <Route exact path="/mdv/mapa" component={MdvMapa}/>
                            <Route exact path="/mdv/inicio" component={MdvInicio}/>
                            <Route exact path="/mdv/estadisticas" component={Estadisticas}/>


                            <Route exact path="/arboles/mapa" component={ArbolesMapa}/>
                            <Route exact path="/arboles/inicio" component={ArbolesInicio}/>
                            <Route exact path="/arboles/list" component={ArbolesList}/>
                            <Route exact path="/arboles/new" component={ArbolesEdit}/>
                            <Route exact path="/arboles/:recordId/edit/" component={ArbolesEdit}/>
                            <Route exact path="/arboles/:recordId" component={ArbolesView}/>
                            <Route exact path="/arboles/:arbolId/medicion/new" component={ArbolesMedicionEdit}/>
                            <Route exact path="/arboles/:arbolId/medicion/:recordId" component={ArbolesMedicionEdit}/>

                            <Route exact path="/sapo-concho/mapa" component={SapoConchoMapa}/>
                            <Route exact path="/sapo-concho/inicio" component={SapoInicio}/>
                            <Route exact path="/sapo-concho/list" component={SapoList}/>
                            <Route exact path="/sapo-concho/:eventoId/:recordType/new" component={SapoEdit}/>
                            <Route exact path="/sapo-concho/:eventoId/:recordType/:recordId" component={SapoEdit}/>
                            <Route exact path="/sapo-concho/:eventoId/edit" component={EventoEdit}/>
                            <Route exact path="/sapo-concho/:eventoId" component={SapoView}/>

                            <Route exact path="/admin/especies" component={EspeciesList}/>
                            <Route exact path="/admin/especies/new" component={EspeciesEdit}/>
                            <Route exact path="/admin/especies/:recordId" component={EspeciesEdit}/>

                            <Route exact path="/perfil" component={Perfil}/>
                            <Route exact path="/inicio" component={Inicio}/>

                            <Route exact path="/" component={Inicio}/>
                        </Switch>
                    </Menu>
                </Switch>

            </HashRouter>
            </SessionContext.Provider>
        )
    }

}

export default MdvApp;
