//@flow
import React, {PureComponent, Fragment} from 'react';

const PROTOCOLS = new Map([
    ['total', {title: 'Total', hasEspecie: true}],
    ['aves', {title: 'Aves', hasEspecie: true}],
    ['vegetacion', {title: 'Vegetación', hasEspecie: true}],
    ['reptil', {title: 'Reptiles', hasEspecie: true}],
    ['humedal', {title: 'Humedales', hasEspecie: true}],
    ['playa', {title: 'Playas', hasEspecie: false}],
    ['cuenca', {title: 'Cuencas', hasEspecie: false}],
]);

type Props = {
    hexStats: Object,
    hexId?: number | string,
}

export default class HexStatsTable extends PureComponent<Props> {
    render() {
        const fProps = this.props.hexStats;
        const protocolKeys = Array.from(PROTOCOLS.keys());
        return (

            <div className="table-responsive mb-3">
                <table className="table table-hover table-sm table-striped">
                    <thead className="thead-light">
                    <tr>
                        <th>Protocolo</th>
                        <th style={{textAlign: 'right'}}>Eventos</th>
                        <th style={{textAlign: 'right'}}>Abundancia</th>
                        <th style={{textAlign: 'right'}}>Diversidad</th>
                    </tr>
                    </thead>
                    <tbody style={{fontWeight: 'bold'}}>{
                        protocolKeys.map((protocolKey, idx) => {

                            if (fProps['evt_' + protocolKey] <= 0) {
                                return null;
                            }

                            let columnPrefixes = ['evt_'];
                            if (PROTOCOLS.get(protocolKey).hasEspecie) {
                                columnPrefixes.push('esp_', 'div_');
                            }

                            return (
                                <tr key={'popup.tr.' + idx}>
                                    <td style={{fontWeight: 'bold'}}>{PROTOCOLS.get(protocolKey).title}</td>
                                    {columnPrefixes.map((colPrefix, idx) => {
                                        return (
                                            <td key={'popup.td.' + idx} style={{textAlign: 'right',}}>
                                                {fProps[colPrefix + protocolKey]}
                                            </td>
                                        )
                                    })}
                                    {columnPrefixes.length === 1 &&
                                    <Fragment>
                                        <td/>
                                        <td/>
                                    </Fragment>
                                    }
                                </tr>
                            );

                        })
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}
