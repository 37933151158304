import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class CaracteristicasQuimicasForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xpoint: ['Izquierda', 'Centro', 'Derecha', 'Media'],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Características químicas</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>X-Site point</label>
                            <select className="form-select form-control"
                                    name="sitio_xquimica"
                                    autoFocus={true}
                                    value={this.state.record.sitio_xquimica}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.xpoint.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Temperatura (°C)"
                            type="number"
                            step="any"
                            name="temperatura"
                            col={6}
                            value={this.state.record.temperatura}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="DO (mg/l)"
                            type="number"
                            step="any"
                            name="dissolved_oxygen"
                            col={6}
                            value={this.state.record.dissolved_oxygen}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="pH"
                            type="number"
                            step="any"
                            name="ph"
                            col={6}
                            value={this.state.record.ph}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="TDS"
                            type="number"
                            step="any"
                            name="tds"
                            col={6}
                            value={this.state.record.tds}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="S (ppm)"
                            type="number"
                            step="any"
                            name="salinity"
                            col={6}
                            value={this.state.record.salinity}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="N"
                            type="number"
                            step="any"
                            name="n"
                            col={6}
                            value={this.state.record.n}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="P"
                            type="number"
                            step="any"
                            name="p"
                            value={this.state.record.p}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Comentarios"
                            step="any"
                            name="comm_quimica"
                            col={12}
                            value={this.state.record.comm_quimica}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default CaracteristicasQuimicasForm;