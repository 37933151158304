import React, {Component} from "react";
import PuntosCircularesForm from "./PuntosCircularesForm";
import TransectosLinealesForm from "./TransectosLinealesForm";
import ObservacionesCasualesForm from "./ObservacionesCasualesForm";
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";

const COMPORTAMIENTOS = [
    "Alimentación",
    "Aseo",
    "Búsqueda de alimento",
    "Canto",
    "Cortejo",
    "Cuidado de las crías",
    "Peleando",
    "Vuelo",
    "N/A"
];

class ReptilesEdit extends Component<SessionProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            recordType: props.match.params.recordType,
            especies: [],
            record: {}
        };

    }

    componentDidMount() {
        this.fetchEspecies();
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId, this.getRecordType());
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }));
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    };

    fetchRecord = async (id, type) => {
        const data = await this.props.rest.get({
            entity: type,
            id: id,
        });
        this.setState({
            record: data,
        });
    };

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('reptiles').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    getRecordType() {
        if (this.state.recordType === 'puntos') return "reptil_muestreo_cuadrante";
        if (this.state.recordType === 'observaciones') return "reptil_observaciones";
        if (this.state.recordType === 'transectos') return "reptil_muestreo_transecto";
    }

    handleSubmit = (event) => {
        let especieTxt = this.state.record.especie_txt;
        if (this.state.record.especie_id && this.state.record.especie_id > 0) {
            especieTxt = this.props.especieService.getSelectedSpecieName(this.state.especies, this.state.record.especie_id)
        }

        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId,
                especie_txt: especieTxt,
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: this.getRecordType(),
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if (data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });

        event.preventDefault();
    };

    render() {
        let form = null;
        if (this.state.recordType === 'puntos')
            form = (
                <PuntosCircularesForm
                    record={this.state.record}
                    especies={this.state.especies}
                    comportamiento={COMPORTAMIENTOS}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        if (this.state.recordType === 'transectos')
            form = (
                <TransectosLinealesForm
                    record={this.state.record}
                    especies={this.state.especies}
                    comportamiento={COMPORTAMIENTOS}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        if (this.state.recordType === 'observaciones')
            form = (
                <ObservacionesCasualesForm
                    record={this.state.record}
                    especies={this.state.especies}
                    comportamiento={COMPORTAMIENTOS}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        return (
            <div>{form}</div>
        );
    }
}

export default withSession(ReptilesEdit);