
export const INDICADORES_SUELO = {
    indicadores_suelo_redox: 'Características redox',
    indicadores_suelo_reduc_sulfato: 'Reducción de sulfato',
    indicadores_suelo_mat_organica: 'Acumulación de materia orgánica',
    indicadores_suelo_a1: 'A1 Histosoles',
    indicadores_suelo_a2: 'A2 Epipedón hístico',
    indicadores_suelo_a4: 'A4 Sulfuro de hidrógeno',
    indicadores_suelo_a11: 'A11 Emprobrecido bajo la superficie oscura',
    indicadores_suelo_s4: 'S4 Matriz arenosa gléyica (Gley)',
    indicadores_suelo_s5: 'S5 Reducción',
    indicadores_suelo_s7: 'S7 Superficie oscura',
    indicadores_suelo_f2: 'F2 Matriz lómica gleyca',
    indicadores_suelo_f3: 'F3 Matriz empobrecida',
};

export const INDICADORES_PRIM = {
    indicadores_prim_a1: 'A1 Agua superficial',
    indicadores_prim_a2: 'A2 Columna de agua',
    indicadores_prim_a3: 'A3 Saturación',
    indicadores_prim_b1: 'B1 Marcas de agua',
    indicadores_prim_b2: 'B2 Depósitos de sedimentos',
    indicadores_prim_b3: 'B3 Acumulación de depósitos',
    indicadores_prim_b4: 'B4 Corteza o manto de algas',
    indicadores_prim_b5: 'B5 Depósitos de hierro',
    indicadores_prim_b7: 'B7 Inundación visible en imágenes aéreas',
    indicadores_prim_b9: 'B9 Hojas con manchas de agua',
    indicadores_prim_b13: 'B13 Fauna acuática',
    indicadores_prim_c4: 'C4 Presencia de hierro reducido',
    indicadores_prim_c10: 'C10 Cuevas de cangrejo',
};

export const INDICADORES_SEG = {
    indicadores_secu_b6: 'B6 Grietas en la superficie del suelo',
    indicadores_secu_b10: 'B10 Patrones de drenaje',
    indicadores_secu_d5: 'D5 FAC- neutral',
};
