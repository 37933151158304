import {Component} from 'react';
import type {GeoJSONFeatureCollection, GeoJSONFeature} from '@mapbox/geojson-types';

const DEFAULT_VIEWPORT = {
    latitude: 18.229963,
    longitude: -66.242816,
    zoom: 8,
    bearing: 0
};

const EMPTY_FEATURE_COLLECTION: GeoJSONFeatureCollection = {
    type: 'FeatureCollection',
    features: [],
};

const EMPTY_FEATURE: GeoJSONFeature = {
    type: 'Feature',
};


class MapUtils {

    static hashStringToViewport(urlHash) {

        if (urlHash === '') {
            return DEFAULT_VIEWPORT;
        }

        // try to restore center, zoom-level and rotation from the URL
        const splittedHash = urlHash.split("?");
        if (splittedHash.length < 2) {
            return DEFAULT_VIEWPORT;
        }

        let partsStr = splittedHash[1].replace('map=', '');
        let parts = partsStr.split('/');
        if (parts.length !== 4) {
            return DEFAULT_VIEWPORT;
        }

        return {
            zoom: parseInt(parts[0], 10),
            latitude: parseFloat(parts[1]),
            longitude: parseFloat(parts[2]),
            bearing: parseFloat(parts[3]),
        };

    }

    static viewportToHashString(viewport, prevUrlHash) {
        const queryStr = `?map=${viewport.zoom.toFixed(2)}/${viewport.latitude.toFixed(2)}/${viewport.longitude.toFixed(2)}/${viewport.bearing.toFixed(2)}`;
        return (prevUrlHash ? prevUrlHash.split("?")[0] : '') + queryStr
    }

    static getMapViewport(map) {
        return {
            zoom: map.getZoom(),
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
            bearing: map.getBearing(),
        }
    }
}


class MapPermalink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shouldUpdatePermalink: true,
        };

        this.updatePermalink = this.updatePermalink.bind(this);
        this.onWindowPopState = this.onWindowPopState.bind(this);
    }

    render() {
        return null
    }

    componentDidMount() {
        window.addEventListener('popstate', this.onWindowPopState);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onWindowPopState);
    }

    componentDidUpdate() {
        console.debug(this.props);
    }

    updatePermalink() {
        if (!this.props.map) {
            return;
        }

        if (!this.state.shouldUpdatePermalink) {
            this.setState({
                shouldUpdatePermalink: true
            });
            return;
        }

        const viewport = MapUtils.getMapViewport(this.props.map);
        const hashStr = MapUtils.viewportToHashString(viewport, window.location.hash);
        window.history.pushState({ viewport: viewport }, this.props.title || 'MapPermalink', hashStr);
    }

    onWindowPopState(event) {
        if (event.state === null) {
            return;
        }

        this.setState({
            viewport: event.state.viewport
        });
    }

}

export {MapUtils, DEFAULT_VIEWPORT, EMPTY_FEATURE_COLLECTION};