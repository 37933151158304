//@flow
import React, {Component} from "react";
import Pagination from "react-js-pagination/dist/Pagination";
import './PageNav.css';
import {
    FaAngleLeft,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleRight
} from "react-icons/fa";

type Props = {
    onChange: () => void,
    totalItemsCount: number,
    itemsCountPerPage: number,
}

class PageNav extends Component<Props> {

    render() {
        if(this.props.totalItemsCount <= this.props.itemsCountPerPage) {
            return null;
        }

        return (
                <Pagination
                    activePage={this.props.activePage}
                    itemsCountPerPage={this.props.itemsCountPerPage}
                    totalItemsCount={this.props.totalItemsCount}
                    pageRangeDisplayed={this.props.pageRangeDisplayed || 5}
                    onChange={this.props.onChange}
                    firstPageText={<FaAngleDoubleLeft/>}
                    lastPageText={<FaAngleDoubleRight/>}
                    prevPageText={<FaAngleLeft/>}
                    nextPageText={<FaAngleRight/>}
                    innerClass="pagination"
                    itemClass="page-item"
                    linkClass="page-link"
                />

        )
    }
}

export default PageNav;