import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class CaracteristicasFisicasForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xpoint: ['Izquierda', 'Centro', 'Derecha', 'Media'],
            sustrato: ['Arcilla', 'Peñasco', 'Pedrusco', 'Grava', 'Limo', 'Lecho rosco', 'Arena', 'Artificial'],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Características físicas</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>X-Site point</label>
                            <select className="form-select form-control"
                                    name="sitio_xfisico"
                                    autoFocus={true}
                                    value={this.state.record.sitio_xfisico}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.xpoint.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Distacia desde el banco"
                            type="number"
                            step="any"
                            name="distancia_banco"
                            col={6}
                            value={this.state.record.distancia_banco}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Pofundidad (cm)"
                            type="number"
                            step="any"
                            name="profundidad"
                            col={6}
                            value={this.state.record.profundidad}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Velocidad (@.06D)"
                            type="number"
                            step="any"
                            name="velocidad"
                            col={6}
                            value={this.state.record.velocidad}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>TDS</label>
                            <select className="form-select form-control"
                                    name="sustrato"
                                    value={this.state.record.sustrato}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.sustrato.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Comentarios"
                            type="text"
                            name="comm_fisico"
                            col={6}
                            value={this.state.record.comm_fisico}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default CaracteristicasFisicasForm;