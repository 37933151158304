import React from 'react';
import loadingIcon from "../images/loading_icon.gif";

export default function LoadingIcon() {
    return (
        <div className="row">
            <div className="col text-center">
                <img src={loadingIcon} style={{height: 'auto'}}
                     className="img-fluid" alt="Imágen de perfil"/>
            </div>
        </div>
    );
}