import React, {Component} from "react";
import TransectosPlayaForm from "./TransectosPlayaForm";
import OlasForm from "./OlasForm";
import SedimentoPlayaForm from "./SedimentoPlayaForm";
import CalidadAguaForm from "./CalidadAguaForm";
import {withSession} from "../../context/SessionContext";

class PlayaEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            recordType: props.match.params.recordType,
            record: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fetchRecord = this.fetchRecord.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId, this.getRecordType());
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }));
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    }

    async fetchRecord(id, type) {
        const data = await this.props.rest.get({
            entity: type,
            id: id,
        });
        this.setState({
            record: data
        })
    }

    getRecordType() {
        if (this.state.recordType === 'agua') return "playa_agua";
        if (this.state.recordType === 'olas') return "playa_olas";
        if (this.state.recordType === 'sedimento') return "playa_sedimento";
        if (this.state.recordType === 'transecto') return "playa_transecto";
    }

    handleSubmit(event) {
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId,
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: this.getRecordType(),
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if(data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });
        event.preventDefault();
    }

    render() {
        let form = null;
        if (this.state.recordType === 'transecto')
            form = <TransectosPlayaForm record={this.state.record}
                                        handleSubmit={this.handleSubmit}
                                        handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'olas')
            form = <OlasForm record={this.state.record}
                             handleSubmit={this.handleSubmit}
                             handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'sedimento')
            form = <SedimentoPlayaForm record={this.state.record}
                                       handleSubmit={this.handleSubmit}
                                       handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'agua')
            form = <CalidadAguaForm record={this.state.record}
                                    handleSubmit={this.handleSubmit}
                                    handleInputChange={this.handleInputChange}/>;
        return (
            <div>{form}</div>
        );
    }
}

export default withSession(PlayaEdit);