import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FaPlus, FaSearch} from 'react-icons/fa';
import {withSession} from "../../../context/SessionContext";
import {Fmts, formatDate} from "../../../util/format";
import withPermalink from "../../withPermalink";
import PageNav from "../../pagenav/PageNav";
import {convertStatsToCSV} from "../../../util/util";
import {FaFileExport} from 'react-icons/fa';



class ArbolesList extends Component {
    constructor(props) {
        super(props);
        const plinkData = this.props.permalinkData;
        this.state = {
            page: {
                limit: 15,
                offset: plinkData && plinkData.page ? Number(plinkData.page) : 1,
                totalRecords: 0
            },
            records: [],
            exportableRecords: null,
        };
    }

    async componentDidMount() {
        this.fetchArboles(this.state.page.offset);
        this.fetchArbolesForExport();
    }

    fetchArboles = async pageNumber => {
        const data = await this.props.rest.query({
            entity: 'arbol_campeon',
            _pageNumber: pageNumber,
            outFields: ['OBJECTID', 'editdate_mdv1', 'hex_id', 'nombre_comun', 'nombre_cientifico', 'municipio_arbol', 'total_puntos', 'GlobalID' ],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            page: {limit: data.limit, offset: pageNumber, totalRecords: data.totalRecords},
            records: data.records
        })
    };

    fetchArbolesForExport = async () => {
        const data = await this.props.rest.queryForExport({
            entity: 'arbol_campeon',
            outFields: ['OBJECTID', 'GlobalID', 'post_title', 'post_content', 'post_category',
                'latitud', 'longitud', 'informacion_relevante', 'total_puntos', 'nombre_comun', 'nombre_cientifico',
                'especie_id', 'status_evento', 'hex_id', 'servicio_forestal', 'condicion_arbol',
                'tipo_lugar', 'barrio_carretera', 'municipio_arbol', 'descripcion_area', 'tipo_contacto',
                'municipio_contacto', 'codigo_postal', 'telefono', 'email', 'nominador', 'direccion_nominador',
                'telefono_nominador', 'email_nominador', 'creationdate_mdv1', 'creator_mdv1', 'editdate_mdv1', 'editor_mdv1'
            ],
            orderByFields: 'creationdate_mdv1 desc'
        });
        const exportableRecords = data.map(f => {
            const exportableRec = {};
            for (const propKey of Object.keys(f.attributes)) {
                switch (propKey) {
                    case 'editdate_mdv1':
                    case 'creationdate_mdv1': {
                        const propVal = f.attributes[propKey];
                        exportableRec[propKey] = propVal ? formatDate(propVal, Fmts.ISO_TIMESTAMP) : null
                        break;
                    }
                    case 'hex_id': {
                        exportableRec['hexagono'] = f.attributes[propKey];
                        break;
                    }
                    default: {
                        exportableRec[propKey] = f.attributes[propKey];
                    }
                }
            }
            return exportableRec;
        });
        debugger;
        this.setState({
            exportableRecords: exportableRecords,
        });
    };

    handlePaginationChange = pageNumber => {
        this.fetchArboles(pageNumber);
        if(this.props.updatePermalink) {
            this.props.updatePermalink({
                page: '' + pageNumber
            })
        }
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h3>Árboles Campeones</h3>
                    </div>
                    <div className="col-6">
                        <div className="form-group text-right align-bottom">
                            <a className="btn btn-primary"
                               href={URL.createObjectURL(new Blob([convertStatsToCSV(this.state.exportableRecords)], {type: 'text/csv;charset=utf-8;'}))}
                               download={`arboles_campeones.csv`}
                            ><FaFileExport/> Exportar</a>&nbsp;
                            <Link className="btn btn-success" to="/arboles/new" role="button">
                                <FaPlus/> Añadir arbol campeón
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="table-responsive mb-3">
                    <table className="table table-hover table-sm table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Fecha de medición</th>
                            <th>Hex ID</th>
                            <th>Nombre común</th>
                            <th>Nombre científico</th>
                            <th>Municipio</th>
                            <th>Puntos</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.records.map(arbol => (
                                <tr key={arbol.OBJECTID}>
                                    <td>{formatDate(arbol.editdate_mdv1)}</td>
                                    <td>{arbol.hex_id}</td>
                                    <td>{arbol.nombre_comun}</td>
                                    <td>{arbol.nombre_cientifico}</td>
                                    <td>{arbol.municipio_arbol}</td>
                                    <td>{arbol.total_puntos}</td>
                                    <td className="text-center">
                                        <Link to={`/arboles/${arbol.GlobalID}`}><FaSearch/></Link>
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />
            </div>
        );
    }

}

export default withSession(withPermalink(ArbolesList));