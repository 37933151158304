import React, {Component, Fragment} from 'react';
import Sidebar from 'react-sidebar';
import {NavLink} from 'react-router-dom';
import {Collapse} from 'reactstrap';
import './Menu.css';
import logo from '../../images/Logo_PLN_Naranja.jpg';
import {ToastContainer} from 'react-toastify';
import PropTypes from 'prop-types';
import {UserSession} from "@esri/arcgis-rest-auth";


const mql = window.matchMedia(`(min-width: 800px)`);

class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mql: mql,
            docked: props.docked,
            open: props.open,
            accordionOpen: null,
            mainElemMinHeight: 600

        };

        this.footer = React.createRef();

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    }

    isAdmin(): boolean {
        const predicate = grp => grp.title.toLowerCase().indexOf("admin") > 0;
        return this.props.user && this.props.user.groups.find(predicate)
    }

    render() {
        let sidebarContent = (
            <div id="menu-content" className="container-fluid">
                <div className="row nav-row">
                    <div className="col-9 text-center nav-col">
                        <img id="logo" src={logo} style={{height: 'auto'}}
                             className="img-fluid mt-3" alt="logo"/>
                    </div>
                    <div className="col-3 nav-col">
                    </div>
                </div>
                <div className="row nav-row">
                    <div className="col flex-column nav-col">
                        <br/>
                        <NavLink className="nav-link" activeClassName="active"
                                 to="/inicio">Inicio</NavLink>

                        <button
                            className={`nav-link nav-link-parent ${this.state.accordionOpen === 'mdv' ? 'nav-link-parent-active' : ''}`}
                            onClick={() => this.setState({accordionOpen: 'mdv'})}>Mapa de Vida
                        </button>

                        <Collapse isOpen={this.state.accordionOpen === 'mdv'} className="nav-link-children">
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/mdv/inicio">Información</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/mdv/mapa">Mapa de hexágonos</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/evento">Eventos</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/mdv/estadisticas">Estadísticas</NavLink>
                        </Collapse>

                        <button
                            className={`nav-link nav-link-parent ${this.state.accordionOpen === 'arboles' ? 'nav-link-parent-active' : ''}`}
                            onClick={() => this.setState({accordionOpen: 'arboles'})}>Arboles Campeones
                        </button>


                        <Collapse isOpen={this.state.accordionOpen === 'arboles'} className="nav-link-children">
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/arboles/mapa">Mapa</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/arboles/list">Eventos</NavLink>

                        </Collapse>

                        <button
                            className={`nav-link nav-link-parent ${this.state.accordionOpen === 'sapo' ? 'nav-link-parent-active' : ''}`}
                            onClick={() => this.setState({accordionOpen: 'sapo'})}>Sapo Concho
                        </button>

                        <Collapse isOpen={this.state.accordionOpen === 'sapo'} className="nav-link-children">

                            <NavLink className="nav-link" activeClassName="active"
                                     to="/sapo-concho/mapa">Mapa</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/sapo-concho/list">Eventos</NavLink>

                        </Collapse>

                        {/*<button className={`nav-link nav-link-parent ${this.state.accordionOpen === 'luminica'? 'nav-link-parent-active': ''}`}
                                onClick={() => this.setState({accordionOpen: 'luminica'})}>Lumínica</button>

                        <Collapse isOpen={this.state.accordionOpen === 'luminica'} className="nav-link-children">
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/luminica/inicio">Información</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/luminica/mapa">Mapa</NavLink>
                            <NavLink className="nav-link" activeClassName="active"
                                     to="/luminica/list">Eventos</NavLink>
                        </Collapse>*/}

                        {this.isAdmin() && (
                            <Fragment>

                                <button
                                    className={`nav-link nav-link-parent ${this.state.accordionOpen === 'admin' ? 'nav-link-parent-active' : ''}`}
                                    onClick={() => this.setState({accordionOpen: 'admin'})}>Administración
                                </button>

                                <Collapse isOpen={this.state.accordionOpen === 'admin'} className="nav-link-children">

                                    <NavLink className="nav-link" activeClassName="active"
                                             to="/admin/especies">Especies</NavLink>
                                </Collapse>
                            </Fragment>
                        )}

                        {this.renderSessionLink()}

                    </div>
                </div>
            </div>
        );

        return (
            <Sidebar sidebar={sidebarContent}
                     open={true}
                     docked={this.state.sidebarDocked}>
                <ToastContainer
                    className='toast-container'
                    bodyClassName='toast-body'/>
                <main style={{minHeight: this.state.mainElemMinHeight}} lang="es-PR">
                    {this.props.children}
                </main>
                <footer ref={this.footer}>
                    {this.props.location.pathname !== '/mdv/mapa' &&
                    this.props.location.pathname !== '/arboles/mapa' &&
                    this.props.location.pathname !== '/sapo-concho/mapa' &&
                    <div className="container">
                        <p className="text-center text-muted font-italic small">Copyright © Para La Naturaleza 2018-2020 </p>
                    </div>
                    }
                </footer>
            </Sidebar>
        );
    }

    renderSessionLink = () => {
        if (this.props.user) {
            return (
                <Fragment>
                    <NavLink className="nav-link" activeClassName="active"
                             to="/perfil">Perfil de usuario</NavLink>
                    <button className="nav-link" onClick={() => {
                        this.props.logout();
                    }}>Cerrar sesión
                    </button>
                </Fragment>
            )
        } else {
            return (
                <button className="nav-link" onClick={() => {
                    UserSession.beginOAuth2({
                        clientId: process.env.REACT_APP_CLIENT_ID,
                        portal: process.env.REACT_APP_SERVER_URL,
                        redirectUri: process.env.REACT_APP_REDIRECT_URL,
                        popup: false,
                    });
                }}>Iniciar sesión</button>
            )
        }
    };

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);
        let mainElemMinHeight = window.innerHeight - this.footer.current.clientHeight - 50;
        if (typeof mainElemMinHeight !== "number") {
            mainElemMinHeight = 600
        }

        this.setState({
            sidebarDocked: mql.matches,
            mainElemMinHeight: mainElemMinHeight
        });
    }

    componentWillUnmount() {
        this.state.mql.removeListener(this.mediaQueryChanged);
    }

    mediaQueryChanged() {
        this.setState({sidebarDocked: this.state.mql.matches});
    }

}

Menu.propTypes = {
    isAdmin: PropTypes.bool,
};

export default Menu;