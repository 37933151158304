import React, {Component} from "react";
import TableContainer from "../TableContainer";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class Suelo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            resultRecordCount: limit,
            entity: 'humedal_suelo',
            where: `evento_GlobalID='${this.props.eventoId}'`,
            outFields: ['OBJECTID', 'profundidad', 'horizonte', 'matriz', 'redox_color',
                'redox_pct', 'redox_tipo', 'redox_loc', 'id_muestra', 'textura'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });

        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        }
    }

    render() {
        return (
            <TableContainer title="Hoja de datos suelos hídricos"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.state.eventoId}/humedales/suelo/new`}>
                <table className="table table-hover table-bordered table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th style={{width: 10 + "%"}}>Profundidad (pulgadas)</th>
                        <th style={{width: 5 + "%"}}>Horizonte</th>
                        <th>Color matriz</th>
                        <th>Color Redox</th>
                        <th style={{width: 10 + "%"}}>% Redox</th>
                        <th>Tipo Redox</th>
                        <th>Loc. Redox</th>
                        <th>Id de muestra</th>
                        <th>Textura</th>
                        <th className="align-top text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.profundidad}</td>
                                <td>{record.horizonte}</td>
                                <td>{record.matriz}</td>
                                <td>{record.redox_color}</td>
                                <td>{record.redox_pct}</td>
                                <td>{record.redox_tipo}</td>
                                <td>{record.redox_loc}</td>
                                <td>{record.idmuestra}</td>
                                <td>{record.textura}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.props.eventoId}/humedales/suelo/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(Suelo);