import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class DatosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sexo: [
                'N/A',
                'Macho',
                'Hembra'
            ],
            sustrato: [
                'Charco',
                'Hierba',
                'Hojarasca',
                'Ramitas',
                'Rocas',
                'Suelo'
            ],
            habitat: [
                'Arbustos',
                'Área abierta',
                'Bosque'
            ],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.setState(prevState => ({
                record: {
                    ...prevState.record,
                    tipo_captura: this.props.tipoCaptura
                }
            }),
        );
        this.props.handleSubmit(event)
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Sapo concho datos</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Cuadrante"
                            required={true}
                            name="cuadrante"
                            autoFocus={true}
                            value={this.state.record.cuadrante}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                        <FormControl
                            label="Especie Sapo"
                            required={true}
                            name="especie_txt"
                            value={this.state.record.especie_txt}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Sexo</label>
                            <select className="form-select form-control"
                                    name="sexo"
                                    value={this.state.record.sexo}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.sexo.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Edad"
                            name="edad"
                            value={this.state.record.edad}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Sustrato donde se encontro</label>
                            <select className="form-select form-control"
                                    name="sustrato"
                                    value={this.state.record.sustrato}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.sustrato.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Longitud cabeza-cola (mm)"
                            name="longitud_cabeza_cola"
                            value={this.state.record.longitud_cabeza_cola}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row">
                        <FormControl
                            type="number"
                            label="Longitud"
                            step="any"
                            name="longitud"
                            value={this.state.record.longitud}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                        <FormControl
                            label="Latitud"
                            type="number"
                            step="any"
                            name="latitud"
                            value={this.state.record.latitud}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Habitat Sapo</label>
                            <select className="form-select form-control"
                                    name="habitat"
                                    value={this.state.record.habitat}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.habitat.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Hora"
                            type="time"
                            name="hora_captura"
                            value={this.state.record.hora_captura}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Comportamiento"
                            name="comportamiento"
                            value={this.state.record.comportamiento}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Observaciones"
                            name="observaciones"
                            value={this.state.record.observaciones}
                            onChange={this.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default DatosForm;