import React, {PureComponent} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";
import EspecieSelect from "../EspecieSelect";

class TransectosLinealesForm extends PureComponent {
    
    render() {
        return (
            <div className="container">
                <Form submit={this.props.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Datos reptiles transectos</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Número de transecto reptiles"
                            type="number"
                            name="num_transecto_lineal"
                            col={6}
                            autoFocus={true}
                            value={this.props.record.num_transecto_lineal}
                            onChange={this.props.handleInputChange}/>

                        <FormControl
                            label="Segmento del transecto reptiles"
                            type="number"
                            name="segmento_transecto"
                            col={6}
                            value={this.props.record.segmento_transecto}
                            onChange={this.props.handleInputChange}/>

                    </div>
                    <div className="row">
                        <EspecieSelect
                            onChange={this.props.handleInputChange}
                            value={this.props.record.especie_id}
                            required={true}
                            name="especie_id"
                            options={this.props.especies}
                        />
                        <FormControl
                            label="Escriba especie de reptil si no aparece en la lista"
                            type="text"
                            name="especie_txt"
                            col={6}
                            required={this.props.record.especie_id && Number(this.props.record.especie_id) === -1}
                            disabled={Number(this.props.record.especie_id) !== -1}
                            value={this.props.record.especie_txt}
                            onChange={this.props.handleInputChange}
                        />
                    </div>
                    <div className="row">
                        <FormControl
                            label="Número de individuos"
                            type="number"
                            col={6}
                            name="cnt_individuos_cuadrante"
                            value={this.props.record.cnt_individuos_cuadrante}
                            onChange={this.props.handleInputChange}/>

                        <FormControl
                            label="Componente del hábitat"
                            type="text"
                            name="componente_habitat"
                            col={6}
                            value={this.props.record.componente_habitat}
                            onChange={this.props.handleInputChange}
                            className="form-control"/>
                    </div>

                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Comportamiento de reptiles</label>
                            <select className="form-select form-control"
                                    name="comportamiento_reptil"
                                    value={this.props.record.comportamiento_reptil}
                                    onChange={this.props.handleInputChange}>
                                {this.props.comportamiento.map(comp => (
                                    <option key={comp}>{comp}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default TransectosLinealesForm;