import React, {Component} from 'react';
import DatosForm from "./DatosForm";
import RemocionForm from "./RemocionForm";
import {withSession} from "../../context/SessionContext";


class SapoEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            recordType: props.match.params.recordType,
            record: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId, this.getRecordType());
        }
    }

    async fetchRecord(id, type) {
        let options = {
            entity: type,
            resultRecordCount: 1
        };

        if (this.state.recordType === 'datos') {
            options.where = `GlobalID='${id}' and tipo_captura='EN_PARCELA'`;
        }
        if (this.state.recordType === 'datos-fuera') {
            options.where = `GlobalID=${id} and tipo_captura='FUERA_PARCELA'`;
        }
        if (this.state.recordType === 'remocion') {
            options.where = `GlobalID='${id}'`;
        }

        const data = await this.props.rest.query(options);

        this.setState({
            record: data.records[0],
        });
    }

    getRecordType() {
        if (this.state.recordType === 'datos') return "sapo_concho_datos";
        if (this.state.recordType === 'datos-fuera') return "sapo_concho_datos";
        if (this.state.recordType === 'remocion') return "sapo_concho_remocion";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    }

    handleSubmit(event) {
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: this.getRecordType(),
                attributes: this.state.record
            };

            if (this.state.recordType === 'datos') options.attributes.tipo_captura = 'EN_PARCELA';
            if (this.state.recordType === 'datos-fuera') options.attributes.tipo_captura = 'FUERA_PARCELA';

            this.props.rest.save(options).then(data => {
                if(data.success) {
                    this.props.history.push(`/sapo-concho/${this.state.eventoId}`);
                }
            });
        });
        event.preventDefault();
    }

    render() {
        let form = null;
        if (this.state.recordType === 'datos')
            form = <DatosForm record={this.state.record}
                              handleSubmit={this.handleSubmit}
                              handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'datos-fuera')
            form = <DatosForm record={this.state.record}
                              handleSubmit={this.handleSubmit}
                              handleInputChange={this.handleInputChange}/>;
        if (this.state.recordType === 'remocion')
            form = <RemocionForm record={this.state.record}
                                 handleSubmit={this.handleSubmit}
                                 handleInputChange={this.handleInputChange}/>;

        return (
            <div>{form}</div>
        );
    }
}

export default withSession(SapoEdit);





