//@flow
import React, {PureComponent} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";
import {INDICADORES_PRIM, INDICADORES_SEG, INDICADORES_SUELO} from './Common';

const DETERMINACION_VEGETACION = [
    '< del 50% es vegetación hídrica',
    '> del 50% es vegetación hídrica'
];

type Props = {
    handleSubmit: (event: any) => void,
    handleInputChange: (event: any) => void,
    record: Object,
}


export default class DatosAdicionalesForm extends PureComponent<Props> {
    
    render() {
        return (
            <div className="container">
                <Form submit={this.props.handleSubmit}>
                    {/*<div>{JSON.stringify(this.state)}</div>*/}
                    <div className="row">
                        <div className="col">
                            <h4>Humedales Indicadores Datos</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <h5>Indicadores de suelos</h5>
                            {Object.keys(INDICADORES_SUELO).map((key, idx) => (
                                <div className="form-check" key={key}>
                                    <input name={key}
                                           id={key}
                                           className="form-check-input"
                                           type="checkbox"
                                           onChange={this.props.handleInputChange}
                                           value={key}
                                           checked={this.props.record[key]}/>
                                    <label className="form-check-label"
                                           htmlFor={key}>
                                        {INDICADORES_SUELO[key]}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="col-6 form-group">
                            <h5>Indicadores de hidrología primarios</h5>
                            {Object.keys(INDICADORES_PRIM).map((key, idx) => (
                                <div className="form-check" key={key}>
                                    <input name={key}
                                           id={key}
                                           className="form-check-input"
                                           type="checkbox"
                                           onChange={this.props.handleInputChange}
                                           value={key}
                                           checked={this.props.record[key]}/>
                                    <label className="form-check-label"
                                           htmlFor={key}>
                                        {INDICADORES_PRIM[key]}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <h5>Indicadores de hidrología secundarios</h5>
                            {Object.keys(INDICADORES_SEG).map((key, idx) => (
                                <div className="form-check" key={key}>
                                    <input name={key}
                                           id={key}
                                           className="form-check-input"
                                           type="checkbox"
                                           onChange={this.props.handleInputChange}
                                           value={key}
                                           checked={this.props.record[key]}/>
                                    <label className="form-check-label"
                                           htmlFor={key}>
                                        {INDICADORES_SEG[key]}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="col-6 form-group">
                            <h5>Determinación de vegetación hídrica</h5>
                            {DETERMINACION_VEGETACION.map(veg => (
                                <div className="form-check" key={veg}>
                                    <input name="hum_vegetacion"
                                           id={veg}
                                           className="form-check-input"
                                           type="radio"
                                           onChange={this.props.handleInputChange}
                                           checked={this.props.record.hum_vegetacion === veg}
                                           value={veg}/>
                                    <label className="form-check-label"
                                           htmlFor={veg}>
                                        {veg}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Total de especies dominantes"
                            type="number"
                            step="any"
                            col={6}
                            name="hum_total_especies"
                            value={this.props.record.hum_total_especies}
                            onChange={this.props.handleInputChange}/>
                        <FormControl
                            label="Porcientos de especies dominantes"
                            type="number"
                            step="any"
                            col={6}
                            name="hum_pct_especies"
                            value={this.props.record.hum_pct_especies}
                            onChange={this.props.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}
