import React, {Component} from 'react';
import type {SessionProps} from "../../context/SessionContext";
import {withSession} from "../../context/SessionContext";
import {Fmts, formatDate, parseISODate} from "../../util/format";
import placeholder from '../../images/avatar-placeholder.gif';
import {FechaStatsGraph} from "../mdv/stats/FechaStatsGraph";
import {addMonths, isBefore} from 'date-fns';

class Perfil extends Component<SessionProps> {

    constructor(props) {
        super(props);
        this.state = {
            fechaEventoStats: null,
            fechaEspeciesStats: null,
        }
    }

    componentDidMount() {
        if (!this.props.user) {
            return;
        }

        this.loadStats();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user === this.props.user) {
            return;
        }

        this.loadStats();
    }

    loadStats() {
        this.props.refDataRest.query({
            entity: 'editor_yyyy_mm_evento_stats_v',
            where: `editor = '${this.props.user.username}'`,
            orderByFields: 'fecha_yyyy_mm',
        }).then(fechaStats => {
            this.setState({
                fechaEventoStats: this.fillGaps(fechaStats.records),
            })
        });

        this.props.refDataRest.query({
            entity: 'editor_yyyy_mm_especies_stats_v',
            where: `editor = '${this.props.user.username}'`,
            orderByFields: 'fecha_yyyy_mm',
        }).then(fechaStats => {
            this.setState({
                fechaEspeciesStats: this.fillGaps(fechaStats.records),
            })
        });
    }

    fillGaps(fechaStats: { fecha_yyyy_mm: string }[]) {
        const result = [];
        if(!fechaStats || fechaStats.length === 0) {
            return result;
        }

        const zeroRow = {};
        for (let key in fechaStats[0]){
            if(fechaStats[0].hasOwnProperty(key) && key !== 'fecha_yyyy_mm'){
                zeroRow[key] = 0;
            }
        }

        const minDate = parseISODate(`${fechaStats[0].fecha_yyyy_mm}-01`);
        const now = new Date();
        for (let i = 0; isBefore(addMonths(minDate, i), now); i++) {
            const date = addMonths(minDate, i);
            const yyyyMM = formatDate(date, 'yyyy-MM');
            const found = fechaStats.find(it => it.fecha_yyyy_mm === yyyyMM);
            result.push(found || {...zeroRow, fecha_yyyy_mm: yyyyMM });
        }

        return result;
    }

    render() {
        const {user, session} = this.props;

        if (!user) {
            return null;
        }

        let imgPath;
        if(user.thumbnail) {
            imgPath = `${process.env.REACT_APP_SERVER_URL}/community/users/${user.username}/info/${user.thumbnail}?token=${session.token}`;
        } else {
            imgPath = placeholder;
        }

        return (
            <div className="container">

                <h2>Perfil de usuario</h2>

                {/*<div>{JSON.stringify(user)}</div>*/}

                <div className="row">
                    <div className="col-2">
                        <img src={imgPath} style={{height: 'auto'}}
                             className="img-fluid" alt="Imágen de perfil"/>
                    </div>
                    <div className="col-10">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Usuario:</td>
                                <td>{user.username}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Nombre:</td>
                                <td>{user.fullName}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Fecha de creación:</td>
                                <td>{formatDate(user.created, Fmts.TIMESTAMP)}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: 'bold'}}>Fecha de actualización:</td>
                                <td>{formatDate(user.modified, Fmts.TIMESTAMP)}</td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

                <h3>Miembro de grupos</h3>
                <div className="table-responsive mb-3">
                    <table className="table table-hover table-sm table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Nombre de grupo</th>
                            <th>Descripción</th>
                        </tr>
                        </thead>
                        <tbody>
                        {user.groups.map(grp => (
                                <tr key={'grupo-' + grp.title}>
                                    <td>{grp.title}</td>
                                    <td>{grp.description || grp.snippet}</td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>

                <FechaStatsGraph title="Eventos editados por mes" fechaStats={this.state.fechaEventoStats}/>
                <FechaStatsGraph title="Especies editadas por mes" fechaStats={this.state.fechaEspeciesStats}/>
            </div>
        );
    }
}

export default withSession(Perfil);



