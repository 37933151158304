import React, {Component} from 'react';
import {Map as ReactMapboxGL, Cluster, Marker, ZoomControl, ScaleControl, Popup} from 'react-mapbox-gl';
import './SapoConchoMapa.css'
import sapoMarker from '../../../images/sapo-concho.png';
import {MapUtils} from "../../../util/map";
import {withSession} from "../../../context/SessionContext";
import type {SessionProps} from "../../../context/SessionContext";

const ReactMap = ReactMapboxGL({
    accessToken: process.env.REACT_APP_MAPBOX_API_KEY
});

const MAP_STYLE = "mapbox://styles/mapbox/streets-v9";


class SapoConchoMapa extends Component<SessionProps> {

    constructor(props) {
        super(props);

        this.state = {
            viewport: MapUtils.hashStringToViewport(window.location.hash),
            popup: null,
            sapos: {type: 'FeatureCollection', features: []},
            mapContainerHeight: window.innerHeight
        };

        this.initMap = this.initMap.bind(this);
        this.updatePermalink = this.updatePermalink.bind(this);
        this.onWindowPopState = this.onWindowPopState.bind(this);
        this.onMapMove = this.onMapMove.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('popstate', this.onWindowPopState);
        const boundaries = await this.props.refDataRest.queryGeoJSON({entity: 'sapo_concho_v'});
        this.setState({
            sapos: boundaries,
        });
    }


    render() {

        const viewport = this.state.viewport;
        const mapStyle = {
            width: '100%',
            height: this.state.mapContainerHeight
        };

        return (
            <ReactMap
                ref="mapElem"
                style={MAP_STYLE}
                center={[viewport.longitude, viewport.latitude]}
                zoom={[viewport.zoom]}
                bearing={viewport.bearing}
                containerStyle={mapStyle}
                onStyleLoad={this.initMap}
                onZoomEnd={this.onMapMove}
                onDragEnd={this.onMapMove}
                onRotateEnd={this.onMapMove}>
                
                <ZoomControl position="topLeft" style={{left: '10px', top: '10px'}} />
                <ScaleControl position="bottomRight" style={{bottom: '30px', right: '10px'}}/>

                <Cluster ClusterMarkerFactory={this.createClusterMarker}>
                    {
                        this.state.sapos.features
                            .filter((feature) => feature.geometry !== null)
                            .map((feature, key) => (
                                <Marker
                                    key={`marker${key}`}
                                    coordinates={feature.geometry.coordinates}
                                    onClick={this.onClickSapo.bind(this, feature)}>
                                        <img src={sapoMarker} alt="Sapo Concho"/>
                                </Marker>
                            )
                        )
                    }
                </Cluster>

                {this.state.popup}

            </ReactMap>
        );
    }


    componentWillUnmount() {
        window.removeEventListener('popstate', this.onWindowPopState);
    }

    initMap(map, evt) {

        this.setState(prevState => ({
            map: map,
        }));

    }

    createClusterMarker(coordinates, pointCount, getLeaves) {
        
        return (
            <Marker coordinates={coordinates}>
                <div style={{
                    boxShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white',
                    border: '1px solid black',
                    borderRadius: '3px',
                    padding: '1px',
                    marginTop: '0',
                    height: '14px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    right: '-3px',
                    top: '-8px' }}>
                    <span style={{ position: 'relative', top: '-4px' }}> {pointCount} </span>
                </div>
                <img src={sapoMarker} alt="Conjunto de sapos capturados"/>
            </Marker>
        )
    };


    onClickSapo(feature, event) {
        this.setState(prevState => ({
            popup: null
        }));

        this.setState(prevState => ({
            popup: (
                <SapoPopup coordinates={feature.geometry.coordinates}
                           feature={feature}
                           onClickClose={() => this.setState({popup: null})}
                />
            )
        }));
        //event.preventDefault();
    }

    onMapMove(evt) {
        this.updatePermalink();
        this.setState(prevState => ({
            viewport: {
                zoom: prevState.map.getZoom(),
                longitude: prevState.map.getCenter().lng,
                latitude: prevState.map.getCenter().lat,
                bearing: prevState.map.getBearing(),
            }
        }));
    }

    updatePermalink() {
        if (!this.state.map) {
            return;
        }

        if (!this.state.shouldUpdatePermalink) {
            this.setState({
                shouldUpdatePermalink: true
            });
            return;
        }

        const viewport = MapUtils.getMapViewport(this.state.map);
        const hashStr = MapUtils.viewportToHashString(viewport, window.location.hash);
        window.history.pushState({ viewport: viewport }, 'Mapa de Vida', hashStr);
    }

    onWindowPopState(event) {
        if (event.state === null) {
            return;
        }

        this.setState({
            viewport: event.state.viewport
        });
    }


}

class SapoPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coordinates: props.coordinates,
            feature: props.feature,
            onClickClose: props.onClickClose
        }
    }

    render() {

        const fProps = this.state.feature.properties;
        return (
            <Popup coordinates={this.state.coordinates} anchor="bottom" offset={37}>
                <button className="mapboxgl-popup-close-button" type="button" aria-label="Close popup"
                        onClick={() => this.state.onClickClose && this.state.onClickClose() }>×</button>
                <h5 style={{marginBottom: 0}}>Captura</h5>
                <p>Especie: {fProps.especie_txt}<br/>
                Fecha: {fProps.fecha_txt}<br/>
                Hora: {fProps.hora_captura}<br/>
                Hexagono: {fProps.hex_id}</p>
            </Popup>
        )
    }
}


export default withSession(SapoConchoMapa);
