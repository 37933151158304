import React, {Component} from "react";
import TableContainer from "../TableContainer";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class CaracteristicasFisicas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            fetchRecords: props.fetchRecords,
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            resultRecordCount: limit,
            entity: 'cuenca_caracteristicas_fisicas',
            where: `evento_GlobalID='${this.state.eventoId}'`,
            outFields: ['OBJECTID', 'sitio_xfisico', 'distancia_banco', 'profundidad', 'velocidad', 'comm_fisico', 'sustrato'],
            orderByFields: 'creationdate_mdv1 desc'
        });
        this.setState({
            records: data.records
        });
        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        }
    }

    render() {
        return (
            <TableContainer title="Características físicas"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/evento/${this.state.eventoId}/cuencas/fisicas/new`}>
                <table className="table table-hover table-bordered table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>X Site Point</th>
                        <th>Distancias del banco</th>
                        <th>Profundidad</th>
                        <th>Velocidad</th>
                        <th>Comentarios</th>
                        <th>Sustrato</th>
                        <th className="text-center">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.sitio_xfisico}</td>
                                <td>{record.distancia_banco}</td>
                                <td>{record.profundidad}</td>
                                <td>{record.velocidad}</td>
                                <td>{record.comm_fisico}</td>
                                <td>{record.sustrato}</td>
                                <td className="text-center">
                                    <Link to={`/evento/${this.state.eventoId}/cuencas/fisicas/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(CaracteristicasFisicas);