import React, {Component} from 'react';
import BackButton from "../../BackButton";
import {withSession} from "../../../context/SessionContext";
import Form from "../../Form";
import FormControl from "../../FormControl";
import {Fmts, formatDate} from "../../../util/format";


class ArbolesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordId: props.match.params.recordId,
            status: ['Pendiente', 'Revisión', 'Completado'],
            categoria: [''],
            meses: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    }

    handleSubmit(event) {
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }), () => {
            let files = document.querySelector("input[name='arbol_image'").files;

            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: 'arbol_campeon',
                attributes: this.state.record,
                attachment: files.length > 0 ? files[0] : null
            };

            this.props.rest.save(options).then(data => {
                if (data.success) {
                    this.props.history.push(`/arboles/${data.globalId}`);
                }
            });
        });

        event.preventDefault();
    }

    componentDidMount() {
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId);
        }
    }

    async fetchRecord(id) {
        const data = await this.props.rest.getByGlobalID({
            entity: 'arbol_campeon',
            globalId: id,
        });
        this.setState({
            record: data,
        });
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit} encType="multipart/form-data">
                    <div className="row">
                        <div className="col">
                            <h3>Árboles Campeones</h3>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Título / Nombre común del árbol"
                            name="post_title"
                            id="input_1_1"
                            value={this.state.record.post_title}
                            autoFocus={true}
                            onChange={this.handleInputChange}
                            type="text"/>
                    </div>
                    <h4>Evento</h4>
                    <div className="row">
                        <div className="ginput_container">
                            <FormControl
                                label="Fecha"
                                name="creationdate_mdv1"
                                id="input_1_2"
                                value={formatDate(this.state.record.creationdate_mdv1, Fmts.ISO_DATE)}
                                onChange={this.handleInputChange}
                                required={true}
                                type="date"/>
                        </div>
                        <FormControl
                            label="Hex Id"
                            name="hex_id"
                            id="input_1_4"
                            value={this.state.record.hex_id}
                            onChange={this.handleInputChange}
                            className="form-control"
                            type="number"/>
                        <FormControl
                            label="Latitud"
                            name="latitud"
                            required={true}
                            id="input_1_6"
                            value={this.state.record.latitud}
                            onChange={this.handleInputChange}
                            type="number"
                            step="any"/>
                        <FormControl
                            label="Longitud"
                            name="longitud"
                            required={true}
                            id="input_1_7"
                            value={this.state.record.longitud}
                            onChange={this.handleInputChange}
                            className="form-control"
                            type="number"
                            step="any"/>
                    </div>


                    <div className="row">
                        <div className="col-6 form-group">
                            <label htmlFor="input_1_5">Status del evento</label>
                            <select name="post_status"
                                    id="input_1_5"
                                    value={this.state.record.post_status}
                                    onChange={this.handleInputChange}
                                    className="form-control">
                                {this.state.status.map(s => (
                                    <option value={s}>{s}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <h4>Árbol Nominado</h4>

                    <div className="row">
                        <FormControl
                            label="Nombre Común"
                            name="nombre_comun"
                            id="input_1_10"
                            value={this.state.record.nombre_comun}
                            onChange={this.handleInputChange}
                            required={true}
                            type="text"/>

                        <FormControl
                            label="Nombre Científico"
                            name="nombre_cientifico"
                            id="input_1_12"
                            value={this.state.record.nombre_cientifico}
                            onChange={this.handleInputChange}
                            required={true}
                            type="text"/>

                        <FormControl
                            label="Condición del Árbol"
                            name="condicion_arbol"
                            id="input_1_11"
                            value={this.state.record.condicion_arbol}
                            onChange={this.handleInputChange}
                            type="text"/>

                        <FormControl
                            label="Total de Puntos"
                            name="total_puntos"
                            id="input_1_14"
                            value={this.state.record.total_puntos}
                            onChange={this.handleInputChange}
                            type="text"/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Información Relevante"
                            name="informacion_relevante"
                            id="input_1_13"
                            value={this.state.record.informacion_relevante}
                            onChange={this.handleInputChange}
                            type="text"/>
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_15">Foto del árbol</label>
                            <input name="arbol_image"
                                   id="input_1_15"
                                   className="form-control-file"
                                   type="file"/>
                        </div>
                    </div>

                    <h4>Localizacion del árbol</h4>
                    <div className="row">
                        <FormControl
                            label="Tipo de Lugar"
                            name="tipo_lugar"
                            id="input_1_17"
                            value={this.state.record.tipo_lugar}
                            onChange={this.handleInputChange}
                            type="text"/>
                        <FormControl
                            label="Barrio y Carretera"
                            name="barrio_carretera"
                            id="input_1_18"
                            value={this.state.record.barrio_carretera}
                            onChange={this.handleInputChange}
                            type="text"/>
                        <FormControl
                            label="Municipio"
                            name="municipio_arbol"
                            id="input_1_19"
                            value={this.state.record.municipio_arbol}
                            onChange={this.handleInputChange}
                            type="text"/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Descripción del Área"
                            name="descripcion_area"
                            id="input_1_20"
                            value={this.state.record.descripcion_area}
                            onChange={this.handleInputChange}
                            type="text"/>
                    </div>

                    <h4>Contacto</h4>
                    <div className="row">
                        <FormControl
                            label="Tipo de Contacto"
                            name="tipo_contacto"
                            id="input_1_22"
                            value={this.state.record.tipo_contacto}
                            onChange={this.handleInputChange}
                            className="form-control"
                            type="text"/>

                        <FormControl
                            label="Dirección"
                            name="direccion_nominador"
                            id="input_1_23"
                            value={this.state.record.direccion_nominador}
                            onChange={this.handleInputChange}
                            type="text"/>

                        <FormControl
                            label="Municipio"
                            name="municipio_contacto"
                            id="input_1_25"
                            value={this.state.record.municipio_contacto}
                            onChange={this.handleInputChange}
                            type="text"/>

                    </div>
                    <div className="row">
                        <FormControl
                            label="Código Postal"
                            name="codigo_postal"
                            id="input_1_26"
                            value={this.state.record.codigo_postal}
                            onChange={this.handleInputChange}
                            type="text"/>

                        <FormControl
                            label="Teléfono"
                            name="telefono"
                            id="input_1_27"
                            value={this.state.record.telefono}
                            onChange={this.handleInputChange}
                            type="phone"/>

                        <FormControl
                            label="Email"
                            name="email"
                            id="input_1_28"
                            value={this.state.record.email}
                            onChange={this.handleInputChange}
                            type="email"/>
                    </div>

                    <h4>Comentarios</h4>
                    <div className="row">
                        <div className="col form-group">
                            <label className="gfield_label"
                                   htmlFor="input_1_46">Comentarios</label>
                            <textarea name="input_46"
                                      id="input_1_46"
                                      className="form-control"
                                      value="???"
                                      rows="8"
                                      cols="50"/>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_48">Mes</label>
                            <div className="ginput_container">
                                <select name="input_48"
                                        id="input_1_48"
                                        className="form-control">
                                    {this.state.meses.map(mes => (
                                        <option value={mes}>{mes}</option>
                                    ))}
                                </select></div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withSession(ArbolesEdit);



