//@flow
import React, {Fragment} from 'react';
import {Fmts, formatDate} from "../util/format";

type Props = {
    entity: Object,
}

export function AuditData(props: Props) {

    const entity = props.entity;
    return (
        <Fragment>
            <h3>Datos de registro</h3>
            <table className="table mt-3">
                <tbody>
                <tr>
                    <td className="font-weight-bold">Creado por:</td>
                    <td>{entity.creator_mdv1}</td>
                    <td className="font-weight-bold">Editado por:</td>
                    <td>{entity.editor_mdv1}</td>
                </tr>
                <tr>
                    <td className="font-weight-bold">Creado en:</td>
                    <td>{formatDate(entity.creationdate_mdv1, Fmts.TIMESTAMP)}</td>
                    <td className="font-weight-bold">Editado en:</td>
                    <td>{formatDate(entity.editdate_mdv1, Fmts.TIMESTAMP)}</td>
                </tr>
                </tbody>
            </table>
        </Fragment>
    );
}