import React, {Component} from 'react';
import BackButton from "../../BackButton";
import {withSession} from "../../../context/SessionContext";
import {Fmts, formatDate} from "../../../util/format";


class ArbolesMedicionEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordId: props.match.params.recordId,
            arbolId: props.match.params.arbolId,
            record: {
                medicion: {},
                arbol: {}
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                medicion: {...prevState.record.medicion, [name]: value},
                arbol: {...prevState.record.arbol}
            }
        }));
    }

    handleSubmit(event) {
        this.setState(prevState => ({
            record: {
                medicion: {
                    ...prevState.record.medicion,
                    arbol_campeon_GlobalID: this.state.record.arbol.GlobalID
                },
                arbol: {...prevState.record.arbol}
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: 'arbol_campeon_medicion',
                attributes: this.state.record.medicion
            };

            this.props.rest.save(options).then(data => {
                this.props.history.push(`/arboles/${this.state.record.arbol.GlobalID}`);
            });
        });

        event.preventDefault();
    }

    componentDidMount() {
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId);
        } else {
            this.fetchArbol(this.state.arbolId);
        }
    }

    async fetchArbol(id) {
        const arbol = await this.props.rest.getByGlobalID({
            entity: 'arbol_campeon',
            globalId: id,
        });

        this.setState({
            record: {
                arbol: arbol,
                medicion: {}
            },
        });
    }

    async fetchRecord(id) {
        const medicion = await this.props.rest.getByGlobalID({
            entity: 'arbol_campeon_medicion',
            globalId: id,
        });

        const arbol = await this.props.rest.getByGlobalID({
            entity: 'arbol_campeon',
            globalId: medicion.arbol_campeon_GlobalID,
        });

        this.setState({
            record: {
                arbol: arbol,
                medicion: medicion
            },
        });
    }

    render() {
        return (
            <div className="container">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h3>{this.state.record.arbol.nombre_comun} - Medición</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_1">Fecha de medición <i className="text-danger">*</i></label>
                            <input name="fecha_medicion"
                                   id="input_1_1"
                                   value={formatDate(this.state.record.medicion.fecha_medicion, Fmts.ISO_DATE)}
                                   onChange={this.handleInputChange}
                                   className="form-control"
                                   type="date"
                                   autoFocus={true}
                                   required={true}/>
                        </div>
                        <div className="col form-group">
                            <label htmlFor="input_1_2">Diametro</label>
                            <div className="ginput_container">
                                <input name="diametro"
                                       id="input_1_2"
                                       value={this.state.record.medicion.diametro}
                                       onChange={this.handleInputChange}
                                       className="form-control"
                                       type="number"
                                       step="any"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_1">Altura (p)</label>
                            <input name="altura_pies"
                                   id="input_1_1"
                                   value={this.state.record.medicion.altura_pies}
                                   onChange={this.handleInputChange}
                                   className="form-control"
                                   type="number"
                                   step="any"/>
                        </div>
                        <div className="col form-group">
                            <label htmlFor="input_1_2">Mayor extensión</label>
                            <div className="ginput_container">
                                <input name="mayor_extension"
                                       id="input_1_2"
                                       value={this.state.record.medicion.mayor_extension}
                                       onChange={this.handleInputChange}
                                       className="form-control"
                                       type="number"
                                       step="any"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_1">Menor extención</label>
                            <input name="menor_extension"
                                   id="input_1_1"
                                   value={this.state.record.medicion.menor_extension}
                                   onChange={this.handleInputChange}
                                   className="form-control"
                                   type="number"
                                   step="any"/>
                        </div>
                        <div className="col form-group">
                            <label htmlFor="input_1_1">Promedio de copa</label>
                            <input name="promedio_copa"
                                   id="input_1_1"
                                   value={this.state.record.medicion.promedio_copa}
                                   onChange={this.handleInputChange}
                                   className="form-control"
                                   type="number"
                                   step="any"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col form-group">
                            <label htmlFor="input_1_2">1/4 extensión de la copa</label>
                            <div className="ginput_container">
                                <input name="cuarto_extension_copa"
                                       id="input_1_2"
                                       value={this.state.record.medicion.cuarto_extension_copa}
                                       onChange={this.handleInputChange}
                                       className="form-control"
                                       type="number"
                                       step="any"/>
                            </div>
                        </div>
                        <div className="col form-group">
                            <label htmlFor="input_1_2">Voluntarios</label>
                            <div className="ginput_container">
                                <input name="voluntarios"
                                       id="input_1_2"
                                       value={this.state.record.medicion.voluntarios}
                                       onChange={this.handleInputChange}
                                       className="form-control"
                                       type="number"
                                       step="any"/>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default withSession(ArbolesMedicionEdit);



