//@flow
import React, {Fragment} from 'react';
import {defaults, HorizontalBar} from "react-chartjs-2";
import {FaFileExport} from "react-icons/fa";
import LoadingIcon from "../../LoadingIcon";
import {convertStatsToCSV} from "../../../util/util";

defaults.global.defaultFontFamily = 'Avenir-Roman';

type EditorEspeciesStats = {
    editor: string,
    especies: number,
    diversidad: number,
    especies_aves: number,
    diversidad_aves: number,
    especies_reptiles: number,
    diversidad_reptiles: number,
    especies_vegetacion: number,
    diversidad_vegetacion: number,
}


type Props = {
    editorEspeciesStats: EditorEspeciesStats[],
}

export function EditorEspeciesStatsGraph(props: Props) {

    if(!props.editorEspeciesStats) {
        return <LoadingIcon/>
    }

    const data = {
        labels: props.editorEspeciesStats.map(f => f.editor ? f.editor : '* Desconocido *'),
        datasets: [{
            data: props.editorEspeciesStats.map(f => f.especies),
            backgroundColor: 'rgba(55,126,184, 0.8)',
            borderColor: 'rgba(55,126,184, 0.9)',
            label: 'Especies',
        }, {
            data: props.editorEspeciesStats.map(f => f.diversidad),
            backgroundColor: 'rgba(77,175,74, 0.8)',
            borderColor: 'rgba(77,175,74, 0.9)',
            label: 'Diversidad',
        }, ]
    };
    const options = {
        responsive: true,
    };

    return (
        <Fragment>
            <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                <div className="col">
                    <h3>Especies por editor</h3>
                </div>
                <div className="col text-right">
                    <a className="btn btn-primary"
                       href={URL.createObjectURL(new Blob([convertStatsToCSV(props.editorEspeciesStats)], {type: 'text/csv;charset=utf-8;'}))}
                       download={`especies_por_editor.csv`}><FaFileExport/> Exportar</a>
                </div>
            </div>
            <HorizontalBar data={data} options={options}/>
        </Fragment>
    );
}

EditorEspeciesStatsGraph.defaultProps = {
    editorEspeciesStats: [],
};