//@flow
import React, {Component} from "react";
import BackButton from "../BackButton";
import {withSession} from "../../context/SessionContext";
import Form from "../Form";
import FormControl from "../FormControl";
import type {SessionProps} from "../../context/SessionContext";
import EspecieSelect from "../EspecieSelect";

class VegetacionEdit extends Component<SessionProps> {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            formMethod: 'POST',
            especies: [],
            record: {}
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    };

    componentDidMount() {
        this.fetchEspecies();
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId);
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record, evento_GlobalID: this.state.eventoId
            }
        }));
    }

    async fetchRecord(id) {
        const data = await this.props.rest.get({
            entity: 'vegetacion',
            id: id,
        });
        this.setState({
            record: data,
        });
    }

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('vegetacion').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    handleSubmit = (event) => {
        let especieTxt = this.state.record.especie_txt;
        if (this.state.record.especie_id && this.state.record.especie_id > 0) {
            especieTxt = this.props.especieService.getSelectedSpecieName(this.state.especies, this.state.record.especie_id)
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId,
                especie_txt: especieTxt,
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: 'vegetacion',
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if(data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });

        event.preventDefault();
    };

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Datos compilados de vegetación</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="ID del transecto vegetación"
                            type="number"
                            col={6}
                            name="idtransecto"
                            autoFocus={true}
                            required={true}
                            value={this.state.record.idtransecto}
                            onChange={this.handleInputChange}/>
                        <div className="col-6 form-group">
                            <label>Distancia vegetación</label>
                            <select className="form-select form-control"
                                    name="distancia_vegetacion"
                                    value={this.state.record.distancia_vegetacion}
                                    onChange={this.handleInputChange}>
                                <option/>
                                <option>0-25m</option>
                                <option>25-50m</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <EspecieSelect
                            onChange={this.handleInputChange}
                            value={this.state.record.especie_id}
                            required={true}
                            name="especie_id"
                            options={this.state.especies}
                        />
                        <FormControl
                            label="Escriba especie de vegetación si no aparece en la lista"
                            type="text"
                            name="especie_txt"
                            required={this.state.record.especie_id && Number(this.state.record.especie_id) === -1}
                            disabled={Number(this.state.record.especie_id) !== -1}
                            value={this.state.record.especie_txt}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="DBH (cm) Vegetación"
                            type="number"
                            name="dbh"
                            value={this.state.record.dbh}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Altura (p) Vegetación"
                            type="number"
                            name="altura_vegetacion"
                            value={this.state.record.altura_vegetacion}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default withSession(VegetacionEdit);