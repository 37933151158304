//@flow
import React, {Component} from 'react';
import {Map as ReactMapboxGL, ScaleControl, ZoomControl, GeoJSONLayer, Marker} from 'react-mapbox-gl';
import './MdvMapa.css'
import {DEFAULT_VIEWPORT} from "../../../util/map";
import {withSession} from "../../../context/SessionContext";
import type {SessionProps} from "../../../context/SessionContext";
import HexLabelLayer from "./HexLabelLayer";
import HexBoundariesLayer from "./HexBoundariesLayer";

const ReactMap = ReactMapboxGL({
    accessToken: process.env.REACT_APP_MAPBOX_API_KEY
});

const MAP_STYLE = "mapbox://styles/mapbox/streets-v9";

type Props = {
    ...SessionProps,
    height?: number,
    lat?: number,
    lng?: number,
}

class CoordViewMapa extends Component<Props> {

    constructor(props) {
        super(props);

        let vport = DEFAULT_VIEWPORT;
        if(props.lat && props.lng) {
            vport = {
                latitude: props.lat,
                longitude: props.lng,
                zoom: 11,
                bearing: 0
            }
        }

        this.state = {
            viewport: vport,
            map: null,
            mapContainerHeight: this.props.height || window.innerHeight,
            boundaries: {},
            centroids: {},
        };

    }

    async componentDidMount() {
        const boundaries = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_boundaries'
        });
        this.setState({
            boundaries: boundaries,
        });

        const centroids = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_centroids_v'
        });
        this.setState({
            centroids: centroids,
        });
    }

    render() {

        const vport = this.state.viewport;

        const mapStyle = {
            width: '100%',
            height: this.state.mapContainerHeight
        };

        return (
            <ReactMap
                style={MAP_STYLE}
                center={[vport.longitude, vport.latitude]}
                zoom={[vport.zoom]}
                bearing={vport.bearing}
                containerStyle={mapStyle}
                onStyleLoad={this.initMap}>

                <ZoomControl position="topLeft" style={{left: '10px', top: '10px'}} />
                <ScaleControl position="bottomRight" style={{bottom: '30px', right: '10px'}}/>

                <HexBoundariesLayer data={this.state.boundaries}/>
                <HexLabelLayer data={this.state.centroids}
                               minZoom={8} textSize={14}/>

                <Marker coordinates={[this.state.viewport.longitude, this.state.viewport.latitude]} anchor="bottom">
                    <div className="mapMarkerStyle" />
                </Marker>

            </ReactMap>
        );
    }

    initMap = (map, evt) => {
        this.setState({
            map: map,
        });
    };

}


export default withSession(CoordViewMapa);
