//@flow
import React, {PureComponent} from 'react';
import {GeoJSONLayer} from "react-mapbox-gl";

type Props = {
    textSize?: number,
    data: any,
    minZoom?: number,
}

export default class HexLabelLayer extends PureComponent<Props> {

    static defaultProps = {
        textSize: 12,
        minZoom: 10,
    };

    render() {
        return (this.props.data &&
            <GeoJSONLayer
            data={this.props.data}
            id="mdv-hex-labels"
            symbolLayout={{
                "text-size": this.props.textSize,
                "text-field": "{hex_id}",
            }}
            symbolPaint={{
                "text-color": "hsl(0, 0%, 37%)",
                "text-halo-color": "hsla(0, 0%, 100%, 0.5)",
                "text-halo-width": 1.5,
            }}
            layerOptions={{
                "minzoom": this.props.minZoom,
            }}
        />)
    }

}