import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class RemocionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Sapo concho remoción</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Especie Sapo"
                            required={true}
                            name="especie_txt"
                            autoFocus={true}
                            value={this.state.record.especie_txt}
                            onChange={this.handleInputChange}/>

                    </div>
                    <div className="row">
                        <FormControl
                            label="Hileras"
                            name="hileras"
                            value={this.state.record.hileras}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Renacuajos"
                            name="renacuajos"
                            value={this.state.record.renacuajos}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Longitud"
                            type="number"
                            step="any"
                            name="longitud"
                            value={this.state.record.longitud}
                            onChange={this.handleInputChange}/>
                            <FormControl
                                label="Latitud"
                                type="number"
                                step="any"
                                name="latitud"
                                value={this.state.record.latitud}
                                onChange={this.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default RemocionForm;