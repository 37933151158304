//@flow
import React, {Component} from "react";
import PuntosCirculares from "./PuntosCirculares";
import ObservacionesCasuales from "./ObservacionesCasuales";
import TransectosLineales from "./TransectosLineales";
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";

class ReptilesList extends Component<SessionProps> {
    constructor(props) {
        super(props);
        this.state = {
            especies: [],
        };
    }

    componentDidMount() {
        this.fetchEspecies();
    }

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('reptiles').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    render() {
        console.log(this.state.especies);
        return (
        <div className="row">
            <div className="col">
                <PuntosCirculares eventoId={this.props.eventoId} especies={this.state.especies}/>
                <TransectosLineales eventoId={this.props.eventoId} especies={this.state.especies}/>
                <ObservacionesCasuales eventoId={this.props.eventoId} especies={this.state.especies}/>
            </div>
        </div>
        )
    }
}

export default withSession(ReptilesList);