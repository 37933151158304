export const ORIGIN_OPTS = [
    {value: 'M', label: 'M'},
    {value: 'N', label: 'N - Nativo'},
    {value: 'E', label: 'E - Endémico'},
];

export const DRNA_OPTS= [
    {value: 'CR', label: 'CR - Peligro crítico'},
    {value: 'EN', label: 'EN - Peligro'},
    {value: 'NE', label: 'NE - No evaluado'},
    {value: 'NL', label: 'NL - No listado'},
    {value: 'VU', label: 'VU'},
    {value: 'DD', label: 'DD - Deficiencia de datos'},
    {value: 'PC', label: 'PC - Peligro crítico'},
];

export const USFWS_OPTS= [
    {value: 'AM', label: 'AM - Amenazado'},
    {value: 'EN', label: 'EN - Peligro'},
    {value: 'T', label:  'T - Threatened'},
];

export const UICN_OPTS= [
    {value: 'CR', label: 'CR - Peligro crítico'},
    {value: 'DD', label: 'DD - Deficiencia de datos'},
    {value: 'EN', label: 'EN - Peligro'},
    {value: 'LC', label: 'LC'},
    {value: 'NE', label: 'NE - No evaluado'},
    {value: 'NL', label: 'NL - No listado'},
    {value: 'NT', label: 'NT'},
    {value: 'VU', label: 'VU'},
];

export const SPECIES_TYPES = [
    {value: 'aves', label: 'Aves'},
    {value: 'reptiles', label: 'Reptiles'},
    {value: 'vegetacion', label: 'Vegetación'},
];
