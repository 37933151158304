import React, {Component, Fragment} from 'react';
import {EMPTY_FEATURE_COLLECTION} from "../../../util/map";
import {Layer, Source} from "react-mapbox-gl";
import {withSession} from "../../../context/SessionContext";

class EventoClusterLayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventos: EMPTY_FEATURE_COLLECTION,
        }
    }

    async componentDidMount() {
        const eventos = await this.props.rest.queryGeoJSON({
            entity: 'evento'
        });
        this.setState({
            eventos: eventos,
        });
    }

    render() {
        return (
            <Fragment>
                <Source id="siembra-cluster" geoJsonSource={{
                    type: 'geojson',
                    data: this.state.eventos,
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                }}/>

                <Layer type="circle" id="cluster-circles"
                       sourceId="siembra-cluster"
                       filter={["has", "point_count"]}
                       paint={{
                           'circle-color': 'green',
                           'circle-stroke-width': 2,
                           'circle-stroke-color': 'white',
                           'circle-opacity': 0.8,
                           'circle-radius': [
                               "step",
                               ["get", "point_count"],
                               20, 100,
                               30, 750,
                               40
                           ]
                       }}/>

                <Layer type="symbol" id="cluster-text" sourceId="siembra-cluster"
                       filter={["has", "point_count"]}
                       paint={{
                           "text-color": 'white'
                       }}
                       layout={{
                           "text-field": "{point_count_abbreviated}",
                           "text-size": [
                               "step", ["get", "point_count"],
                               12, 10,
                               14, 20,
                               16, 40,
                               18, 80,
                               20, 160,
                               22, 320,
                               24, 640,
                               26, 1280,
                               28

                           ]
                       }}/>

                <Layer type="circle" id="point-circles"
                       sourceId="siembra-cluster"
                       filter={["!has", "point_count"]}
                       paint={{
                           'circle-color': 'green',
                           "circle-stroke-width": 2,
                           "circle-stroke-color": 'white',
                           'circle-radius': 5
                       }}/>
            </Fragment>
        );
    }
}

export default withSession(EventoClusterLayer);