//@flow
import React, {Component, Fragment} from 'react';
import {withSession} from "../../../context/SessionContext";
import type {FeatureCollection} from "../../../util/rest-arcgis";
import RestArcGIS from "../../../util/rest-arcgis";
import {GeoJSONLayer} from "react-mapbox-gl";
import {jenks} from "../../../util/jenks";
import {Button, ButtonGroup} from "reactstrap";
import HexPopup from "./HexPopup";

const GREENS = ['#f7fcf5', '#e5f5e0', '#c7e9c0', '#a1d99b',
    '#74c476', '#41ab5d', '#238b45', '#006d2c', '#00441b'];

const STATS = new Map([
    ['evt', 'Eventos'],
    ['esp', 'Abundancia'],
    ['div', 'Diversidad']
]);

const PROTOCOLS = new Map([
    ['total', {title: 'Total', hasEspecie: true}],
    ['aves', {title: 'Aves', hasEspecie: true}],
    ['vegetacion', {title: 'Vegetación', hasEspecie: true}],
    ['reptil', {title: 'Reptiles', hasEspecie: true}],
    ['humedal', {title: 'Humedales', hasEspecie: true}],
    ['playa', {title: 'Playas', hasEspecie: false}],
    ['cuenca', {title: 'Cuencas', hasEspecie: false}],
]);

type Props = {
    before?: string,
    map: Object,
    rest: RestArcGIS,
    refDataRest: RestArcGIS,
}

class HexStatsLayer extends Component<Props> {

    constructor(props) {
        super(props);
        this.state = {
            currStat: 'esp',
            currProtocol: 'total',
            stops: [[1, GREENS[0]]],
            ranges: [],
            hexStats: {type: 'FeatureCollection', features: []},
            legendDisplay: 'none',
            popup: null,
        };
    }

    async componentDidMount() {

        const hexStats = await this.props.refDataRest.queryGeoJSON({entity: 'hexagonos_stats_v',});

        let stopsDataObj = this.getStops(hexStats, this.state.currStat, this.state.currProtocol);

        this.setState(prevState => ({
            hexStats: hexStats,
            stops: stopsDataObj.stops,
            ranges: stopsDataObj.ranges,
            legendDisplay: 'block'
        }));

        // Change the cursor to a pointer when the mouse is over the places layer.
        this.props.map.on('mouseenter', 'mdv-hex-stats-fill', () => {
            this.props.map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        this.props.map.on('mouseleave', 'mdv-hex-stats-fill', () => {
            this.props.map.getCanvas().style.cursor = '';
        });
    }

    onClickStat = (e) => {
        const newStat = e.target.value;
        const stopsData = this.getStops(this.state.hexStats, newStat, this.state.currProtocol);

        this.setState({
            currStat: newStat,
            stops: stopsData.stops,
            ranges: stopsData.ranges,
        });

    };

    onClickProtocol = (e) => {
        const newProtocol = e.target.value;
        const stopsData = this.getStops(this.state.hexStats, this.state.currStat, newProtocol);

        this.setState({
            currProtocol: newProtocol,
            stops: stopsData.stops,
            ranges: stopsData.ranges,
        });

    };

    onClickHex = (evt) => {
        if(this.state.popup) {
            this.setState({
                popup: null
            });
        }

        this.setState({
            popup: (
                <HexPopup coordinates={evt.lngLat}
                          feature={evt.features[0]}
                          onClickClose={() => this.setState({popup: null})}
                />
            )
        });
    };

    getStops(hexStats: FeatureCollection, stat, protocol) {
        const currStatProp = [stat, protocol].join('_');
        const features = hexStats.features;
        let sortableValuesArr = new Array(features.length);
        for (let i = 0; i < features.length; i++) {
            sortableValuesArr[i] = features[i].properties[currStatProp];
        }

        const jenksObj = jenks(sortableValuesArr, GREENS.length);

        let stops = [], ranges = [];
        for (let i = 0; i < jenksObj.length - 1; i++) {
            stops.push([jenksObj[i], GREENS[i]]);
            ranges.push(`${jenksObj[i]} - ${jenksObj[i + 1]}`);
        }

        return {
            stops: stops,
            ranges: ranges,
        };
    }

    render() {

        const protocolKeys = Array.from(PROTOCOLS.keys());
        const statKeys = Array.from(STATS.keys());

        return (
            <Fragment>
                <div id='map-legend' className='mdv-ctrl mdv-ctrl-legend' style={{display: this.state.legendDisplay}}>
                    <strong>{STATS.get(this.state.currStat)}<br/>{PROTOCOLS.get(this.state.currProtocol).title}</strong>
                    <table>
                        <tbody>
                        {this.state.stops.map((stop, idx) => {
                            return (
                                <tr key={idx}>
                                    <td><span style={{backgroundColor: GREENS[idx]}}/></td>
                                    <td style={{textAlign: 'left'}}>{this.state.ranges[idx]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className="container" style={{position: 'absolute', right: '10px', width: '10em'}}>
                    <div id='stat-control' className="row  justify-content-end">
                        <ButtonGroup vertical className="col-12 mdv-ctrl mdv-ctrl-layer"
                                     style={{position: 'relative'}}>
                            {statKeys.map((k) => {
                                return <Button key={`stat-checkbox-${k}`} value={k}
                                               active={k === this.state.currStat}
                                               disabled={!PROTOCOLS.get(this.state.currProtocol).hasEspecie && k !== 'evt'}
                                               onClick={this.onClickStat} size="sm">
                                    {STATS.get(k)}
                                </Button>
                            })}
                        </ButtonGroup>
                        <div className="w-100"/>
                        <ButtonGroup vertical className="col-12 mdv-ctrl mdv-ctrl-layer"
                                     style={{position: 'relative', marginTop: '10px'}}>
                            {protocolKeys.map((k) => {
                                return <Button key={`protocol-checkbox-${k}`} value={k}
                                               active={k === this.state.currProtocol}
                                               disabled={!PROTOCOLS.get(k).hasEspecie && this.state.currStat !== 'evt'}
                                               onClick={this.onClickProtocol} size="sm">
                                    {PROTOCOLS.get(k).title}
                                </Button>
                            })}
                        </ButtonGroup>
                    </div>
                </div>
                <GeoJSONLayer
                    id="mdv-hex-stats"
                    data={this.state.hexStats}
                    fillPaint={{
                        'fill-opacity': 0.5,
                        'fill-color': {
                            'property': [this.state.currStat, this.state.currProtocol].join('_'),
                            'type': 'interval',
                            'default': GREENS[0],
                            'stops': this.state.stops
                        }
                    }}
                    fillOnClick={this.onClickHex}
                    before={this.props.before}
                />
                {this.state.popup}
            </Fragment>
        );
    }
}

export default withSession(HexStatsLayer);