//@flow
import booleanWithin from '@turf/boolean-within'
import type {Feature, Geometry} from "./rest-arcgis";

export function within(latitud: number, longitud: number, hexGeoJson: Geometry | Feature): boolean {
    if (!hexGeoJson || !latitud || !longitud) {
        return false;
    }
    const locationGeoJson = {
        type: 'Point', coordinates: [
            longitud,
            latitud
        ]
    };
    return booleanWithin(locationGeoJson, hexGeoJson)
}