import React, {Component, Fragment} from "react";
import Indicador1Form from "./Indicador1Form";
import Indicador2Form from "./Indicador2Form";
import SueloForm from "./SueloForm";
import DatosAdicionalesForm from "./DatosAdicionalesForm";
import {withSession} from "../../context/SessionContext";

class HumedalesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            recordType: props.match.params.recordType,
            record: {},
            especies: []
        };
    }

    async componentDidMount() {
        this.fetchEspecies();
        if (this.state.recordId) {
            await this.fetchRecord(this.state.recordId, this.getRecordType());
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }));
    }

    fetchRecord = async (id, type) => {
        const data = await this.props.rest.get({
            entity: type,
            id: id,
        });
        this.setState({
            record: data
        })
    };

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('vegetacion').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record,
                [name]: value
            }
        }));
    };

    getRecordType = () => {
        if (this.state.recordType === 'indicador1') return "humedal_indicador_1";
        if (this.state.recordType === 'indicador2') return "humedal_indicador_2";
        if (this.state.recordType === 'suelo') return "humedal_suelo";
        if (this.state.recordType === 'datos-adicionales') return "humedal";
    };

    handleSubmit = async (event) => {
        let especieTxt = this.state.record.especie_txt;
        if (this.state.record.especie_id && this.state.record.especie_id > 0) {
            especieTxt = this.props.especieService.getSelectedSpecieName(this.state.especies, this.state.record.especie_id)
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId,
                especie_txt: especieTxt,
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: this.getRecordType(),
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if (data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });
        event.preventDefault();
    };

    render() {
        let form = null;
        if (this.state.recordType === 'indicador1')
            form = (
                <Indicador1Form
                    record={this.state.record}
                    especies={this.state.especies}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        if (this.state.recordType === 'indicador2')
            form = (
                <Indicador2Form
                    record={this.state.record}
                    especies={this.state.especies}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        if (this.state.recordType === 'suelo')
            form = (
                <SueloForm
                    record={this.state.record}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        if (this.state.recordType === 'datos-adicionales')
            form = (
                <DatosAdicionalesForm
                    record={this.state.record}
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                />
            );
        return (
            <Fragment>
                {/*<div>{JSON.stringify(this.state.record)}</div>*/}
                <div>{form}</div>
            </Fragment>
        );
    }
}

export default withSession(HumedalesEdit);