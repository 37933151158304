import React, {PureComponent} from "react";
import BackButton from "../BackButton";
import {withSession} from "../../context/SessionContext";
import Form from "../Form";
import FormControl from "../FormControl";
import type {SessionProps} from "../../context/SessionContext";
import EspecieSelect from "../EspecieSelect";
import type {EspecieType} from "../../util/service";

const ESTRATOS = [
    'Árboles',
    'Plántulas y Arbustos',
    'Herbáceas',
    'Enredaderas Leñoas',
];

type Props = {
    ...SessionProps,
    record: Object,
    especies: EspecieType[],
    handleSubmit: () => void,
    handleInputChange: () => void,
}

class Indicador1Form extends PureComponent<Props> {

    render() {
        return (
            <div className="container">
                <Form submit={this.props.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Humedales - Indicador 1</h4>
                        </div>
                    </div>
                    <div className="row">
                        <EspecieSelect
                            onChange={this.props.handleInputChange}
                            value={this.props.record.especie_id}
                            required={true}
                            autoFocus={true}
                            name="especie_id"
                            options={this.props.especies}
                        />
                        <FormControl
                            label="Escriba especie si no aparece en la lista"
                            type="text"
                            name="especie_txt"
                            col={6}
                            required={this.props.record.especie_id && Number(this.props.record.especie_id) === -1}
                            disabled={Number(this.props.record.especie_id) !== -1}
                            value={this.props.record.especie_txt}
                            onChange={this.props.handleInputChange}/>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Estrato</label>
                            <select className="form-select form-control"
                                    name="estrato"
                                    value={this.props.record.estrato}
                                    required={true}
                                    onChange={this.props.handleInputChange}>
                                <option/>
                                {ESTRATOS.map(estrato => (
                                    <option key={estrato} value={estrato}>{estrato}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withSession(Indicador1Form);