//@flow
import React, {Component, Fragment} from "react";
import BackButton from "../BackButton";
import EventoTipo from "../EventoTipo";
import {withSession} from '../../context/SessionContext';
import Form from "../Form";
import FormControl from "../FormControl";
import {Fmts, formatDate, parseISODate} from "../../util/format";
import CoordViewMapa from "../mdv/mapa/CoordViewMap";
import {within} from '../../util/geom';
import {getHexagono, withEvento} from "./EventoUtils";
import {FaSave} from "react-icons/fa";
import {SitioEdit} from "./SitioEdit";
import {ClimaEdit} from "./ClimaEdit";
import {countDecimals} from "../../util/util";
import type {EventoProps} from "./EventoUtils";
import type {SessionProps} from "../../context/SessionContext";
import type {IPoint} from "@esri/arcgis-rest-types";
import type {SaveOptions} from '../../util/rest-arcgis'

const HEX_ID_MIN = 292;
const HEX_ID_MAX = 1446;

type Props = {
    ...EventoProps,
    ...SessionProps,
}

class EventoEdit extends Component<Props> {
    static status = [
        "Nominación",
        "Revisión",
        "Aprobación",
        "Completado",
        "Eliminado"
    ];

    constructor(props) {
        super(props);

        let isCoordsInvalid = false;
        const evento = this.props.evento;
        if (this.props.hexagono && evento && evento.latitud && evento.longitud) {
            isCoordsInvalid = !within(evento.latitud, evento.longitud, this.props.hexagono)
        }

        let isHexIdInvalid = false;
        if (evento.hex_id && (evento.hex_id < HEX_ID_MIN || evento.hex_id > HEX_ID_MAX
            || (evento.hex_id >= HEX_ID_MIN && evento.hex_id <= HEX_ID_MAX && !props.hexagono))) {
            isHexIdInvalid = true;
        }

        const stateEvento = Object.assign({
            tipo_evento: props.match.params.eventoType === 'SAPO_CONCHO' ? 'SAPO_CONCHO' : 'MDV',
            tipo_mdv: props.match.params.eventoType === 'SAPO_CONCHO' ? null : props.match.params.eventoType,
            estatus: 'Nominación',
            vegetacion_arbustos: 0,
            vegetacion_cactus: 0,
            vegetacion_gramineas: 0,
            vegetacion_helechos: 0,
            vegetacion_lenosa: 0,
            vegetacion_otro: 0,
        }, evento);

        for (const name of Object.keys(stateEvento)) {
            if (stateEvento[name] === null) {
                stateEvento[name] = '';
            }
        }

        this.state = {
            isCoordsInvalid: isCoordsInvalid,
            isHexIdInvalid: isHexIdInvalid,
            hexagono: this.props.hexagono,
            record: stateEvento,
        };

        this.fechaRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.record.fecha) {
            this.fechaRef.current.value = formatDate(this.state.record.fecha, Fmts.ISO_DATE)
        }
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    };

    handleHexChange = async event => {
        const target = event.target;
        if (!target.validity.valid) {
            return;
        }

        const hexId = target.value;
        let hex = null;
        if (hexId) {
            hex = await getHexagono(this.props.refDataRest, hexId);
        }

        const evento = this.state.record;

        this.setState({
            hexagono: hex,
            isHexIdInvalid: !hex,
            isCoordsInvalid: hex && evento.latitud && evento.longitud && !within(evento.latitud, evento.longitud, hex)
        });
    };

    handleLatLngChange = async event => {
        const target = event.target;
        if (!target.validity.valid) {
            return;
        }
        const evento = this.state.record;
        const lat = target.name === 'latitud' ? event.value : evento.latitud;
        const lng = target.name === 'longitud' ? event.value : evento.longitud;

        if (lat && lng && this.state.hexagono) {
            this.setState({
                isCoordsInvalid: !within(lat, lng, this.state.hexagono)
            });
            return;
        }

        this.setState({
            isCoordsInvalid: false,
        })

    };

    handleSubmit = async event => {
        const payload = Object.assign({}, this.state.record, {
            fecha: parseISODate(this.fechaRef.current.value).getTime()
        });
        for (const name of Object.keys(payload)) {
            if (payload[name] === '') {
                payload[name] = null;
            }
        }

        let geom: IPoint = null;
        if(payload.latitud && payload.longitud) {
            geom = {
                x: payload.longitud,
                y: payload.latitud,
            }
        }

        let files = document.querySelector("input[name='foto_paisaje'").files;

        let options: SaveOptions = {
            action: this.props.evento.GlobalID ? 'UPDATE' : 'INSERT',
            entity: 'evento',
            attributes: payload,
            attachment: files.length > 0 ? files[0] : null,
            geometry: geom,
        };

        const response = await this.props.rest.save(options);

        if (options.action === 'INSERT' && payload.tipo_mdv === 'mdv-cuenca') {
            await this.props.rest.save({
                action: 'INSERT',
                entity: 'cuenca',
                attributes: {
                    evento_GlobalID: response.globalId
                }
            })
        }

        if (response.success) {
            this.props.history.push(this.resolveReturnPath(response.globalId));
        }

        event.preventDefault();
    };

    resolveReturnPath(eventoId) {
        let tipoEvento = this.state.record.tipo_evento === 'SAPO_CONCHO' ? 'sapo-concho' : 'evento';
        return `/${tipoEvento}/${eventoId}`
    }

    render() {

        const evento = this.state.record;
        return (
            <div className="container">
                <Form id="form" submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-9">
                            <h3>Evento - <EventoTipo tipoEvento={evento.tipo_mdv || evento.tipo_evento}/></h3>
                        </div>
                        <div className="col-3 text-right">
                            <BackButton btnClass="success"/>
                        </div>
                    </div>
                    {/*<div>{JSON.stringify(this.state.eventoId)}</div>*/}

                    <div className="row">
                        <FormControl
                            label="Nombre del evento"
                            name="nombre"
                            col={8}
                            value={evento.nombre}
                            onChange={this.handleInputChange}
                            required={true}
                            autoFocus={true}/>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Status del record <i className="text-danger">*</i></label>
                                <select className="form-select form-control"
                                        name="estatus"
                                        value={evento.estatus}
                                        onChange={this.handleInputChange}>
                                    <option value=""/>
                                    {EventoEdit.status.map(p => (
                                        <option key={p} value={p}>{p}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group mb-3 col-4">
                            <label htmlFor="fecha">Fecha (dd/mm/aaaa) <i className="text-danger">*</i></label>
                            <input ref={this.fechaRef}
                                   title="Escoja la fecha del evento"
                                   id="fecha"
                                   name="fecha"
                                   className="form-control"
                                   type="date"/>
                            <div className='invalid-feedback'>&nbsp;</div>
                        </div>
                        <FormControl
                            label="Hora comienzo"
                            col={4}
                            type="time"
                            name="hora_comienzo"
                            value={evento.hora_comienzo}
                            onChange={this.handleInputChange}
                            placeholder="HH:MM"/>
                        <FormControl
                            label="Hora final"
                            col={4}
                            type="time"
                            name="hora_final"
                            value={evento.hora_final}
                            onChange={this.handleInputChange}
                            placeholder="HH:MM"/>
                    </div>

                    {this.renderErrorMsg()}

                    <div id="hex-validation" className="row">
                        <FormControl
                            ref={this.hexIdRef}
                            label="ID Hexágono"
                            col={4}
                            type="number"
                            name="hex_id"
                            min={HEX_ID_MIN}
                            max={HEX_ID_MAX}
                            value={evento.hex_id}
                            onChange={(e) => {
                                this.handleInputChange(e);
                                this.handleHexChange(e);
                            }}
                            onBlur={this.handleHexChange}
                            required={true}/>
                        <FormControl
                            label="Latitud en grados decimales"
                            col={4}
                            type="number"
                            step="any"
                            name="latitud"
                            min={17}
                            max={19}
                            value={evento.latitud}
                            onChange={(e) => {
                                this.handleInputChange(e);
                                this.handleLatLngChange(e);
                            }}
                            onBlur={this.handleLatLngChange}
                            required={true}
                            placeholder="18.123456"/>
                        <FormControl
                            label="Longitud en grados decimales"
                            col={4}
                            type="number"
                            step="any"
                            min={-68}
                            max={-64}
                            name="longitud"
                            value={evento.longitud}
                            onChange={(e) => {
                                this.handleInputChange(e);
                                this.handleLatLngChange(e);
                            }}
                            onBlur={this.handleLatLngChange}
                            required={true}
                            placeholder='-66.123456'/>
                    </div>

                    {evento.latitud && countDecimals(Number(evento.latitud)) > 2
                    && evento.longitud && countDecimals(Number(evento.longitud)) > 2 &&
                    <Fragment>
                        <CoordViewMapa height={200}
                                       lat={evento.latitud}
                                       lng={evento.longitud}
                        />
                        <br/>
                    </Fragment>
                    }

                    <SitioEdit
                        evento={evento}
                        onInputChange={this.handleInputChange}/>

                    <ClimaEdit
                        evento={evento}
                        onInputChange={this.handleInputChange}/>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Comentarios</label>
                                <textarea className="wpt-wysiwyg wp-editor-area form-control" rows="5"
                                          autoComplete="off"
                                          name="comentarios"
                                          value={evento.comentarios}
                                          onChange={this.handleInputChange}/>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <button type="submit"
                                    className="btn btn-primary form-submit">
                                <FaSave/> Guardar
                            </button>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
                <br/>
            </div>
        );
    }

    renderErrorMsg = () => {

        const hexNotExists = (
            this.state.isHexIdInvalid &&
            <div className="row">
                <div className="col">
                    <p className="alert alert-danger">{`El hexágono ${this.state.record.hex_id} no existe. Favor de corregir el número de hexágono`}</p>
                </div>
            </div>
        );


        let isValidCoords = (
            this.state.isCoordsInvalid &&
            <div className="row">
                <div className="col">
                    <p className="alert alert-danger">{`Las coordenadas no concuerdan con el hexágono ${this.state.record.hex_id}. Favor de verificar.`}</p>
                </div>
            </div>
        );

        return (
            <Fragment>
                {hexNotExists}
                {isValidCoords}
            </Fragment>
        );
    };

}

export default withSession(withEvento(EventoEdit));
