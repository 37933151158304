import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";
import {withSession} from "../../context/SessionContext";
import type {SessionProps} from "../../context/SessionContext";
import EspecieSelect from "../EspecieSelect";

class AvesEdit extends Component<SessionProps> {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.match.params.eventoId,
            recordId: props.match.params.recordId,
            especies: [],
            record: {},
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(prevState => ({
            record: {
                ...prevState.record, [name]: value
            }
        }));
    };

    handleSubmit = (event) => {
        let especieTxt = this.state.record.especie_txt;
        if (this.state.record.especie_id && this.state.record.especie_id > 0) {
            especieTxt = this.props.especieService.getSelectedSpecieName(this.state.especies, this.state.record.especie_id)
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId,
                especie_txt: especieTxt,
            }
        }), () => {
            let options = {
                action: this.state.recordId ? 'UPDATE' : 'INSERT',
                entity: 'aves',
                attributes: this.state.record
            };

            this.props.rest.save(options).then(data => {
                if(data.success) {
                    this.props.history.push(`/evento/${this.state.eventoId}`);
                }
            });
        });

        event.preventDefault();
    };

    componentDidMount() {
        this.fetchEspecies();
        if (this.state.recordId) {
            this.fetchRecord(this.state.recordId);
        }
        this.setState(prevState => ({
            record: {
                ...prevState.record,
                evento_GlobalID: this.state.eventoId
            }
        }));
    }

    async fetchRecord(id) {
        const data = await this.props.rest.get({
            entity: 'aves',
            id: id,
        });
        this.setState({
            record: data,
        });
    }

    fetchEspecies = () => {
        this.props.especieService.fetchEspecies('aves').then((data) => {
            this.setState({
                especies: data.records,
            });
        });
    };

    render() {
        return (
            <div className="container">
                <Form id="form" submit={this.handleSubmit}>

                    <div className="row">
                        <div className="col">
                            <h4>Datos compilados de aves</h4>
                        </div>
                    </div>
                    <div className="row">
                        <EspecieSelect
                            onChange={this.handleInputChange}
                            value={this.state.record.especie_id}
                            required={true}
                            autoFocus={true}
                            name="especie_id"
                            options={this.state.especies}
                        />
                        <FormControl
                            label="Escriba especie si no aparece en la lista"
                            type="text"
                            name="especie_txt"
                            required={this.state.record.especie_id && Number(this.state.record.especie_id) === -1}
                            disabled={Number(this.state.record.especie_id) !== -1}
                            value={this.state.record.especie_txt}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row" style={{marginTop: 1 + 'em'}}>
                        <div className="col">
                            <h5>Tiempo</h5>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="0-7 (≤25)"
                            col={6}
                            type="number"
                            name="tiempo_menor07"
                            value={this.state.record.tiempo_menor07}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="0-7 (≥25)"
                            type="number"
                            col={6}
                            name="tiempo_mayor07"
                            value={this.state.record.tiempo_mayor07}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="8-10 (≤25)"
                            type="number"
                            col={6}
                            name="tiempo_menor8_10"
                            value={this.state.record.tiempo_menor8_10}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="8-10 (≥25)"
                            type="number"
                            col={6}
                            name="tiempo_mayor8_10"
                            value={this.state.record.tiempo_mayor8_10}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="5 (≤25)"
                            type="number"
                            col={6}
                            name="tiempo_menor5"
                            value={this.state.record.tiempo_menor5}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="5 (≥25)"
                            type="number"
                            col={6}
                            name="tiempo_mayor5"
                            value={this.state.record.tiempo_mayor5}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Ocurrencia escuchada"
                            type="text"
                            col={6}
                            name="ocurrencia_escuchada"
                            value={this.state.record.ocurrencia_escuchada}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Ocurrencia observada"
                            type="text"
                            col={6}
                            name="ocurrencia_observada"
                            value={this.state.record.ocurrencia_observada}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Punto de conteo"
                            type="number"
                            col={6}
                            name="punto_conteo_txt"
                            value={this.state.record.punto_conteo_txt}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Comportamiento"
                            type="text"
                            col={6}
                            name="comportamiento"
                            value={this.state.record.comportamiento}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-4 text-right">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                            &nbsp;<BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default withSession(AvesEdit);