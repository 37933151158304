import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import MdvApp from './MdvApp'

ReactDOM.render(
    <MdvApp/>, document.getElementById('root')
);


registerServiceWorker();
