//@flow
import React from 'react';
import {Doughnut, defaults} from "react-chartjs-2";

defaults.global.defaultFontFamily = 'Avenir-Roman';
// defaults.global.defaultFontSize = 12;

export function LandUseChart(props) {

    const data = {
        datasets: [{
            data: [
                props.evento.conservacion_agricultura,
                props.evento.conservacion_conservacion,
                props.evento.conservacion_ganaderia,
                props.evento.conservacion_plantacion,
                props.evento.conservacion_otro,
                props.evento.construido_carretera,
                props.evento.construido_industrial,
                props.evento.construido_residencial,
                props.evento.construido_recreativo,

            ],
            backgroundColor: [
                'rgba(111, 100, 86, 0.8)',
                'rgba(205, 220, 73, 0.8)',
                'rgba(203, 126, 148, 0.8)',
                'rgba(233, 75, 48, 0.8)',
                'rgba(254, 230, 89, 0.8)',
                'rgba(161, 207, 221, 0.8)',
                'rgba(147, 223, 108, 0.8)',
                'rgba(251, 71, 243, 0.8)',
                'rgba(217, 226, 78, 0.8)',
            ],
            label: 'Dataset 1'
        }],
        labels: [
            'Conservación agricultura',
            'Conservación conservación',
            'Conservación ganadería',
            'Conservación plantación',
            'Conservación otro',
            'Construído carretera',
            'Construído industrial',
            'Construído residencial',
            'Construído recreativo',
        ]
    };
    const options = {
        responsive: true,
        legend: {
            position: 'right',
        },
        title: {
            display: true,
            text: 'Uso del terreno'
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };

    return (
        <Doughnut data={data} options={options}/>
    );
}