//@flow

import RestArcGIS from "./rest-arcgis";

export type Especie = {
    OBJECTID: number,
    species_id: number,
    genus_species: string,
    common_name: string,
}

export type EspecieType = 'aves' |  'reptiles' | 'vegetacion' ;

export default class EspecieService {
    constructor(refDataRest: RestArcGIS) {
        this.refDataRest = refDataRest;
    }

    async fetchEspecies(especieType: EspecieType) {
        return this.refDataRest.query({
            entity: 'species',
            where: `species_type = '${especieType}' and active <> 0`,
            outFields: ['OBJECTID', 'species_id', 'common_name', 'genus_species']
        },);
    }

    getSelectedSpecieName(especies: Especie[], especieObjectId: number): string {
        let especie;
        if (especieObjectId <= 0) {
            return '';
        }

        especie = especies.find((e) => {
            //convert int to string, then compare
            return `${e.species_id}` === `${especieObjectId}`;
        });

        if(!especie) {
            return '';
        }

        let optLabel = especie.common_name;
        if (especie.common_name !== especie.genus_species) {
            optLabel += ` (${especie.genus_species})`
        }
        return optLabel;
    }

}