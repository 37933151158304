import React, {Component} from 'react';
import mapaImg from '../../../images/Mapa-de-mapa-de-vida.jpg'


class MdvInicio extends Component {


    render() {
        return (
            <div className="container">

                <h2>Mapa de Vida</h2>
                <p>Los seres humanos dependemos completamente de los servicios que nos proveen los ecosistemas. Tomar
                    decisiones sensibles en cuanto a la protección de ecosistemas y el uso de terrenos nos ayudara a
                    garantizar esos servicios esenciales para nuestra subsistencia. Trazar un Mapa de Vida sera
                    indispensable para guiar nuestros pasos. Tu participación sera esencial.</p>
                <h2>¿Que es el Mapa de Vida?</h2>
                <p>El Mapa de Vida es una iniciativa liderada por el Fideicomiso de Conservación de Puerto Rico que se
                    enfoca en conservar la diversidad biológica en las islas de Puerto Rico. Consiste de una herramienta
                    que integra diversas bases de datos y mapas, con el fin de proveer una visión amplia del estado<br/>
                    de los ecosistemas en Puerto Rico y ofrecer apoyo en la toma de decisiones relacionadas a la
                    conservación, el uso de terrenos y el desarrollo. La información contenida en el Mapa de Vida se
                    valida a través de actividades de campo que integran la participación de ciudadanos en la
                    recopilación de datos ecológicos.</p>
                <h2>¿Cómo funciona?</h2>
                <p>El Mapa de Vida divide a Puerto Rico en cinco regiones, basándose en cinco de las principales cuencas
                    hidrográficas. Esas regiones, a su vez, se subdividen, para crear un sistema de hexágonos, o formas
                    geométricas de 6 partes iguales, que en este caso medirán aproximadamente 509 cuerdas (200
                    hectáreas) en tamaño. Las actividades de campo se enfocan en recoger datos ecológicos en distintos
                    hexágonos de cada region, con el fin de determinar Ia salud de los ecosistemas y los servicios que
                    nos proveen.</p>
                <p>Los datos recopilados servirán para actualizar y validar el Mapa de Vida a tiempo real. Para el año
                    2015, el Fideicomiso de Conservación espera haber reclutado a mas de 9,000 voluntarios para Mapa de
                    Vida y haber cubierto al menos 25% del total de hexágonos en las islas de Puerto Rico, que serían
                    1,250 de 5,000 en total.</p>
                <p>
                    <img className="img-fluid"
                        src={mapaImg}
                        alt="Diagrama de mapa de vida"/></p>
                <blockquote><p>El Mapa de Vida servirá como una herramienta inteligente que ofrecerá apoyo tanto al
                    sector público como al privado en la toma de decisiones acerca del uso de terrenos y el desarrollo
                    en nuestras islas.</p>
                    <footer>Para La Naturaleza</footer>
                </blockquote>
            </div>
        );
    }
}

export default MdvInicio;



