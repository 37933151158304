//@flow
import React, {Component, Fragment} from "react";
import type {SessionProps} from "../../../context/SessionContext";
import {withSession} from '../../../context/SessionContext'
import {Fmts, formatDate} from "../../../util/format";
import HexMap from "./HexMap";
import withPermalink from "../../withPermalink";
import PageNav from "../../pagenav/PageNav";
import HexStatsTable from "../mapa/HexStatsTable";
import {FaFileExport} from 'react-icons/fa';
import HexStatsTableHeader from "../mapa/HexStatsTableHeader";


class HexPerfil extends Component<SessionProps> {

    constructor(props) {
        super(props);
        this.hexId = props.match.params.hexId;
        const plinkData = this.props.permalinkData;
        this.state = {
            page: {
                limit: 15,
                offset: plinkData && plinkData.page ? Number(plinkData.page) : 1,
                totalRecords: 0
            },
            records: [],
            allRecords: [],
            hexStats: null,
        }
    }

    async componentDidMount() {
        this.fetchRecords(this.state.page.offset);
        this.fetchAllRecords();
        const hexStats = await this.props.refDataRest.queryGeoJSON({
            entity: 'hexagonos_stats_v',
            where: `hex_id = ${this.props.match.params.hexId}`
        });
        this.setState({
            hexStats: hexStats.features[0],
        })
    }

    fetchRecords = async (pageNumber) => {
        const data = await this.props.refDataRest.query({
            entity: 'evento_especie_v',
            _pageNumber: pageNumber,
            outFields: [
                'unique_id', 'evento_OBJECTID',
                'especie_txt', 'genus_species', 'common_name',
                'fecha', 'latitud', 'longitud', 'hex_id', 'especie_id',
                'nombre', 'species_type', 'tipo_mdv',
                'evento_GlobalID', 'especies_count',
            ],
            where: `hex_id = ${this.props.match.params.hexId}`,
            orderByFields: 'fecha desc'
        });
        this.setState({
            page: {limit: data.limit, offset: pageNumber, totalRecords: data.totalRecords},
            records: data.records,
        });
    };

    fetchAllRecords = async () => {
        const data = await this.props.refDataRest.query({
            entity: 'evento_especie_v',
            outFields: '*',
            where: `hex_id = ${this.props.match.params.hexId}`,
            orderByFields: 'fecha desc'
        });
        this.setState({
            allRecords: data.records,
        });
    };

    handlePaginationChange = async (pageNumber) => {
        this.fetchRecords(pageNumber);
        if (this.props.updatePermalink) {
            this.props.updatePermalink({
                page: '' + pageNumber
            })
        }
    };


    convertEspeciesToCSV = (allRecords): string => {
        const headers = [
            'evento_OBJECTID', 'evento_GlobalID', 'especie_txt', 'genus_species', 'common_name',
            'fecha', 'hora_comienzo', 'hora_final', 'latitud', 'longitud', 'hex_id', 'especie_id',
            'nombre', 'species_type', 'tipo_mdv',
            'especies_count',
            'estatus',
            'nombre',
            'ubicacion',
            'propietario',
            'conservacion_agricultura',
            'conservacion_conservacion',
            'conservacion_ganaderia',
            'conservacion_plantacion',
            'conservacion_otro',
            'cobertura_bosque',
            'cobertura_humedal',
            'cobertura_pastizal',
            'cobertura_otro',
            'construido_carretera',
            'construido_industrial',
            'construido_residencial',
            'construido_recreativo',
            'vegetacion_arbustos',
            'vegetacion_cactus',
            'vegetacion_gramineas',
            'vegetacion_helechos',
            'vegetacion_lenosa',
            'vegetacion_otro',
            'cuerpo_agua',
            'descripcion_paisaje',
            'descripcion_foto',
            'temperatura',
            'velocidad_viento',
            'dir_viento',
            'humedad',
            'cob_nubes',
            'precipitacion',
            'presion_barometrica',
            'condicion_habitat',
            'elevacion',
            'creationdate_mdv1',
            'creator_mdv1',
            'editdate_mdv1',
            'editor_mdv1',
        ];
        let csvStr = headers.join(',') + '\r\n';
        for (const record of allRecords) {
            const values = [];
            for (const header of headers) {
                let val: *;
                if (record[header] === null) {
                    val = '';
                } else if (header === 'fecha') {
                    val = formatDate(record[header], Fmts.ISO_DATE);
                } else if (['creationdate_mdv1', 'editdate_mdv1'].indexOf(header) !== -1) {
                    val = formatDate(record[header], Fmts.ISO_TIMESTAMP);
                } else {
                    val = String(record[header]);
                    if ([',', '"'].indexOf(val) !== -1) {
                        val = String(record[header]).replace('"', '""');
                        val = '"' + val + '"';
                    }
                }
                values.push(val);
            }
            csvStr += values.join(',') + '\r\n';
        }
        return csvStr;
    };

    render() {
        return (
            <div className="container">
                <h2>Hexágono #{this.hexId}</h2>

                <h3>Mapa de eventos</h3>
                <HexMap height={400} hexId={this.hexId}/>
                <br/>

                {this.state.hexStats &&
                <Fragment>
                    <HexStatsTableHeader
                        hexId={this.hexId}
                        hexStats={this.state.hexStats.properties}
                    />
                    <HexStatsTable
                        hexStats={this.state.hexStats.properties}
                    />
                </Fragment>
                }

                <br/>

                <div className="row" style={{marginTop: 2 + 'em', marginBottom: 1 + 'em'}}>
                    <div className="col-6">
                        <h3>Especies observadas</h3>
                    </div>
                    {this.state.allRecords && this.state.allRecords.length > 0 &&
                    <div className="col-6 text-right">
                        <a className="btn btn-primary"
                           href={URL.createObjectURL(new Blob([this.convertEspeciesToCSV(this.state.allRecords)], {type: 'text/csv;charset=utf-8;'}))}
                           download={`hexagono_${this.hexId}_especies.csv`}><FaFileExport/> Exportar</a>
                    </div>
                    }
                </div>
                <div className="table-responsive mb-3">
                    <table className="table table-hover table-sm table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Evento ID</th>
                            <th>Fecha</th>
                            <th>Evento</th>
                            <th>Protocolo</th>
                            <th>Especie</th>
                            <th>Conteo</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.records && this.state.records.map(record => {
                                let speciesText = record.genus_species || record.especie_txt;
                                if (record.common_name && record.common_name !== record.genus_species) {
                                    speciesText = `${record.common_name} (${speciesText})`;
                                }

                                return (
                                    <tr key={'record-' + record.unique_id}>
                                        <td>{record.evento_OBJECTID}</td>
                                        <td>{formatDate(record.fecha, Fmts.DATE)}</td>
                                        <td>{record.nombre}</td>
                                        <td style={{textTransform: 'capitalize'}}>{record.tipo_mdv.replace('mdv-', '')}</td>
                                        <td>{speciesText}</td>
                                        <td>{record.especies_count}</td>
                                    </tr>
                                );
                            }
                        )}
                        </tbody>
                    </table>
                </div>
                <PageNav
                    activePage={this.state.page.offset}
                    itemsCountPerPage={this.state.page.limit}
                    totalItemsCount={this.state.page.totalRecords}
                    onChange={this.handlePaginationChange}
                />
            </div>
        );
    }
}

export default withSession(withPermalink(HexPerfil));