import React, {Component} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class MacroinvertebradosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lugar: [
                'Artificial',
                'Boulders/cobble',
                'Leaf Pack',
                'Submerged logs',
                'Substrate',
                'Surface water',
                'Undercut banks',
                'Woody debris'
            ],
            record: {}
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            record: props.record
        });
    }

    handleInputChange(event) {
        this.props.handleInputChange(event);
    }

    handleSubmit(event) {
        this.props.handleSubmit(event);
    }

    render() {
        return (
            <div className="container">
                <Form submit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Macrovertebrados</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label>Lugar de la muestra</label>
                            <select className="form-select form-control"
                                    name="muestra"
                                    autoFocus={true}
                                    value={this.state.record.muestra}
                                    onChange={this.handleInputChange}>
                                <option/>
                                {this.state.lugar.map(p => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </select>
                        </div>
                        <FormControl
                            label="Grupo 1"
                            type="number"
                            name="grupo1"
                            col={6}
                            value={this.state.record.grupo1}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Grupo 2"
                            type="number"
                            name="grupo2"
                            col={6}
                            value={this.state.record.grupo2}
                            onChange={this.handleInputChange}/>
                        <FormControl
                            label="Grupo 3"
                            type="number"
                            name="grupo3"
                            col={6}
                            value={this.state.record.grupo3}
                            onChange={this.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Comentarios"
                            type="text"
                            col={12}
                            name="comm_vertebrado"
                            value={this.state.record.comm_vertebrado}
                            onChange={this.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default MacroinvertebradosForm;