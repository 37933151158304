//@flow
import {
    addAttachment,
    addFeatures,
    deleteFeatures,
    getAttachments,
    getFeature,
    getLayer,
    queryFeatures,
    queryRelated,
    updateAttachment,
    updateFeatures
} from '@esri/arcgis-rest-feature-layer';

import type {
    IGeometry,
    IAddFeaturesOptions,
    IQueryFeaturesOptions,
} from "@esri/arcgis-rest-feature-layer";

import type {GeoJSONFeatureCollection} from '@mapbox/geojson-types';
import { getPortal } from '@esri/arcgis-rest-portal';
import ErrorToast from "../components/ErrorToast";
import toast from './toast';
import {toast as unwrappedToaster} from 'react-toastify';
import React from "react";
import {UserSession} from "@esri/arcgis-rest-auth";

export type QueryOptions = {
    entity: String,
    where: String,
    outFields: String | String[],
    orderByFields: String,
    _pageNumber: number,
}

export type AddPhotoOptions = {
    entity: String,
    id: number,
    attachment: File
}

export type SaveOptions = {
    action: 'INSERT' | 'UPDATE',
    entity: String,
    attributes: {},
    geometry?: IGeometry,
    attachment: File
}

export type DeleteOptions = {
    entity: String,
    id: String
}

export type GetPhotoOptions = {
    entity: String,
    id: String
}

export type QueryResults = {
    limit: number,
    pageNumber: number,
    totalRecords: number,
    records: Array,
}

export type GetPhotoResult = {
    entity: {
        id: String,
        name: String
    },
    attachment: {
        id: number,
        url: String
    }
}

export type GeoJSONQueryOptions = {
    entity: String,
    where: String,
    outFields: String | String[],
    orderByFields: String,
}

export default class RestArcGIS {
    constructor(session: UserSession, endpoint?: string) {
        this.session = session;
        this.tableMeta = null;
        this.endpoint = endpoint;
    }

    pingServer() {
        return getPortal(null, {
            url: this.endpoint,
            authentication: this.session
        });
    }

    async query(options: QueryOptions): QueryResults {
        await this.initTableMeta();
        let MAX_PAGES = 15;

        let requestOptions = Object.assign({}, options);
        delete requestOptions._pageNumber; //remove private property from request options
        requestOptions.authentication = this.session;
        requestOptions.url = this.endpoint + '/' + this.tableMeta[options.entity];

        //record count
        let recordsCountOptions = Object.assign({}, requestOptions);
        recordsCountOptions.returnCountOnly = true;
        const recordsCount = await queryFeatures(recordsCountOptions);

        //paging options
        if (options._pageNumber) {
            requestOptions.resultRecordCount = options.resultRecordCount || MAX_PAGES;
            requestOptions.resultOffset = options._pageNumber ? (options._pageNumber - 1) * requestOptions.resultRecordCount : 0;
        }

        const json = await queryFeatures(requestOptions);

        return {
            limit: requestOptions.resultRecordCount,
            pageNumber: options._pageNumber ? options._pageNumber : 1,
            totalRecords: recordsCount.count,
            records: json.features.map(f => f.attributes)
        }
    }

    async queryForExport(options: QueryOptions): QueryResults {
        await this.initTableMeta();

        let requestOptions = Object.assign({}, options);
        delete requestOptions._pageNumber; //remove private property from request options
        requestOptions.authentication = this.session;
        requestOptions.url = this.endpoint + '/' + this.tableMeta[options.entity];

        const json = await queryFeatures(requestOptions);
        return json.features;
    }

    async queryGeoJSON(options: GeoJSONQueryOptions): GeoJSONFeatureCollection {
        await this.initTableMeta();

        let requestOptions = Object.assign({}, options);
        delete requestOptions._pageNumber; //remove private property from request options
        requestOptions.authentication = this.session;
        requestOptions.f = 'geojson';
        requestOptions.url = this.endpoint + '/' + this.tableMeta[options.entity];
        return await queryFeatures(requestOptions);
    }


    async initTableMeta() {
        if (this.tableMeta) {
            return;
        }
        const json = await getLayer({url: this.endpoint, authentication: this.session});
        const tableMeta = {};
        json.layers.forEach(lyr => tableMeta[lyr.name] = lyr.id);
        json.tables.forEach(tbl => tableMeta[tbl.name] = tbl.id);
        this.tableMeta = tableMeta;
    }

    async get(options) {
        await this.initTableMeta();

        let requestOptions = Object.assign({}, options);
        requestOptions.authentication = this.session;
        requestOptions.url = this.endpoint + '/' + this.tableMeta[options.entity];

        const json = await getFeature(requestOptions);
        console.log(json);
        return json.attributes
    }

    async getByGlobalID(options) {
        await this.initTableMeta();

        let requestOptions: IQueryFeaturesOptions = Object.assign({}, options);
        requestOptions.authentication = this.session;
        requestOptions.url = this.endpoint + '/' + this.tableMeta[options.entity];
        requestOptions.where = `GlobalID = '${options.globalId}'`;
        requestOptions.resultRecordCount = 1;
        const json = await queryFeatures(requestOptions);
        return json.features.length > 0 ? json.features[0].attributes : null;
    }

    async getRelated(options) {
        await this.initTableMeta();

        const requestOptions = Object.assign({}, options);
        requestOptions.authentication = this.session;
        const tableId = this.tableMeta[options.entity];
        requestOptions.url = this.endpoint + '/' + tableId;
        const relatedTableMeta = await this.getTableRelatedMeta(tableId);
        requestOptions.relationshipId = relatedTableMeta[options.relatedEntity];
        requestOptions.objectIds = [options.OBJECTID];
        const json = await queryRelated(requestOptions);
        console.log(json);
        const recordsCount = 15;
        return {
            limit: requestOptions.resultRecordCount,
            pageNumber: options._pageNumber ? options._pageNumber : 1,
            totalRecords: recordsCount.count,
            records: json.relatedRecordGroups ? json.relatedRecordGroups[0].relatedRecords.map(f => f.attributes) : [],
        };
    }

    async getTableRelatedMeta(tableId) {
        const json = await getLayer(this.endpoint + '/' + tableId, {authentication: this.session});
        const tableMeta = {};
        json.relationships.forEach(lyr => tableMeta[lyr.name] = lyr.id);
        return tableMeta;
    }

    async getPhotos(options : GetPhotoOptions): GetPhotoResult {
        await this.initTableMeta();

        let baseUrl = `${this.endpoint}/${this.tableMeta[options.entity]}`;

        let attachments = await getAttachments({
            authentication: this.session,
            url: baseUrl,
            featureId: options.id
        });

        let token = await this.session.getToken(baseUrl);

        let photoUrls = attachments.attachmentInfos
            .filter(attachmentInfo => {
                return attachmentInfo.contentType.includes("image"); //filter image mime types
            }).flatMap(attachmentInfo => {
                return {
                    entity: {
                        name: options.entity,
                        id: options.id
                    },
                    attachment: {
                        id: attachmentInfo.id,

                        //attachmentInfo.size is appended at the end of the image url to force browsers to reload the image when its size changes
                        url: `${baseUrl}/${options.id}/attachments/${attachmentInfo.id}?f=html&token=${token}&${attachmentInfo.size}`
                    }
                };
            });

        return photoUrls && photoUrls.length > 0 ? photoUrls : [];
    }

    //add or update a single entity photo. If entity has no photo, creates a new one; updates existent otherwise
    async savePhoto(options: AddPhotoOptions, withFeedback: Boolean) {
        await this.initTableMeta();

        let response = {};
        let baseUrl = `${this.endpoint}/${this.tableMeta[options.entity]}`;

        if (!options.attachment) {
            response = {success: false, error: {description: 'Attachment is required'}};
            RestArcGIS.__showError(response.error);
            return response;
        }

        if (!options.attachment.type.includes("image")) {
            response = {success: false, error: {description: 'Attachment must be of type [image]'}};
            RestArcGIS.__showError(response.error);
            return response;
        }

        let attachments = await getAttachments({
            authentication: this.session,
            url: baseUrl,
            featureId: options.id
        });

        if (attachments.attachmentInfos.length === 0) {
            let p = await addAttachment({
                authentication: this.session,
                url: baseUrl,
                featureId: options.id,
                attachment: options.attachment
            });
            response = p.addAttachmentResult;
        } else {
            let p = await updateAttachment({
                authentication: this.session,
                url: baseUrl,
                featureId: options.id,
                attachment: options.attachment,
                attachmentId: attachments.attachmentInfos[0].id
            });
            response = p.updateAttachmentResult;
        }

        if (withFeedback) {
            if (response.success) {
                toast.success('La operación fue completada exitosamente');
            } else {
                RestArcGIS.__showError(response.error);
            }
        }

        return response;
    }

    //add a photos to entity
    async appendPhoto(options: AddPhotoOptions, withFeedback: Boolean) {
        await this.initTableMeta();

        let baseUrl = `${this.endpoint}/${this.tableMeta[options.entity]}`;

        let response = await addAttachment({
            authentication: this.session,
            url: baseUrl,
            featureId: options.id,
            attachment: options.attachment
        });

        if (withFeedback) {
            if (response.success) {
                toast.success('La operación fue completada exitosamente');
            } else {
                unwrappedToaster(<ErrorToast errorDetail={response.error}/>, {
                    type: unwrappedToaster.TYPE.ERROR
                });
            }
        }

        return response.addAttachmentResult;
    }

    save = async (options: SaveOptions) => {
        await this.initTableMeta();

        if (options.action === 'INSERT') {
            options.attributes.creationdate_mdv1 = new Date().getTime();
            options.attributes.creator_mdv1 = this.session.username;
        }
        options.attributes.editdate_mdv1 = new Date().getTime();
        options.attributes.editor_mdv1 = this.session.username;

        let requestOptions: IAddFeaturesOptions = {
            url: this.endpoint + '/' + this.tableMeta[options.entity],
            authentication: this.session,
            features: [{
                attributes: options.attributes,
                geometry: options.geometry,
            }]
        };

        console.debug(requestOptions);

        let saveResponse = {};

        try {

            if (options.action === 'INSERT') {
                let result = await addFeatures(requestOptions);
                saveResponse = result.addResults[0];
            }

            if (options.action === 'UPDATE') {
                let result = await updateFeatures(requestOptions);
                saveResponse = result.updateResults[0];
            }

            if (saveResponse.success) {
                if (options.attachment) {
                    let addPhotoResponse = await this.savePhoto({
                        id: saveResponse.objectId,
                        entity: options.entity,
                        attachment: options.attachment
                    }, false);

                    console.debug(addPhotoResponse);

                    if (!addPhotoResponse.success) {
                        RestArcGIS.__showError(addPhotoResponse.error);
                    }
                }
            } else {
                RestArcGIS.__showError(saveResponse.error);
            }
        } catch (err) {
            let errDisplayMsg = err.message;
            try {
                errDisplayMsg += "\n" + err.response.error.details.join("\n")
            } catch (noDetailsErr) {
                console.error(JSON.stringify(noDetailsErr))
            }
            RestArcGIS.__showError({ description: errDisplayMsg });
        }

        return saveResponse;
    };

    delete = async (options: DeleteOptions) => {
        await this.initTableMeta();

        let requestOptions = {
            url: this.endpoint + '/' + this.tableMeta[options.entity],
            authentication: this.session,
            objectIds: [options.id]
        };

        let result = await deleteFeatures(requestOptions);
        return result.deleteResults[0];
    };

    static __showError(error) {
        unwrappedToaster(<ErrorToast errorDetail={error}/>, {
            type: unwrappedToaster.TYPE.ERROR
        });
    }
}







