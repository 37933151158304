//@flow
import React, {Component, Fragment} from 'react';
import VegetacionList from "../vegetacion/VegetacionList";
import AvesList from "../aves/AvesList";
import ReptilesList from "../reptiles/ReptilesList";
import PlayaList from "../playa/PlayaList";
import {Link} from "react-router-dom";
import EventoTipo from "../EventoTipo";
import HumedalesList from "../humedal/HumedalesList";
import CuencaList from "../cuenca/CuencaList";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from '../../context/SessionContext';
import {Fmts, formatDate} from '../../util/format'
import CoordViewMapa from "../mdv/mapa/CoordViewMap";
import {within} from '../../util/geom';
import {withEvento} from "./EventoUtils";
import type {EventoProps} from "./EventoUtils";
import type {SessionProps} from "../../context/SessionContext";
import BackButton from "../BackButton";
import {LandUseChart} from "./LandUseChart";
import {LandCoverChart} from "./LandCoverChart";
import {AuditData} from "../AuditData";

type Props = {
    ...EventoProps,
    ...SessionProps,
}

class EventoView extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            foto: null,
            vegetacion: [],
        }
    }

    componentDidMount = async () => {
        const data = this.props.evento;
        const photos = await this.props.rest.getPhotos({
            entity: 'evento',
            id: data.OBJECTID,
        });

        this.setState({
            foto: photos[0],
            vegetacion: this.initVegetacion(data),
        });
    };

    initVegetacion(evento) {
        const vegList = [];
        if(evento.vegetacion_arbustos !== 0) {
            vegList.push('Arbustos');
        }
        if(evento.vegetacion_cactus !== 0) {
            vegList.push('Cactus');
        }
        if(evento.vegetacion_gramineas !== 0) {
            vegList.push('Gramíneas');
        }
        if(evento.vegetacion_helechos !== 0) {
            vegList.push('Helechos');
        }
        if(evento.vegetacion_lenosa !== 0) {
            vegList.push('Leñosa');
        }
        if(evento.vegetacion_otro !== 0) {
            vegList.push('Otro');
        }
        return vegList;
    }

    render() {
        
        let img = <span>Foto no disponible</span>;
        if (this.state.foto) {
            img =
                <div style={{height: 25 + '%', width: 25 + '%', border: 'dotted ' + 1 + 'px #ccc'}}>
                    <img alt="" className="img-fluid" src={this.state.foto.attachment.url}/>
                </div>;
        }

        const evento = this.props.evento;

        let valid_coords = within(evento.latitud, evento.longitud, this.props.hexagono);

        return (
            <div className="container">
                <div className="row">
                    <div className="col-9">
                        <h3>Evento - <EventoTipo tipoEvento={evento.tipo_mdv}/> - {evento.nombre}</h3>
                    </div>
                    <div className="col-3 text-right">
                        <Link className="btn btn-success" to={`/evento/${this.props.evento.GlobalID}/edit`} role="button">
                            <FaPencilAlt/> Editar
                        </Link>
                        &nbsp;<BackButton btnClass="success"/>
                    </div>
                </div>
                {/*<div>{JSON.stringify(this.state.evento)}</div>*/}
                <hr/>
                <div className="row">
                    <div className="col-3 font-weight-bold">Nombre del evento: </div>
                    <div className="col-3">{evento.nombre}</div>
                    <div className="col-3 font-weight-bold">Fecha: </div>
                    <div className="col-3">{formatDate(evento.fecha, Fmts.DATE)}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">ID Hexágono: </div>
                    <div className="col-3">{evento.hex_id}</div>
                    <div className="col-3 font-weight-bold">Longitud: </div>
                    <div className="col-3">{evento.longitud}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Estatus: </div>
                    <div className="col-3">{evento.estatus}</div>
                    <div className="col-3 font-weight-bold">Latitud: </div>
                    <div className="col-3">{evento.latitud}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Validación de coordenadas: </div>
                    <div className={`col-3 ${!valid_coords && 'text-danger'}`}>{valid_coords ? 'Válidas' : 'No válidas'}</div>
                </div>

                {evento.latitud && evento.longitud &&
                <Fragment>
                    <br/>
                    <CoordViewMapa height={200}
                                   lat={evento.latitud}
                                   lng={evento.longitud}
                    />
                    <br/>
                </Fragment>
                }

                <br/>
                <h3>Sitio</h3>
                <hr/>

                <div className="row">
                        <div className="col-3 font-weight-bold">Ubicacion: </div>
                        <div className="col-3">{evento.ubicacion}</div>
                        <div className="col-3 font-weight-bold">Propietario: </div>
                        <div className="col-3">{evento.propietario}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Descripción del paisaje: </div>
                    <div className="col-9">{evento.foto_paisaje_descripcion}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Cuerpo de agua: </div>
                    <div className="col-3">{evento.cuerpo_agua}</div>
                    <div className="col-3 font-weight-bold">Vegetación dominante: </div>
                    <div className="col-3">{this.state.vegetacion.join(', ')}</div>
                </div>

                <br/>

                <div className="row">
                    <div className="col"><LandUseChart evento={evento}/></div>
                    <div className="col"><LandCoverChart evento={evento}/></div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <span className="font-weight-bold">Foto del paisaje: </span>
                        {img}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <span className="font-weight-bold">Descripción de foto: </span>
                        <span>{evento.descripcion_foto}</span>
                    </div>
                </div>

                <br/>
                <h3>Clima</h3>
                <hr/>

                <div className="row">
                    <div className="col-3 font-weight-bold">Temperatura: </div>
                    <div className="col-3">{evento.temperatura}</div>
                    <div className="col-3 font-weight-bold">Velocidad del viento: </div>
                    <div className="col-3">{evento.velocidad_viento}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Humedad relativa: </div>
                    <div className="col-3">{evento.humedad}</div>
                    <div className="col-3 font-weight-bold">Dirección del viento: </div>
                    <div className="col-3">{evento.dir_viento}</div>
                </div>
                <div className="row">
                    <div className="col-3 font-weight-bold">Precipitación: </div>
                    <div className="col-3">{evento.precipitacion}</div>
                    <div className="col-3 font-weight-bold">Cobertura de nubes: </div>
                    <div className="col-3">{evento.cob_nubes}</div>
                </div>
                {this.getAdditionalData()}
                <br/>

                <AuditData entity={evento}/>
            </div>
        );
    }
    
    getAdditionalData() {
        const evento = this.props.evento;
        if (evento.tipo_mdv === 'mdv-vegetaciones') {
            return <VegetacionList eventoId={evento.GlobalID}/>
        } else if (evento.tipo_mdv === 'mdv-aves') {
            return <AvesList eventoId={evento.GlobalID}/>
        } else if (evento.tipo_mdv === 'mdv-reptiles') {
            return <ReptilesList eventoId={evento.GlobalID}/>
        } else if (evento.tipo_mdv === 'mdv-playa') {
            return <PlayaList eventoId={evento.GlobalID}/>
        } else if (evento.tipo_mdv === 'mdv-humedal') {
            return <HumedalesList eventoId={evento.GlobalID}/>
        } else if (evento.tipo_mdv === 'mdv-cuenca') {
            return <CuencaList eventoId={evento.GlobalID}/>
        }
    }
}

export default withSession(withEvento(EventoView));