import React, {Component} from "react";
import PropTypes from 'prop-types';

class EventoTipo extends Component {
    
    render() {
        let tipoEvento = null;
        if (this.props.tipoEvento === 'mdv-aves') tipoEvento = 'MDV Aves';
        if (this.props.tipoEvento === 'mdv-vegetaciones') tipoEvento = 'MDV Vegetación';
        if (this.props.tipoEvento === 'mdv-reptiles') tipoEvento = 'MDV Reptiles';
        if (this.props.tipoEvento === 'mdv-playa') tipoEvento = 'MDV Playa';
        if (this.props.tipoEvento === 'mdv-cuenca') tipoEvento = 'MDV Cuenca';
        if (this.props.tipoEvento === 'mdv-humedal') tipoEvento = 'MDV Humedal';
        if (this.props.tipoEvento === 'SAPO_CONCHO') tipoEvento = 'Sapo Concho';
        if (this.props.tipoEvento === 'mdv-tortugas') tipoEvento = 'MDV Tortugas';
        if (this.props.tipoEvento === 'mdv-mariposas') tipoEvento = 'MDV Mariposas';
        if (this.props.tipoEvento === 'mdv-anfibios') tipoEvento = 'MDV Anfíbios';
        return (
            <span>{tipoEvento}</span>
        )
    }
}

EventoTipo.propTypes = {
    tipoEvento: PropTypes.string
};

export default EventoTipo;