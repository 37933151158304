import React, {Component} from 'react';


class ArbolesInicio extends Component {


    render() {
        return (
            <div className="container">

                <h2>Árboles Campeones</h2>
                <p>
                    Los seres humanos dependemos completamente de los servicios que nos proveen los ecosistemas. Tomar
                    decisiones sensibles en cuanto a la protección de ecosistemas y el uso de terrenos nos ayudará a
                    garantizar esos servicios esenciales para nuestra subsistencia. Trazar un Mapa de Vida será
                    indispensable para guiar nuestros pasos. Tu participación será esencial.</p>
            </div>
        );
    }
}

export default ArbolesInicio;



