import React, {Component} from "react";
import TableContainer from "../TableContainer";
import {Link} from "react-router-dom";
import {FaPencilAlt} from 'react-icons/fa';
import {withSession} from "../../context/SessionContext";

class DatosFuera extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventoId: props.eventoId,
            records: []
        };
        this.fetchRecords = this.fetchRecords.bind(this);
    }

    async fetchRecords(limit, pageNumber) {
        const data = await this.props.rest.query({
            _pageNumber: pageNumber,
            entity: 'sapo_concho_datos',
            where: `evento_GlobalID = '${this.state.eventoId}' and tipo_captura='FUERA_PARCELA'`,
            outFields: ['OBJECTID', 'cuadrante', 'especie_txt', 'sexo', 'edad', 'sustrato', 'comportamiento', 'longitud_cabeza_cola', 'habitat', 'hora_captura', 'observaciones', 'GlobalID'],
            orderByFields: 'creationdate_mdv1 desc'
        });

        this.setState({
            records: data.records
        });

        return {
            limit: data.limit,
            pageNumber: data.pageNumber,
            totalRecords: data.totalRecords
        };
    }

    render() {
        return (
            <TableContainer title="Especies fuera de la parcela"
                            fetchRecords={this.fetchRecords}
                            newRecordUrl={`/sapo-concho/${this.state.eventoId}/datos-fuera/new`}>

                <table className="table table-bordered table-striped table-sm">
                    <thead className="thead-light">
                    <tr>
                        <th>Cuadrante</th>
                        <th>Especie</th>
                        <th>Sexo</th>
                        <th>Edad</th>
                        <th>Sustrato</th>
                        <th>Comportamiento</th>
                        <th>Longitud</th>
                        <th>Habitat</th>
                        <th>Longitud</th>
                        <th>Latitud</th>
                        <th>Hora</th>
                        <th>Observaciones</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.records.map(record => (
                            <tr key={record.OBJECTID}>
                                <td>{record.cuadrante}</td>
                                <td>{record.especie_txt}</td>
                                <td>{record.sexo}</td>
                                <td>{record.edad}</td>
                                <td>{record.sustrato}</td>
                                <td>{record.comportamiento}</td>
                                <td>{record.longitud_cabeza_cola}</td>
                                <td>{record.habitat}</td>
                                <td>{record.longitud}</td>
                                <td>{record.latitud}</td>
                                <td>{record.hora_captura}</td>
                                <td>{record.observaciones}</td>
                                <td className="text-center">
                                    <Link to={`/sapo-concho/${this.state.eventoId}/datos-fuera/${record.OBJECTID}`}>
                                        <FaPencilAlt/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>
            </TableContainer>
        )
    }
}

export default withSession(DatosFuera);