import React, {PureComponent} from "react";
import BackButton from "../BackButton";
import Form from "../Form";
import FormControl from "../FormControl";

class SueloForm extends PureComponent {
    
    render() {
        return (
            <div className="container">
                <Form submit={this.props.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <h4>Humedales - Suelos Hídricos</h4>
                        </div>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Id de muestra"
                            type="text"
                            name="id_muestra"
                            autoFocus={true}
                            col={6}
                            value={this.state.record.id_muestra}
                            onChange={this.props.handleInputChange}/>
                        <FormControl
                            label="Profundidad (cm)"
                            type="number"
                            step="any"
                            name="profundidad"
                            col={6}
                            value={this.state.record.profundidad}
                            onChange={this.props.handleInputChange}/>
                    </div>
                    <div className="row">
                        <FormControl
                            label="Horizonte"
                            type="text"
                            name="horizonte"
                            col={6}
                            value={this.state.record.horizonte}
                            onChange={this.props.handleInputChange}/>
                        <FormControl
                            label="Color Matriz"
                            type="text"
                            name="matriz"
                            col={6}
                            value={this.state.record.matriz}
                            onChange={this.props.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Redox Color"
                            type="text"
                            name="redox_color"
                            col={6}
                            value={this.state.record.redox_color}
                            onChange={this.props.handleInputChange}/>
                        <FormControl
                            label="Redox %"
                            type="number"
                            step="any"
                            name="redox_pct"
                            col={6}
                            value={this.state.record.redox_pct}
                            onChange={this.props.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Redox Tipo"
                            type="text"
                            name="redox_tipo"
                            col={6}
                            value={this.state.record.redox_tipo}
                            onChange={this.props.handleInputChange}/>

                        <FormControl
                            label="Redox Loc"
                            type="text"
                            col={6}
                            name="redox_loc"
                            value={this.state.record.redox_loc}
                            onChange={this.props.handleInputChange}/>
                    </div>

                    <div className="row">
                        <FormControl
                            label="Textura"
                            type="text"
                            name="textura"
                            col={6}
                            value={this.state.record.textura}
                            onChange={this.props.handleInputChange}/>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-1">
                            <input type="submit"
                                   value="Guardar"
                                   className="btn btn-primary form-submit"/>
                        </div>
                        <div className="col-1">
                            <BackButton/>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default SueloForm;