//@flow
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import es from 'date-fns/locale/es';

export const Fmts = {
    DATE: 'dd MMM yyyy',
    TIMESTAMP: "d 'de' MMMM 'de' yyyy hh:mm aaaa",
    ISO_DATE: 'yyyy-MM-dd',
    ISO_TIMESTAMP: 'yyyy-MM-dd HH:mm:ss',
};

export function formatDate(epoch: number, pattern?: string) {
    if (!pattern) pattern = Fmts.DATE;
    return epoch ? format(new Date(epoch), pattern, {locale: es}) : ''
}

export function parseISODate(ISODate: string) {
    return parseISO(ISODate)
}